/// <reference path="../../_reference.ts" />
module Apex.Operations.Controllers {
    export class OrderTranzit extends BaseCrmOrderModal {

        constructor($scope: IBasePreOrderScope, $rootScope: any, data: any, crmService: Crm.Services.Crm, commonService: Services.Common, distributionService: Services.Distribution, accountingService: Account.Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, $rootScope, data, crmService, commonService, distributionService, accountingService, $uibModalInstance, toaster, $http);

            crmService.getCompanyBranchesByStatus(Crm.Services.CompanyBranchStatus.Own).then((data) => {
                $scope.components.senderLookup.dataSource.data(data.Result);
                $scope.components.recipientLookup.dataSource.data(data.Result);
                return data;
            });

            $scope.saveOrder = this.saveOrder;
        }        
        
        saveOrder = () => {
            var order = this.convertOrderForSave(Utils.PreorderType.OrderTranzit);
            if (order != null) {
                
                this.saveCrmOrderOrder(order);
            }
        };


        getPartial(): string {
            return 'modals\order.tranzit.html';
        }
    }

}