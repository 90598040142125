/// <reference path='./_reference.ts' />
module Apex.Operations {
    'use strict';

    var apxDictionary = new Core.Module('operations', ['apex.webUI.account','frapontillo.bootstrap-switch'], Operations);
    var routes: Array<Core.Interfaces.IRoute> =
        [
            {
                state: 'operations',
                url: '^/operations',
                template: '<ui-view/>',
                navigation: { caption: 'Oper', navigationClass: 'md md-content-copy' },
                childRoutes: [
                    {
                        state: 'orders',
                        url: '/orders',
                        controller: Operations.Controllers.PreOrders.getName(),
                        templateUrl: "PreOrders.html",
                        navigation: {
                            caption: 'orders'
                        }
                    },
                    {
                        state: 'labels',
                        url: '/labels',
                        controller: Operations.Controllers.LabelsPrint.getName(),
                        templateUrl: "labelsPrint.html",
                        navigation: {
                            caption: 'labels'
                        }
                    },
                    {
                        state: 'plprices',
                        url: '/plprices',
                        template: '<div> It is plprices Module </div>',
                        navigation: {
                            caption: 'plprices'
                        }
                    },
                    {
                        state: 'lproducts',
                        url: '/lproducts',
                        controller: Operations.Controllers.BookInnerBuying.getName(),
                        templateUrl: "bookOperations.html",
                        navigation: {
                            caption: 'lproducts'
                        }
                    },
                    {
                        state: 'iproduct',
                        url: '/iproduct',
                        controller: Operations.Controllers.BookImport.getName(),
                        templateUrl: "bookOperations.html",
                        navigation: {
                            caption: 'iproduct'
                        }
                    },
                    {
                        state: 'rproducts',
                        url: '/rproducts',
                        controller: Operations.Controllers.BookRealization.getName(),
                        templateUrl: "bookOperations.html",
                        navigation: {
                            caption: 'rproducts'
                        }
                    },
                    {
                        state: 'woproducts',
                        url: '/woproducts',
                        template: '<div> It is woproducts Module </div>',
                        navigation: {
                            caption: 'woproducts'
                        }
                    },
                    {
                        state: 'innermovement',
                        url: '/innermovement',
                        controller: Operations.Controllers.BookInnerMovement.getName(),
                        templateUrl: "bookOperations.html",
                        navigation: {
                            caption: 'innermovement'
                        }
                    },
                    {
                        state: 'codeconvert',
                        url: '/codeconvert',
                        template: '<div> It is codeconvert Module </div>',
                        navigation: {
                            caption: 'codeconvert'
                        }
                    },
                    {
                        state: 'disband',
                        url: '/disband',
                        template: '<div> It is disband Module </div>',
                        navigation: {
                            caption: 'disband'
                        }
                    },
                    {
                        state: 'inventory',
                        url: '/inventory',
                        controller: Operations.Controllers.Inventory.getName(),
                        templateUrl: "Inventory.html",
                        navigation: {
                            caption: 'Inventory'
                        }
                    },
                    {
                        state: 'service',
                        url: '/service',
                        controller: Operations.Controllers.BookService.getName(),
                        templateUrl: "bookOperations.html",
                        navigation: {
                            caption: 'service'
                        }
                    },
                ]
            }
        ];
    apxDictionary.regRoutes(routes, 'apex');
}