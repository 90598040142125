/// <reference path="../_reference.ts" />
module Apex.Operations.Interfaces {
    export interface ILabelPrintProd {
        Id?: number;
        JobId?: number;
        ProdPPId?: string;
        Quantity?: number;
        Good?: Account.Interfaces.IGood;
    }
}
