/// <reference path="../../_reference.ts" />
module Apex.Operations.Controllers {
    export interface IProductsModalScope extends angular.IScope {
        formName: string;
        Order?: any;
        Components: {
            productsGrid: Apex.Components.IApxGrid
        },
        model: {
            productsFilterForRcount: any,
            productsFilterArrayForRcount: Array<any>,
            recipient?: any,
            supplier?: any,
            modalMarkedProducts?:any;
            Order?:any,
            _OrderType?:number,
            filterValue?:string
        },
        events: {
            close: Function,
            save: Function
        },
        manageRCount:boolean
    }

    export class ProductsModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'accountService', "crmService", Globals.modalInstance, Globals.toaster, "$timeout"];
        constructor(private $scope: IProductsModalScope, private $translate, data: any, private accountService: Apex.Account.Services.Account,
            private crmService: Apex.Crm.Services.Crm, private $uibModalInstance, private toaster, public $timeout) {
            super(arguments, ProductsModal.$inject);
            $scope.model = {
                productsFilterArrayForRcount: this.getProductsFilterArrayForRcount(),
                productsFilterForRcount: 0,
                modalMarkedProducts:{}
            }
             this.$scope.model.recipient=data.recipient;
            this.$scope.model.supplier=data.supplier;
            this.$scope.model.Order=data.Order;
            this.$scope.manageRCount=data.manageRCount;
            this.$scope.model._OrderType=data.OrderType;
            this.$scope.model.filterValue="&OrderType="+data.OrderType;
            
            this.initialization($scope);
           

            

            angular.element(document).ready( ()=> {
                this.ngOnInit();
            });
        }

        ngOnInit = () => {
            this.$scope.$watch('model.productsFilterForRcount', (val: any) => {
                if (val != undefined) {
                    var filter;
                    switch (val) {
                        case '1': {
                            filter = { filterName: 'rcount', filterOperator: 'GreaterThan', filterValue: '0' };
                            break;
                        }
                        case '2':
                            {
                                filter = { filterName: 'sys_rcount', filterOperator: 'GreaterThan', filterValue: '0' };
                                break;
                            }
                    }
                    if (this.$scope.Components.productsGrid.gridView != undefined) {
                        var filters = this.getFiltersForModalProductsGrid();
                        if (filter != undefined) {
                            filters.push(filter);
                        }

                        this.$scope.Components.productsGrid.gridView.showLoading();
                        this.$scope.Components.productsGrid.gridView.setFilter(filters);
                        this.$scope.Components.productsGrid.gridView.refresh();
                    }
                }
            });
        }

        initialization(scope: IProductsModalScope) {
            scope.Components = {
                productsGrid: this.initProductsGrid()
            };
            
            scope.events= {
                close: this.closeForm,
                save: this.save
            }
        }

        getProductsFilterArrayForRcount = () => {
            return [{ id: 0, value: 'ყველა' },
            { id: 1, value: 'ლოკალური ნაშთით' },
            { id: 2, value: 'სისტემური ნაშთით' }];
        }


        initProductsGrid = (): Components.IApxGrid => {
           

            var datasource = Core.Kendo.GridView.initAutoQueryDataSource(this.crmService.crmProductListQueryUrl,null,null,this.$scope.model.filterValue); 
            var options = datasource.options;
            options.schema.model.fields = {
                'bcode': { type:"string", editable: false },
                'category_nu': {type:"string", editable: false },
                'products_nu': {type:"string", editable: false },
                'producer_nu': {type:"string", editable: false },
                'unit': {type:"number", editable: false },
                'price': { type:"number", editable: false },
                'Discount': {type:"number", editable: false },
                'min_price': {type:"number", editable: false },
                'MinPrice': {type:"number", editable: false },
                'rcount': {type:"number", editable: false },
                'sys_rcount': {type:"number", editable: false },
            };
            options.change = (e) => {
                _.forEach(this.$scope.Components.productsGrid.dataSource.data(), (item: any) => {
                    if (this.$scope.model.modalMarkedProducts['"' + item.prodpp_id + '"'] != undefined) {
                        item.count = this.$scope.model.modalMarkedProducts['"' + item.prodpp_id + '"'].count;
                    }
                });
            };
            datasource.init(options);
            return <Apex.Components.IApxGrid>{
                dataSource: datasource,
                templates: this.productsGridTemplates,
                columns: this.productsGridColumns,
                settings: {
                    height: window.innerHeight-200,
                    pageable: true,
                    editble: true,
                    autoBind: false
                },
                initCallback: () => {
                    setTimeout(() => {
                        var filters = this.getFiltersForModalProductsGrid();
                        this.$scope.Components.productsGrid.gridView.setFilter(filters);
                        this.$scope.Components.productsGrid.gridView.refresh();
                    }, 200);
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Save,
                        eFunction: (e) => {
                            
                            if (e != undefined && (<any>e).model != undefined) {
                                if ((<any>e).values.count == null) {
                                    delete this.$scope.model.modalMarkedProducts['"' + (<any>e).model.prodpp_id + '"'];
                                }
                                else {
                                    if (this.$scope.manageRCount == true && (<any>e).values.count > (<any>e).model.rcount) {
                                        Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('notenoughremains')) });
                                        e.preventDefault();
                                        return;
                                    }                                    
                                    (<any>e).model.Prod = { ID: (<any>e).model.prodpp_id };
                                    this.$scope.model.modalMarkedProducts['"' + (<any>e).model.prodpp_id + '"'] = (<any>e).model;
                                }
                            }
                        }
                    },
                    {
                        eType: Components.EventType.Edit,
                        eFunction: (e) => {
                            
                            if ((<any>e).model != undefined && (<any>e).model.packcount == 1) {
                                var options: any = { numeric: true, min: 0, allowMinus: false }
                                $('#countInputProductsGridModal').inputmask('integer', options);
                            }
                        }
                    }
                ],
                isLookup: false
            };
        }

        productsGridTemplates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                editformat: "{0:##.####}",
                fieldName: 'count', editor: function (container, options) {

                    $("<input class='apx-color-lemonchiffon' name='" + options.field + "' id=countInputProductsGridModal />")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 4,
                            spinners: false,
                            min: 0,
                            step: 0
                        });
                }
            }
        ];

        productsGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'prodpp_id', hidden: true },
            { field: 'bcode', titleTranslated: 'barcode', width: 110 },
            { field: 'products_nu', titleTranslated: 'goods', width: 200 },
            { field: 'sys_rcount', titleTranslated: 'sysRcount', width: 80, format: Core.Kendo.Format.Number },
            { field: 'rcount', titleTranslated: 'rest', width: 80, format: Core.Kendo.Format.Number },
            { field: 'price', titleTranslated: 'price', width: 80, format: Core.Kendo.Format.Price4 },
            { field: 'min_price', titleTranslated: 'minprice', width: 80, format: Core.Kendo.Format.Price4 },
            { field: 'count', titleTranslated: 'Count', width: 80, editable: true, format: Core.Kendo.Format.Price4 },
            { field: 'category_nu', titleTranslated: 'categoryName', width: 200 },
            { field: 'producer_nu', titleTranslated: 'producer', width: 190 },
            { field: 'unit', titleTranslated: 'unit', width: 50 }
        ];

        getFiltersForModalProductsGrid = (): Array<Core.Interfaces.IGridFilter> => {
            
            var filters: Array<Core.Interfaces.IGridFilter> = [];
            if (this.$scope.model.recipient) {
                filters.push({ filterName: 'BranchId', filterOperator: '', filterValue: this.$scope.model.recipient.Id.toString() });
            }

            if (this.$scope.model.supplier) {
                filters.push({ filterName: 'SupplierBranchId', filterOperator: '', filterValue: this.$scope.model.supplier.Id.toString() });
            }

            if (this.$scope.Order) {
                filters.push({ filterName: 'OrderId', filterOperator: '', filterValue: this.$scope.Order.OrderId.toString() });
            }

            return filters;
        };
        closeForm = (value) => {
            this.$uibModalInstance.close(value);
        }
        
        save=()=>{
            this.closeForm(this.$scope.model.modalMarkedProducts);
        }
    }

}