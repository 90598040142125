/// <reference path='./../_reference.ts' />
module Apex.Operations.Directives {
    "use strict";
    export class ApxAddOrder implements angular.IDirective {
        restrict = "E";
        template = '<apx-search-dropdown apx-value-id="Id" apx-value-name="Value" apx-on-click="createOrder(val)" apx-array="preorderAllTypes"></apx-search-dropdown>';
        scope = {
            callback: '=',
            preorderAllTypes:'='
        };
        link = (scope: any) => {           
            scope.createOrder = (value) => {
                
                
                Utils.CrmPreOrders.OpenAddCrmPreorderForm(value.Id, scope.callback);
            }
        }
        constructor(private $translate, private accountService) {
        }

        public static factory() {
            var directive = ($translate, accountService) => {
                return new ApxAddOrder($translate, accountService);
            };
            directive['$inject'] = ['$translate', 'accountService'];
            return directive;
        }

    }
}