/// <reference path='../../_reference.ts' />
module Apex.Operations.Controllers {
	export interface IGenInvListModalScope extends angular.IScope {
		model: {
			invInfo: DTOs.IInventarisationInfo,
			supplier: Crm.Interface.ICompanyBranch,
			category: Dictionary.Interfaces.ServiceTypes.ICategory,
			goodType: Dictionary.Interfaces.ServiceTypes.IGoodType,
			producer: Dictionary.Interfaces.ServiceTypes.IProducer,
			hCategory: Dictionary.Interfaces.ServiceTypes.IHCategory
		},
		events?: {
			save: Function,
			cancel: Function
		},
		components: {
			suppliersLookupOptions: suppliersLookupOptions,
			categoryLookupOptions: categoryLookupOptions,
			typeLookupOptions: typeLookupOptions,
			producerLookupOptions: producerLookupOptions,
			hCategoryLookupOptions: hCategoryLookupOptions
		}
	}

	export class GenInvListModal extends Core.Controller {
		public static $inject =
		[Globals.scope, 'crmService', 'commonService', 'warehousingService', 'accountService', 'dictionaryService',
			'dialogs', Globals.translate, Globals.toaster, 'data', Globals.modalInstance];

		constructor(
			private $scope: IGenInvListModalScope, private crmService: Crm.Services.Crm, commonService: Services.Common, private warehousingService: Services.Warehousing, private accountService: Apex.Account.Services.Account, dictionaryService: Apex.Dictionary.Services.Dictionary
			, private dialogs: any, private $translate: any, private toaster: any, private data: BookInventoryModalData, private $uibModalInstance) {
			super(arguments, GenInvListModal.$inject);
			this.setupScope($scope, commonService, crmService, dictionaryService);
			this.loadData();
		}

		setupScope = (scope: IGenInvListModalScope, commonService: Services.Common, crmService: Apex.Crm.Services.Crm, dictionaryService: Apex.Dictionary.Services.Dictionary) => {
			scope.components = {
				suppliersLookupOptions: new suppliersLookupOptions(scope, commonService, crmService),
				categoryLookupOptions: new categoryLookupOptions(scope, commonService, dictionaryService),
				typeLookupOptions: new typeLookupOptions(scope, commonService, dictionaryService),
				producerLookupOptions: new producerLookupOptions(scope, commonService, dictionaryService),
				hCategoryLookupOptions: new hCategoryLookupOptions(scope, commonService, dictionaryService)
			};
			scope.model = {
				invInfo: null,
				supplier: null,
				category: null,
				goodType: null,
				producer: null,
				hCategory: null
			};
			scope.events = {
				save: this.save,
				cancel: () => {
					this.$uibModalInstance.close({ status: 0, message: "cancel" });
				}
			}
		}

		save = () => {
			this.startProgress();

			var t1Id = this.$scope.model.invInfo.T1Id;
			var supId = this.$scope.model.supplier?(<any>this.$scope.model.supplier).Acc:null;
			var categoryId = this.$scope.model.category ? this.$scope.model.category.CategoryId : null;
			var typeId = this.$scope.model.goodType ? this.$scope.model.goodType.ID : null;
			var producerId = this.$scope.model.producer ? this.$scope.model.producer.ProducerID : null;
			var hCategoryId = this.$scope.model.hCategory ? this.$scope.model.hCategory.ID : null;

			this.warehousingService.genInventoryDetails(t1Id, supId, categoryId, typeId, producerId, hCategoryId).then(res => {
				this.stopProgress();
				this.$uibModalInstance.close({ status: 1, message: "შენახვა წარმატებით დასრულდა!" });
			});
		}

		loadData = () => {
			var loadRunning = new Array<Boolean>(false, false, false, false);
			this.warehousingService.GetInventoryInfo(this.data.t1Id, null, null, null).then(this.processInvInfo);
		}

		processInvInfo = (res: DTOs.ISimpleListResult<DTOs.IInventarisationInfo>) => {
			if (res && res.Result && res.Result.length > 0) {
				this.$scope.model.invInfo = res.Result[0];
				this.warehousingService.GetInventoryRDetails(res.Result[0].T1Id).then(this.processData);
			}
			else {
				this.$uibModalInstance.close({ status: 0, message: "InvNotFound" });
			}
		}

		processData = () => {

		}

		startProgress = () => {
			kendo.ui.progress($('#modal-content'), true);
		}
		stopProgress = () => {
			kendo.ui.progress($('#modal-content'), false);
		}
	}

	export class suppliersLookupOptions implements Components.IApxLookup {
		constructor(private scope: IGenInvListModalScope, private commonService: Services.Common, private crmService: Apex.Crm.Services.Crm) {
		}
		dataSource = new kendo.data.DataSource({
			transport: {
				read: (options) => {
					this.crmService.getBranchesExp(Crm.Services.CompanyBranchStatus.Supplier, false, true, true).then((result) => {
						
						if (result.Result) {
							_.each(result.Result, x => {
								var it = _.findWhere(x.BranchAccounts, { AccType: Crm.Services.AccountType.Supplier });
								if (it)
									(<any>x).Acc = it.Account;
							});
							var res = _.filter(result.Result, (x) => { return !!(<any>x).Acc });
							options.success(res);
							return;
						}
						options.success([]);
					});
				}
			}
		}); o
		columns = [
			{ field: 'Acc', width: 150, titleTranslated: 'acc' },
			{ field: 'Name', width: 150, titleTranslated: 'nameCaption' }
		];
		valueText = 'Name';
	}

	export class categoryLookupOptions implements Components.IApxLookup {
		constructor(private scope: IGenInvListModalScope, private commonService: Services.Common, private dictionaryService: Apex.Dictionary.Services.Dictionary) {
		}
		dataSource = new kendo.data.DataSource({
			transport: {
				read: (options) => {
					this.dictionaryService.getCategories(/*null*/).then((result) => {
						options.success(result.Result);
					});
				}
			}
		});
		columns = [
			{ field: 'CategoryCode', width: 100, titleTranslated: 'code' },
			{ field: 'CategoryName', width: 200, titleTranslated: 'nameCaption' }
		];
		valueText = 'CategoryName';
	}

	export class typeLookupOptions implements Components.IApxLookup {
		constructor(private scope: IGenInvListModalScope, private commonService: Services.Common, private dictionaryService: Apex.Dictionary.Services.Dictionary) {
		}
		dataSource = new kendo.data.DataSource({
			transport: {
				read: (options) => {
					this.dictionaryService.getTypes().then((result) => {
						options.success(result.Result);
					});
				}
			}
		});
		columns = [
			//			{ field: 'OperID', width: 150, },
			{ field: 'Name', width: 150, titleTranslated: 'nameCaption' }
		];
		valueText = 'Name';
	}

	export class producerLookupOptions implements Components.IApxLookup {
		constructor(private scope: IGenInvListModalScope, private commonService: Services.Common, private dictionaryService: Apex.Dictionary.Services.Dictionary) {
		}
		dataSource = new kendo.data.DataSource({
			transport: {
				read: (options) => {
					this.dictionaryService.getManufactures().then((result) => {
						options.success(result.Result);
					});
				}
			}
		});
		columns = [
			//			{ field: 'OperID', width: 150, },
			{ field: 'ProducerName', width: 150, titleTranslated: 'nameCaption' },
			{ field: 'ProducerCountry', width: 150, titleTranslated: 'nameCaption' }
		];
		valueText = 'ProducerName';
	}

	export class hCategoryLookupOptions implements Components.IApxLookup {
		constructor(private scope: IGenInvListModalScope, private commonService: Services.Common, private dictionaryService: Apex.Dictionary.Services.Dictionary) {
		}
		dataSource = new kendo.data.DataSource({
			transport: {
				read: (options) => {
					this.dictionaryService.getGroups().then((result) => {
						options.success(result.Result);
					});
				}
			}
		});
		columns = [
			//			{ field: 'OperID', width: 150, },
			{ field: 'Name', width: 150, titleTranslated: 'nameCaption' }
		];
		valueText = 'Name';
	}
}