/// <reference path='../_reference.ts' />
module Apex.Operations.Controllers {
    export interface IOrderScope extends angular.IScope {
        filters: IFilter;
        components: IPreOrdersComponents;
        events: IEvents;
        selectedOrder: any;
        selectedPayment: any;
        allowDelete: boolean;
        showRefreshInfo: boolean;
        preorderAllTypes: Array<Interfaces.IPreorderType>;
        preorderAllStatuses: Array<Apex.Crm.Interface.PrOrderStatuses>;
        orderPayments: Array<any>;
        docTypeTemplate: string;
    }

    export interface IFilter {
        orderDateBetween: any;
        deliveryDateBetween: any;
        types: Array<string>;
        statuses: Array<string>;
        recipient?: any;
        supplier?: any;
        allowOrderDateFilter: boolean;
        allowDeliveryDateFilter: boolean;
    }

    export interface IPreOrdersComponents {
        ordersGrid?: Components.IApxGrid;
        recipientLookup: Components.IApxLookup;
        senderLookup: Components.IApxLookup;
        detailsComponents:
        {
            productsGrid: Components.IApxGrid;
            servicesGrid: Components.IApxGrid;
            paymentsGrid: Components.IApxGrid;
            documentsGrid?: Components.IApxGrid;
            warehousesGrid: Components.IApxGrid;
        }
    }

    export interface IEvents {
        refreshOrders: Function;
        editOrder: Function;
        deleteOrder: Function;
        changePreOrderStatus: Function;
        getStatusName: Function;
        calculateRcount:Function;
    }

    export class PreOrders extends Core.Controller {
        public static $inject = [Globals.scope, 'crmService', 'dialogs', Globals.translate, Globals.toaster, '$timeout'];
        $scope: IOrderScope;
        public static FixedValuesForCrmOrderFunctionality;
        constructor($scope: IOrderScope, private crmService: Crm.Services.Crm, private dialogs: any, private $translate: any, private toaster: any, private $timeout: any) {
            super(arguments, PreOrders.$inject);
            this.$scope = $scope;
            $scope.allowDelete = false;
            $scope.showRefreshInfo = true;
            $scope.filters = {
                allowOrderDateFilter: false,
                allowDeliveryDateFilter: false,
                orderDateBetween: {},
                deliveryDateBetween: {},
                types: [],
                statuses: []
            };
            crmService.getCrmPrOrderStatuses().then(success => {
                $scope.preorderAllStatuses = [];
                $scope.preorderAllStatuses = _.sortBy(success.Result, (item) => { return item.Id });
                
            }, error => { });

            $scope.components = {
                recipientLookup: {
                    columns: PreOrders.branchcolumns,
                    valueText: 'Name'
                },
                senderLookup: {
                    columns: PreOrders.branchcolumns,
                    valueText: 'Name'
                },
                ordersGrid: this.getOrdersGrid(),
                detailsComponents: {
                    productsGrid: this.getProductsGridConfig(),
                    servicesGrid: this.getServicesGridConfig(),
                    paymentsGrid: this.getPaymentsGridConfig(),
                    warehousesGrid: this.getWarehousesGridConfig()
                }
            }

            $scope.$watch('[filters.recipient.Id, ' +
                'filters.supplier.Id, ' +
                'filters.allowOrderDateFilter, ' +
                'filters.allowDeliveryDateFilter]', () => {
                    delete $scope.selectedOrder;
                    $scope.allowDelete = false;
                    $scope.showRefreshInfo = true;
                    this.clearDetailsTab();
                });

            $scope.$watchCollection('[filters.types, filters.statuses]', () => {
                delete $scope.selectedOrder;
                $scope.allowDelete = false;
                $scope.showRefreshInfo = true;
                this.clearDetailsTab();
            });

            crmService.getCompanyBranchesByStatus(Crm.Services.CompanyBranchStatus.Customer).then((data) => {
                $scope.components.recipientLookup.dataSource = data.Result.toDatasource();
                return data;
            });

            crmService.getCompanyBranchesByStatus(Crm.Services.CompanyBranchStatus.Own).then((data) => {
                $scope.components.senderLookup.dataSource = data.Result.toDatasource();
                return data;
            });
            
            $scope.events =
                {
                    refreshOrders: this.refreshOrders,
                    editOrder: this.editOrder,
                    deleteOrder: this.deleteOrder,
                    changePreOrderStatus: this.changePreOrderStatus,
                    getStatusName: (id): string => {
                        var result = _.find($scope.preorderAllStatuses, (item) => { return item.Id == id });
                        var name: string = "";
                        if (result)
                            name = result.Name
                        return name;
                    },
                    calculateRcount:this.calculateRcount
                };
            var dictionaryService = Core.Utils.Helper.getServiceFromJs('dictionaryService');



            dictionaryService.getFixValChildHierarchy(dictionaryService.mgrIdCrmOrders).then(res => {
                $scope.preorderAllTypes = [];
                Operations.Controllers.PreOrders.FixedValuesForCrmOrderFunctionality = res.Result;

                _.forEach(res.Result, (item: any) => {
                    if (item.MgrId == 'd9bdb09ee8eb47caa596ae90fab3ec5d' && item.HCode != 20 && item.Vi == 1) {
                        $scope.preorderAllTypes.push({ Id: item.HCode, Value: item.Name });
                    }
                });
                $scope.preorderAllTypes = _.sortBy($scope.preorderAllTypes, (item) => {
                    return item.Id;
                });
            });

            dictionaryService.getFixVal('541F7AB52CE04C4E96E959AA5DA1438A').then(res => {
                var templates = '';
                _.forEach(res.Result, (item: any) => {
                    templates += '<div  ng-if="dataItem.DocType === ' + item.Vi + '"><span>' + item.Name + '</span></div> ';
                });
                $scope.docTypeTemplate = templates;

                $scope.components.detailsComponents.documentsGrid = this.getDocumentsGridConfig();

            });

            this.$timeout(() => {
                $('#multiDeletePayment').on('click', (e) => {
                    if ($scope.selectedPayment != undefined && $scope.selectedPayment != null) {
                        var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                        var r = Core.Utils.ModalForm.openConfirmation(translate.instant("deleteConfirmation"));
                        r.result.then(() => {
                            if ($scope.selectedPayment != undefined && $scope.selectedPayment != null) {
                                crmService.deleteCrmOrderPayment($scope.selectedPayment.Id).then(res => {
                                    if (res.success != false) {
                                        for (var i = 0; i < $scope.orderPayments.length; i++) {
                                            var payment = $scope.orderPayments[i];
                                            if (payment.Id === $scope.selectedPayment.Id) {
                                                $scope.orderPayments.splice(i, 1);
                                                delete $scope.selectedPayment;
                                                $scope.components.detailsComponents.paymentsGrid.dataSource.data($scope.orderPayments);
                                                break;
                                            }
                                        };
                                        this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('successDeleted'));
                                    }
                                });
                            }
                        });
                    }
                });
            });


        }
        calculateRcount=(dataItem:any)=>{
            return (dataItem.SCount > (dataItem.RemInfo.RCount==undefined? 0: dataItem.RemInfo.RCount) - (dataItem.RemInfo.RCount==undefined? 0: dataItem.RemInfo.Rezerved)? 2: (dataItem.CCount > ((dataItem.RemInfo.RCount==undefined)? 0: dataItem.RemInfo.RCount) - (dataItem.RemInfo.RCount? 0: dataItem.RemInfo.Rezerved)? 1: 0))
        }
        getOrdersGrid = (): Components.IApxGrid => {


            var dataSourceModel = {
                fields: {
                    'order_id': { type: 'number' },
                    'recipient_id': { type: 'number' },
                    'supplier_id': { type: 'number' },
                    'order_status': { type: 'number' },
                    'order_type': { type: 'number' },
                    'order_date': { type: 'date' },
                    'delivery_date': { type: 'date' },
                    'recipient_branch_name': { type: 'string' },
                    'supplier_branch_name': { type: 'string' },
                    'prod_amount': { type: 'number' },
                    'service_amount': { type: 'number' },
                    'total_amount': { type: 'number' },
                    'note': { type: 'string' },
                    'cuser': { type: 'string' }
                }
            };

            
            var ordersGrid: Components.IApxGrid = {
                columns: [
                    { field: 'order_id', width: 80, titleTranslated: 'ordernum', sortable: { initialDirection: "desc" } },
                    { field: 'recipient_id', hidden: true },
                    { field: 'supplier_id', hidden: true },
                    {
                        field: 'order_status',
                        width: 150,
                        filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                filter: 'contains',
                                change: () => { this.$scope.showRefreshInfo = false },
                                dataSource: 
                                [
                                    { value: null, text: this.$translate.instant('ALL') },
                                    { value: 0, text: 'პროექტი' },
                                    { value: 1, text: 'დადასტურებული' },
                                    { value: -1, text: 'გაუქმებული' },
                                    { value: 10, text: 'შესაგროვებელი' },
                                    { value: 11, text: 'შესაგროვებელი სხვა ფილიალიდან გადმოსატანად' },
                                    { value: 12, text: "შესაგროვებელი სხვა ფილიალიდან გასაყიდად" },
                                    { value: 15, text: 'შესაგროვებელი დაბეჭდილი' },
                                    { value: 20, text: 'შეგროვებული' },
                                    { value: 30, text: 'ბუღალტერიაში ასახული' },
                                    // { value: 31, text: ' ' },
                                    // { value: 32, text: ' ' },
                                    { value: 40, text: 'მიტანილი' }
                                    
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value"
                            })
                        },
                        titleTranslated: "status",
                        template:
                        '<div  ><span>{{options.parentScope.events.getStatusName(dataItem.order_status)}}</span></div> '
                    },
                    {
                        field: 'order_type',
                        width: 150,
                        filterable: 'custom',
                        filterTemplate: (args) => {
                            var elem: JQuery = args.element;
                            elem.kendoDropDownList({
                                filter: 'contains',
                                change: () => { this.$scope.showRefreshInfo = false },
                                dataSource: [
                                    { value: null, text: this.$translate.instant('ALL') },
                                    { value: 1, text: 'საცალო რეალიზაცია' },
                                    { value: 2, text: 'საბითუმო რეალიზაცია' },
                                    { value: 3, text: 'დისტრიბუცია' },
                                    { value: 10, text: 'გადაადგილება' }
                                ],
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value",
                                open: (e) => {
                                    var listContainer = e.sender.list.closest(".k-list-container");
                                    listContainer.width(listContainer.width() + 50);
                                }
                            })
                        },
                        titleTranslated: "type",
                        template:
                        '<div  ng-if="dataItem.order_type === 1"><span>საცალო</span></div> ' +
                        '<div  ng-if="dataItem.order_type === 2"><span>საბითუმო</span></div>' +
                        '<div  ng-if="dataItem.order_type === 3"><span>დისტრიბუცია</span></div>' +
                        '<div  ng-if="dataItem.order_type === 10"><span>გადაადგილება</span></div>'
                    },
                    { field: 'order_date', filterable: false, format: Core.Kendo.Format.Date, width: 90, titleTranslated: 'DATE' },
                    { field: 'delivery_date', filterable: false, format: Core.Kendo.Format.Date, width: 90, titleTranslated: 'delivery' },
                    { field: 'pay_date', filterable: false, titleTranslated: 'pay', format: Core.Kendo.Format.Date, width: 90 },
                    { field: 'recipient_branch_name', width: 320, titleTranslated: 'receiver' },
                    { field: 'supplier_branch_name', width: 320, titleTranslated: 'sender' },
                    { field: 'prod_amount', width: 90, titleTranslated: 'prodAmount', format: Core.Kendo.Format.Number },
                    { field: 'service_amount', width: 90, titleTranslated: 'serviceAmount', format: Core.Kendo.Format.Number },
                    { field: 'total_amount', width: 90, titleTranslated: 'fullAmount', format: Core.Kendo.Format.Number },
                    { field: 'payed_amount', titleTranslated: 'payedAmount', format: Core.Kendo.Format.Price2, width: 90 },
                    { field: 'note', width: 160, titleTranslated: 'booknote' },
                    { field: 'cuser', width: 110, titleTranslated: 'user' },
                    { field: 'crtime', width: 130, titleTranslated: 'crtime', format: Core.Kendo.Format.DateTime, filterable: false }
                ],
                settings: {
                    pageable: true,
                    autoBind: false
                },
                parentScope: this.$scope,
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.selectedOrder = this.$scope.components.ordersGrid.gridView.selectedItem();

                                if (this.$scope.selectedOrder != undefined && this.$scope.selectedOrder.order_status === 0)
                                    this.$scope.allowDelete = true;
                                else {
                                    this.$scope.allowDelete = false;
                                }

                                if (this.$scope.selectedOrder != null) {
                                    this.updateCrmOrderDetails();
                                }
                                else {
                                    this.clearDetailsTab();
                                }
                            });
                        }
                    }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.crmService.OrdersQueryUrl, dataSourceModel,"&jsconfig=dh:iso8601")
            };
            ordersGrid.dataSource.options.schema.model.id = 'order_id';
            ordersGrid.dataSource.options.schema.model.fields = {
                'order_status': { type: 'number' }
            }
            return ordersGrid;
        }


        fillDetailsTab = (crmOrderdetails: any) => {

            if (crmOrderdetails.Products != undefined && crmOrderdetails.Products.length > 0) {
                this.$scope.components.detailsComponents.productsGrid.dataSource.data(crmOrderdetails.Products);
            }
            else {
                this.$scope.components.detailsComponents.productsGrid.dataSource.data([]);
            }

            if (crmOrderdetails.Services != undefined && crmOrderdetails.Services.length > 0) {
                this.$scope.components.detailsComponents.servicesGrid.dataSource.data(crmOrderdetails.Services);
            }
            else {
                this.$scope.components.detailsComponents.servicesGrid.dataSource.data([]);
            }

            if (crmOrderdetails.Payments != undefined && crmOrderdetails.Payments.length > 0) {
                this.$scope.orderPayments = crmOrderdetails.Payments;
                this.$scope.components.detailsComponents.paymentsGrid.dataSource.data(crmOrderdetails.Payments);
            }
            else {
                this.$scope.components.detailsComponents.paymentsGrid.dataSource.data([]);
            }

            if (crmOrderdetails.Documents != undefined && crmOrderdetails.Documents.length > 0) {
                this.$scope.components.detailsComponents.documentsGrid.dataSource.data(crmOrderdetails.Documents);
            }
            else {
                this.$scope.components.detailsComponents.documentsGrid.dataSource.data([]);
            }

            if (crmOrderdetails.ProductWhs != undefined && crmOrderdetails.ProductWhs.length > 0) {
                this.$scope.components.detailsComponents.warehousesGrid.dataSource.data(crmOrderdetails.ProductWhs);
                
            }
            else {
                this.$scope.components.detailsComponents.warehousesGrid.dataSource.data([]);
            }

        };

        clearDetailsTab = () => {
            this.$scope.components.detailsComponents.productsGrid.dataSource.data([]);
            this.$scope.components.detailsComponents.servicesGrid.dataSource.data([]);
            this.$scope.components.detailsComponents.paymentsGrid.dataSource.data([]);
            this.$scope.orderPayments = [];
            this.$scope.components.detailsComponents.warehousesGrid.dataSource.data([]);
            if (this.$scope.components.detailsComponents.documentsGrid != undefined)
                this.$scope.components.detailsComponents.documentsGrid.dataSource.data([]);
        }


        getServicesGridConfig = (): Components.IApxGrid => {
            var servicesGrid: Components.IApxGrid;
            servicesGrid = {
                settings: { filterDisabled: true },
                dataSource: new kendo.data.DataSource({ data: [] }),
                columns: this.serviceGridColumns,
            };
            return servicesGrid;
        }


        getPaymentsGridConfig = (): Components.IApxGrid => {
            var paymentsGrid: Components.IApxGrid;
            paymentsGrid = {
                settings: {
                    editble: { disableEditRow: true },
                    gridButtons: { multiDelete: { enabled: true, id: 'Payment' } }, filterDisabled: true
                },
                dataSource: new kendo.data.DataSource({
                    data: [],
                    schema: {
                        model: {
                            fields: {
                                'Doc.DDate': { type: 'date' },
                                'Doc.NumberIn': { type: 'string' },
                                'PaymentType': { type: 'number' },
                                'Amount': { type: 'number' }
                            }
                        }
                    }
                }),
                columns: this.paymentsGridColumns,
                gridEvents: [
                    {
                        eType: Components.EventType.Change,
                        eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.selectedPayment = this.$scope.components.detailsComponents.paymentsGrid.gridView.selectedItem();
                            });
                        }
                    }
                ]
            };
            return paymentsGrid;
        }

        getDocumentsGridConfig = (): Components.IApxGrid => {
            var documentsGrid: Components.IApxGrid;
            documentsGrid = {
                settings: { filterDisabled: true },
                dataSource: new kendo.data.DataSource({ data: [] }),
                columns: this.getDocumentColumns()
            };
            return documentsGrid;
        }

        getProductsGridConfig = (): Components.IApxGrid => {
            var productsGrid: Components.IApxGrid;
            productsGrid = {
                settings: { filterDisabled: true },
                columns: this.productsGridColumns,
                dataSource: new kendo.data.DataSource({ data: [] })
            };
            return productsGrid;
        }

        getWarehousesGridConfig = (): Components.IApxGrid => {
            var warehousesGrid: Components.IApxGrid;
            warehousesGrid = {
                settings: {
                    filterDisabled: true,
                    groupable: true
                },
                columns: this.warehousesGridColumns,
                parentScope:this.$scope,
                dataSource: new kendo.data.DataSource({
                    data: [],
                    schema: {
                        model: {
                            fields: {
                                'CompanyBranch.Name': { type: 'string' },
                                'OrderProduct.Prod.Name': { type: 'string' },
                                'OrderProduct.Prod.Unit': { type: 'string' },
                                'OrderProduct.Prod.Producer.ProducerName': { type: 'string' },
                                'OrderProduct.Prod.Category.CategoryName': { type: 'string' },
                                'SCount': { type: 'number' },
                                'CCount': { type: 'number' },
                                'RemInfo.RCount': { type: 'number' },
                                'RemInfo.SysRCount': { type: 'number' },
                                'RemInfo.FxRcount': { type: 'number' },
                                'RemInfo.Rezerved': { type: 'number' }
                            }
                        }
                    },
                    group: [
                        {
                            field: 'ProductStatus'
                        },
                        {
                            field: 'CompanyBranch.Name'
                        }
                    ]
                })
            };
            return warehousesGrid;
        }

        serviceGridColumns: Array<Core.Interfaces.IGridColumn> =
        [
            { field: 'Service.Name', titleTranslated: 'nameCaption' },
            { field: 'Service.Code', titleTranslated: 'code', width: 150 },
            { field: 'SCount', titleTranslated: 'Count', width: 140, format: Core.Kendo.Format.Price2 },
            { field: 'Discount', titleTranslated: 'discountpercent', width: 80, format: Core.Kendo.Format.Percent },
            { field: 'Price', titleTranslated: 'price', width: 140, format: Core.Kendo.Format.Price2 },
            { field: 'Amount', titleTranslated: 'amount', width: 140, format: Core.Kendo.Format.Price2 }
        ];

        getDocumentColumns = () => {
            var templates = this.$scope.docTypeTemplate;
            var documentssGridColumns: Array<Core.Interfaces.IGridColumn> =
                [
                    { field: 'DDate', width: 170, format: Core.Kendo.Format.Date, titleTranslated: 'DATE' },
                    {
                        field: 'DocType', template: templates, titleTranslated: 'type'
                    },
                    { field: 'NumberIn', titleTranslated: 'numberin', width: 150 },
                    { field: 'NumberOut', titleTranslated: 'numberout', width: 150 },
                    { field: 'DocWaybillNum', titleTranslated: 'WaybillNum', width: 100 },
                    { field: 'Vg', titleTranslated: 'amount', width: 140, format: Core.Kendo.Format.Price2 }
                ];
            return documentssGridColumns;
        }




        paymentsGridColumns: Array<Core.Interfaces.IGridColumn> =
        [
            { field: 'Doc.DDate', width: 170, format: Core.Kendo.Format.Date, titleTranslated: 'DATE' },
            { field: 'Doc.NumberIn', titleTranslated: 'numberin' },
            {
                field: 'PaymentType', titleTranslated: 'type',
                template:
                '<div  ng-if="dataItem.PaymentType === 0"><span>ნაღდი</span></div> ' +
                '<div  ng-if="dataItem.PaymentType === 1"><span>ბარათი</span></div> ' +
                '<div  ng-if="dataItem.PaymentType === 3"><span>კრედიტი</span></div>' +
                '<div  ng-if="dataItem.PaymentType === 2"><span>გადარიცხვა</span></div>' +
                '<div  ng-if="dataItem.PaymentType === 10"><span>სხვა</span></div>'
            },
            { field: 'Amount', titleTranslated: 'amount', width: 140, format: Core.Kendo.Format.Price2 }

        ];

        productsGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Prod.BCode', titleTranslated: 'barcode', width: 110 },
            { field: 'Prod.Name', titleTranslated: 'caption', width: 200 },
            { field: 'Prod.Category.CategoryName', titleTranslated: 'categoryName', width: 200 },
            { field: 'Prod.Producer.ProducerName', titleTranslated: 'producer', width: 200 },
            { field: 'Prod.Unit', titleTranslated: 'unit', width: 50 },
            { field: 'Discount', titleTranslated: 'discountpercent', width: 80, format: Core.Kendo.Format.Percent },
            { field: 'MinPrice', format: Core.Kendo.Format.Price2, width: 80, titleTranslated: 'minprice' },
            { field: 'Price', titleTranslated: 'price', width: 80, format: Core.Kendo.Format.Price2 },
            { field: 'OCount', titleTranslated: 'Count', width: 80, format: Core.Kendo.Format.Price2 },
            { field: 'Amount', titleTranslated: 'amount', width: 50, format: Core.Kendo.Format.Price2, }
        ];

        warehousesGridColumns: Array<Core.Interfaces.IGridColumn> = [
            {
                field: 'ProductStatus', titleTranslated: 'status', width: 150, template:
               '<div  ><span>{{options.parentScope.events.getStatusName(dataItem.ProductStatus)}}</span></div> ',
                groupHeaderTemplate:
                '<span >{{options.parentScope.events.getStatusName(#= value #)}}</span>' 
            },
            { field: 'OrderProduct.Prod.BCode', titleTranslated: 'barcode', width:120 },
            { field: 'CompanyBranch.Name', titleTranslated: 'branch', width: 220 },
            { field: 'OrderProduct.Prod.Name', titleTranslated: 'caption', width:300 },
            { field: 'OrderProduct.Prod.Unit', titleTranslated: 'unit', width: 100 },
            { field: 'OrderProduct.Prod.Producer.ProducerName', titleTranslated: 'producer', width: 250 },
            { field: 'OrderProduct.Prod.Category.CategoryName', titleTranslated: 'categoryName', width: 250 },
            { field: 'CCount', titleTranslated: 'ccount', width: 80, format: Core.Kendo.Format.Price2 },
            { field: 'SCount', titleTranslated: 'scount', width: 80, format: Core.Kendo.Format.Price2 },
            { field: 'RemInfo.RCount', titleTranslated: 'rest', width: 80, format: Core.Kendo.Format.Price2 },
            { field: 'RemInfo.SysRCount', titleTranslated: 'sysRcount', width: 80, format: Core.Kendo.Format.Price2 },
            { field: 'RemInfo.FxRcount', titleTranslated: 'fxRcount', width: 80, format: Core.Kendo.Format.Price2 },
            { field: 'RemInfo.Rezerved', titleTranslated: 'reserve', width: 80, format: Core.Kendo.Format.Price2 },
            { field: 'RStatus', titleTranslated: 'status',width: 80,
            template:`
                <div ng-if="options.parentScope.events.calculateRcount(dataItem)==0" popover-placement="left" popover-trigger="mouseenter" uib-popover="ნორმალური">
                    <i class="fa fa-circle fa-align-center text-success" ></i>
                </div>
                <div ng-if="options.parentScope.events.calculateRcount(dataItem)==1" popover-placement="left" popover-trigger="mouseenter" uib-popover="შეგროვება შეუძლებელია">
                    <i class="fa fa-circle fa-align-center text-warning" ></i>
                </div>
                <div ng-if="options.parentScope.events.calculateRcount(dataItem)==2" popover-placement="left" popover-trigger="mouseenter" uib-popover="ჩახურვა შეუძლებელია">
                    <i class="fa fa-circle fa-align-center text-danger" ></i>
                </div>
            `}
        ];



        public static branchcolumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Id', hidden: true },
            { field: 'Name', width: 150, titleTranslated: 'nameCaption' },
            { field: 'Code', titleTranslated: 'code', width: 50 },
            { field: 'Company.Code', titleTranslated: 'snFull', width: 60 },
            { field: 'Address.FullAddress', titleTranslated: 'address', width: 100 },
        ];

        public refreshOrders = () => {
            this.$scope.components.ordersGrid.gridView.showLoading();
            this.$scope.components.ordersGrid.gridView.setFilter(this.getOrderFilters());
            this.$scope.components.ordersGrid.dataSource.sort({ field: "order_id", dir: "desc" });
            this.$scope.showRefreshInfo = false;
            this.updateCrmOrderDetails();
        };

        changePreOrderStatus = () => {
            var data: any = { selectedOrder: this.$scope.selectedOrder }
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("changepreorderstatus.modal.html", "changePreOrderStatusModal" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result != undefined && result !== false) {
                   
                    
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    Core.Utils.Helper.safeApply(this.$scope, () => {
                        this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                        this.$scope.events.refreshOrders();
                         this.updateCrmOrderDetails();
                    });
                }
            });
        };

        editOrder = () => {
            Utils.CrmPreOrders.OpenEditCrmPreorderForm(this.$scope.selectedOrder.order_id, this.$scope.events.refreshOrders);
        };

        updateCrmOrderDetails=()=>{
             if (this.$scope.selectedOrder != null) {
                this.crmService.getCrmOrderDetails(this.$scope.selectedOrder.order_id, null, true, true, true, true, true, true).then(result => {
                        this.fillDetailsTab(result);
                    });
            }
        }

        deleteOrder = () => {
            Utils.CrmPreOrders.DeleteCrmPreorder(this.$scope.selectedOrder.order_id,
                () => {
                    delete this.$scope.selectedOrder;
                    this.$scope.allowDelete = false;
                    this.$scope.events.refreshOrders();
                });
        };

        checkeNullAndUndefined(val) {
            return typeof val !== "undefined" && val !== null;
        }

        getOrderFilters = () => {

            var filters: Array<Core.Interfaces.IGridFilter> = [
                { filterName: 'order_type', filterOperator: 'In', filterValue: this.$scope.filters.types },
                { filterName: 'order_status', filterOperator: 'In', filterValue: this.$scope.filters.statuses }
            ];

            if (this.$scope.filters.recipient != undefined) {
                filters.push({ filterName: 'recipient_id', filterOperator: '', filterValue: this.$scope.filters.recipient.Id.toString() });
            }

            if (this.$scope.filters.supplier != undefined) {
                filters.push({ filterName: 'supplier_id', filterOperator: '', filterValue: this.$scope.filters.supplier.Id.toString() });
            }

            if (this.$scope.filters.allowOrderDateFilter && this.checkeNullAndUndefined(this.$scope.filters.orderDateBetween.fromDate) && this.$scope.filters.orderDateBetween.fromDate.getTime() > 0 &&
                this.checkeNullAndUndefined(this.$scope.filters.orderDateBetween.toDate) && this.$scope.filters.orderDateBetween.toDate.getTime() > 0) {
                var from = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.orderDateBetween.fromDate);
                var to = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.orderDateBetween.toDate);
                filters.push({
                    filterName: 'order_date',
                    filterOperator: 'Between',
                    filterValue: [from, to]
                });
            }
            if (this.$scope.filters.allowDeliveryDateFilter && this.checkeNullAndUndefined(this.$scope.filters.deliveryDateBetween.fromDate) && this.$scope.filters.deliveryDateBetween.fromDate.getTime() > 0 &&
                this.checkeNullAndUndefined(this.$scope.filters.deliveryDateBetween.toDate) && this.$scope.filters.deliveryDateBetween.toDate.getTime() > 0) {
                var from = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.deliveryDateBetween.fromDate);
                var to = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.deliveryDateBetween.toDate);
                filters.push({
                    filterName: 'delivery_date',
                    filterOperator: 'Between',
                    filterValue: [from, to]
                });
            }
            return filters;
        };
    }

    export class ChangePreOrderStatusModal extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'crmService', Globals.modalInstance, Globals.toaster, Globals.http
        ];
        constructor(private $scope: any, data: any, private crmService: Crm.Services.Crm,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService) {
            super(arguments, ChangePreOrderStatusModal.$inject);
            $scope.selectedOrder = data.selectedOrder;
            $scope.formName = "სტატუსის ცვლილება. შეკვეთის №: " + $scope.selectedOrder.order_id;
            $scope.model = { selectedStatus: $scope.selectedOrder.order_status };
            $scope.closeForm = this.closeForm;
            $scope.save = this.save;
            this.getPrOrderStatus();

        }

        getPrOrderStatus = () => {
            this.crmService.getCrmPrOrderStatuses().then(success => {
                this.$scope.preorderStatuses = [];
                this.$scope.preorderStatuses = _.sortBy(success.Result, (item) => { return item.Id });
            }, error => {

            });
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {            
            this.crmService.changeCrmOrderStatus(this.$scope.selectedOrder.order_id, this.$scope.model.selectedStatus).then((result) => {
                if (result.success != false)
                    this.$uibModalInstance.close(this.$scope.model.selectedStatus);
                else {
                    this.$uibModalInstance.close(false);
                }
            },error=>{ this.closeForm();});

        }
    }
}