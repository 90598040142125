/// <reference path='../../_reference.ts' />
module Apex.Operations.Controllers {
    export interface IBookInventoryModalScope extends angular.IScope {
        model: {
            operIn: Account.Interfaces.IOperInfo,
            operOut: Account.Interfaces.IOperInfo,
            date: Date,
            saveEnabled: boolean,
            dataLoaded: boolean,
            operInDisabled: boolean,
            operOutDisabled: boolean,
            invInfo: DTOs.IInventarisationInfo,
            rDetails: Array<DTOs.IInventarisationRDetails>
        },
        events?: {
            save: Function,
            cancel: Function
        },
        filters: {
            operInLookupOptions: operationsInLookupOptions,
            operOutLookupOptions: operationsOutLookupOptions
        }
    }

    export class BookInventoryModal extends Core.Controller {
        public static $inject = [Globals.scope, 'crmService', 'warehousingService', 'accountService', 'dialogs', Globals.translate, Globals.toaster, 'data', Globals.modalInstance];

        constructor(private $scope: IBookInventoryModalScope, private crmService: Crm.Services.Crm, private warehousingService: Services.Warehousing, private accountService: Apex.Account.Services.Account, private dialogs: any, private $translate: any, private toaster: any, private data: BookInventoryModalData, private $uibModalInstance) {
            super(arguments, BookInventoryModal.$inject);
            this.setupScope($scope, accountService);
            this.loadData();
        }

        setupScope = (scope: IBookInventoryModalScope, accountService: Account.Services.Account) => {
            scope.filters = {
                operInLookupOptions: new operationsInLookupOptions(scope, this.accountService),
                operOutLookupOptions: new operationsOutLookupOptions(scope, accountService)
            };
            scope.model = {
                operIn: null,
                operOut: null,
                date: new Date(),
                dataLoaded: false,
                saveEnabled: false,
                invInfo: null,
                rDetails: null,
                operInDisabled: true,
                operOutDisabled: true,
            };
            scope.events = {
                save: this.save,
                cancel: () => {
                    this.$uibModalInstance.close({ status: 0, message: "cancel" });
                }
            }
        }

        save = () => {
            this.startProgress();
            var lst = <Array<DTOs.IReagInventarisationPair>>[];
            if (this.$scope.model.rDetails)
                this.$scope.model.rDetails.forEach(x => {
                    lst.push({ T2Id: x.T2Id, Reag: x.RegLeft });
                });
            var operInId = this.$scope.model.operIn ? this.$scope.model.operIn.OperID : null;
            var operOutId = this.$scope.model.operOut ? this.$scope.model.operOut.OperID : null;
            this.warehousingService.BookInventory(this.$scope.model.invInfo.T1Id, operInId, operOutId, this.$scope.model.date, lst).then(res => {
                this.stopProgress();
                this.$uibModalInstance.close({ status: 1, message: "შენახვა წარმატებით დასრულდა!" });
            });
        }

        loadData = () => {
            var loadRunning = new Array<Boolean>(false, false, false, false);
            this.warehousingService.GetInventoryInfo(this.data.t1Id, null, null, null).then(this.processInvInfo);
        }

        processInvInfo = (res: DTOs.ISimpleListResult<DTOs.IInventarisationInfo>) => {
            if (res && res.Result && res.Result.length > 0) {
                this.$scope.model.invInfo = res.Result[0];
                this.warehousingService.GetInventoryRDetails(res.Result[0].T1Id).then(this.processRDetails);
            }
            else {
                this.$uibModalInstance.close({ status: 0, message: "InvNotFound" });
            }
        }

        processRDetails = (res: DTOs.ISimpleListResult<DTOs.IInventarisationRDetails>) => {
            if (res && res.Result && res.Result.length > 0) {
                this.$scope.model.rDetails = res.Result;
                if (_.any(res.Result, x => {
                    return x.RegLeft > 0;
                })) {
                    this.$scope.model.operInDisabled = false;
                    if (this.$scope.filters.operInLookupOptions.dataSource.data())
                        this.$scope.model.operIn = this.$scope.filters.operInLookupOptions.dataSource.data()[0];
                }


                if (_.any(res.Result, x => {
                    return x.RegLeft < 0;
                })) {
                    this.$scope.model.operOutDisabled = false;
                    if (this.$scope.filters.operOutLookupOptions.dataSource.data())
                        this.$scope.model.operOut = this.$scope.filters.operOutLookupOptions.dataSource.data()[0];
                }
                this.stopProgress();
            } else {
                this.$uibModalInstance.close({ status: 0, message: "ListIsEmpty" });
            }
        }

        startProgress = () => {
            kendo.ui.progress($('.modal-content'), true);
        }
        stopProgress = () => {
            kendo.ui.progress($('.modal-content'), false);
        }
    }

    export class operationsInLookupOptions implements Components.IApxLookup {
        constructor(private scope: IBookInventoryModalScope, private accountService: Account.Services.Account) {
        }
        dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    this.accountService.getOpers(9110).then((result) => {
                        options.success(result.Result);
                        if (!this.scope.model.operInDisabled)
                            this.scope.model.operIn = result.Result[0];
                    });
                }
            }
        });
        columns = [
            { field: 'OperID', width: 150, },
            { field: 'Name', width: 150, titleTranslated: 'nameCaption' }
        ];
        valueText = 'Name';
    }

    export class operationsOutLookupOptions implements Components.IApxLookup {
        constructor(private scope: IBookInventoryModalScope, private accountService: Account.Services.Account) {

        }
        dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    this.accountService.getOpers(9111).then((result) => {
                        options.success(result.Result);
                        if (!this.scope.model.operOutDisabled)
                            this.scope.model.operOut = result.Result[0];
                    });
                }
            }
        });
        columns = [
            { field: 'OperID', width: 150, },
            { field: 'Name', width: 150, titleTranslated: 'nameCaption' }
        ];
        valueText = 'Name';
    }

    export interface BookInventoryModalData {
        t1Id: Number
    }
}