/// <reference path="../../_reference.ts" />
module Apex.Operations.Controllers {  
    export interface IOrderWholesaleScope extends IBasePreOrderScope {
    }

    export interface IProductItem {
        key: string;
        value: any;
    }

    export interface IOrderWholesaleDeliveryInfoComponents {
        crmOrderPrepareListLookup: Components.IApxLookup;
        mobileUsersLookup: Components.IApxLookup;
        carDropdownOptions?: any;
        driverPersonalNumbersDropdownOptions?: any;
        driverNamesDropdownOptions?: any;
    }

    export class OrderWholesale extends BaseCrmOrderModal {
        $scope: IOrderWholesaleScope;
        constructor($scope: IOrderWholesaleScope, $rootScope: any, data: any, crmService: Crm.Services.Crm, commonService: Services.Common, distributionService: Services.Distribution, accountingService: Account.Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, $rootScope, data, crmService, commonService, distributionService, accountingService, $uibModalInstance, toaster, $http);
            $scope.OrderType=2;
            crmService.getCompanyBranchesByStatus(Crm.Services.CompanyBranchStatus.Customer).then((data) => {
                $scope.components.recipientLookup.dataSource = data.Result.toDatasource();
                return data;
            });          

            crmService.getCompanyBranchesByStatus(Crm.Services.CompanyBranchStatus.Own).then((data) => {
                $scope.components.senderLookup.dataSource.data(data.Result);
                return data;
            });

            $scope.saveOrder = this.saveOrder;
        }        

        saveOrder = () => {
            var order = this.convertOrderForSave(Utils.PreorderType.OrderWholesale);
            if (order != null) {  
                   
                this.saveCrmOrderOrder(order);
            }
        };       
        
        getPartial(): string {
            return 'modals\order.wholesale.html';
        }
    }
}