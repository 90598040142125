/// <reference path="../_reference.ts" />

module Apex.Operations.Services {
    'use strict';
    export class Warehousing extends Core.Service {
        queryProdView: string = "/Common/Dictionary/Goods/Query/";

        getLabelPrintJob(id: number): angular.IPromise<{ Result: Array<Interfaces.ILabelPrintJob> }> {
            return this.httpGet("/Warehousing/LabelPrintJob/" + id);
        }
        getLabelPrintJobs(from: string, to: string, branchId: number = null): angular.IPromise<{ Result: Array<Interfaces.ILabelPrintJob> }> {
            var requestUrl: string = "/Warehousing/LabelPrintJob?From=" + from + "&To=" +to;
            if (branchId != null)
                requestUrl += "&branchId=" + branchId;
            return this.httpGet(requestUrl+"&jsconfig=dh:iso8601");
        }
        getLabelPrintDynamicForms(): angular.IPromise<{ Result: any }> {
            return this.httpGet("/Core/Dynamic/Forms/PrintLabelForms?categoryId=93AAA67D-B1A4-4319-B2CD-8B40D0EAB6E9");
        }
        getLabelPrintJobProds(jobId: number): angular.IPromise<{ Result: Array<any> }> {
            return this.httpGet("/Warehousing/LabelPrintJobProdView?JobID=" + jobId);
        }

        saveLabelPrintJob(job: Interfaces.ILabelPrintJob): angular.IPromise<any> {
            return this.httpPut("/Warehousing/LabelPrintJob", { LabelPrintJob: job });
        }
        genInventoryDetails(t1Id: number, supplierId: string, categoryId: string, goodTypeId: string, producerId: string, hCategoryId: Number): angular.IPromise<any> {
            return this.httpPost("/Warehousing/InventarisationDetails/Gen", {
                T1Id: t1Id, AutoGenerate: true,
                AutoFilters: {
                    SupplierId: supplierId,
                    CategoryId: categoryId,
                    GoodTypeId: goodTypeId,
                    ProducerId: producerId,
                    HCategoryId: hCategoryId,
                },
                Materials: false
            });
        }

        saveLabelPrintProdList(prodList: Array<Interfaces.ILabelPrintProd>): angular.IPromise<any> {
            return this.httpPut("/Warehousing/LabelPrintJobProd", { PrintJobList: prodList });
        }

        deleteLabelPrintJob(id: number): angular.IPromise<any> {
            return this.httpDelete("/Warehousing/LabelPrintJob/" + id);
        }

        deleteLabelPrintProdList(idsList: Array<number>): angular.IPromise<any> {
            return this.httpDelete("/Warehousing/LabelPrintJobProd/List?ListOfIdsId=" + idsList);
        }

        getProductsNumber(
            productName: string,
            barcode: string,
            categoryId: string,
            producerId: string
        ): angular.IPromise<{ Result: number }> {
            var requestUrl = "/Warehousing/ProductsNumber?";

            if (productName != undefined && productName != null && productName.trim().length > 0) {
                requestUrl += '&Name=' + productName;
            }
            if (barcode != undefined && barcode != null && barcode.trim().length > 0) {
                requestUrl += '&Bcode=' + barcode;
            }
            if (categoryId != undefined && categoryId != null && categoryId.trim().length > 0) {
                requestUrl += '&CategoryId=' + categoryId;
            }
            if (producerId != undefined && producerId != null && producerId.trim().length > 0) {
                requestUrl += '&ProducerId=' + producerId;
            }

            return this.httpGet(requestUrl);
        }

        mergeLabelPrints(
            productName: string,
            barcode: string,
            categoryId: string,
            producerId: string,
            jobId: number,
            quantity: number
        ): angular.IPromise<any> {
            var params = { Quantity: quantity, JobId: jobId, ProducerId: producerId, CategoryId: categoryId, Bcode: barcode, Name: productName };
            return this.httpPut("/Warehousing/MergePrintLabels", params);
        }

        getAllGoods(
            productName: string,
            barcode: string,
            categoryId: string,
            producerId: string,
            hCategoryId: number,
            pageNum: number,
            pageSize: number): angular.IPromise<{ TotalPages: number, PageNum: number, Result: Array<Account.Interfaces.IGood> }> {

            var requestUrl: string = "/Common/Dictionary/Goods?PageNum=" + pageNum + "&PageSize=" + pageSize;

            if (productName != undefined && productName != null && productName.trim().length > 0) {
                requestUrl += '&Name=' + productName;
            }
            if (barcode != undefined && barcode != null && barcode.trim().length > 0) {
                requestUrl += '&BCode=' + barcode;
            }
            if (categoryId != undefined && categoryId != null && categoryId.trim().length > 0) {
                requestUrl += '&CategoryID=' + categoryId;
            }
            if (producerId != undefined && producerId != null && producerId.trim().length > 0) {
                requestUrl += '&ProducerID=' + producerId;
            }
            if (hCategoryId != undefined && hCategoryId != null && hCategoryId > 0) {
                requestUrl += '&HCategoryID=' + hCategoryId;
            }
            return this.httpGet(requestUrl);
        }


        getGoods(): angular.IPromise<{ TotalPages: number, PageNum: number, Result: Array<Account.Interfaces.IGood> }> {

            var requestUrl: string = "/Common/Dictionary/Goods"
            return this.httpGet(requestUrl);
        }

        //Inventory


        GetInventoryInfo(T1Id: Number, branchId: Number, SDate: any, EDate: any): angular.IPromise<DTOs.ISimpleListResult<DTOs.IInventarisationInfo>> {
            var resp =
                <angular.IPromise<DTOs.ISimpleListResult<DTOs.IInventarisationInfo>>>this.httpGet("/Warehousing/Inventarisation/",
                    {
                        nm: 'T1Id={0}&branchId={1}&SDate={2}&EDate={3}&jsconfig=dh:iso8601',
                        pr: [T1Id, branchId, SDate, EDate]
                    });
            resp.then(x => {
                if (x && x.Result) {
                    _.forEach(x.Result, y => {
                        if (!y.Block)
                            y.Block = false;
                        if (!y.Blocking)
                            y.Blocking = 0;
                    });
                }
            });
            return resp;
        }

        GetInventoryDocs(t1Id: Number): angular.IPromise<DTOs.ISimpleListResult<Apex.Operations.DTOs.IDocView>> {
            return <angular.IPromise<DTOs.ISimpleListResult<DTOs.IDocView>>>this.httpGet("/Warehousing/Inventarisation/InventarisationDocs/?T1Id=" + t1Id+"&jsconfig=dh:iso8601");
        }

        GetInventoryDetails(t1Id: Number): angular.IPromise<DTOs.ISimpleListResult<DTOs.IInventarisationDetails>> {
            return <angular.IPromise<DTOs.ISimpleListResult<DTOs.IInventarisationDetails>>>this.httpGet("/Warehousing/Inventarisation/GetInventarisationDetails/?T1Id=" + t1Id+"&jsconfig=dh:iso8601");
        }
        GetInventoryDetail(t2Id: Number): angular.IPromise<DTOs.ISimpleListResult<DTOs.IInventarisationDetails>> {
            return <angular.IPromise<DTOs.ISimpleListResult<DTOs.IInventarisationDetails>>>this.httpGet("/Warehousing/Inventarisation/GetInventarisationDetails/?T2Id=" + t2Id+"&jsconfig=dh:iso8601");
        }

        GetInventoryRDetails(t1Id: Number): angular.IPromise<DTOs.ISimpleListResult<DTOs.IInventarisationRDetails>> {
            return <angular.IPromise<DTOs.ISimpleListResult<DTOs.IInventarisationRDetails>>>this.httpGet("/Warehousing/Inventarisation/RDetails/?T1Id=" + t1Id+"&jsconfig=dh:iso8601");
        }

        BookInventory(t1Id: Number, operIn: string, operOut: string, dDate: Date, detailsList: Array<DTOs.IReagInventarisationPair>): any {
            return this.httpPost("/Warehousing/Inventarisation/BookInv", {
                T1Id: t1Id,
                OperIn: operIn,
                OperOut: operOut,
                DDate: dDate,
                DetailsList: detailsList
            });
        }

        unBookInventory(t1Id: Number): any {
            return this.httpDelete("/Warehousing/Inventarisation/Reag/?T1Id=" + t1Id);
        }

        GetInventoryInfoN(): angular.IPromise<DTOs.ISimpleResult<number>> {
            return <angular.IPromise<DTOs.ISimpleResult<number>>>this.httpGet("/Warehousing/InventarisationInfo/GetN");
        }
        GetInvDetailsN(): angular.IPromise<DTOs.ISimpleResult<string>> {
            return <angular.IPromise<DTOs.ISimpleResult<string>>>this.httpGet("/Warehousing/InventarisationDetails/GetN");
        }

        SaveInventoryInfo(invInfo: DTOs.IInventarisationInfo): angular.IPromise<DTOs.IInventarisationInfo> {
            let resp = <angular.IPromise<DTOs.SaveInventarisationInfoResponse>>this.httpPost("/Warehousing/Inventarisation/Info", {
                T1Id: invInfo.T1Id,
                InvN1: invInfo.InvN1,
                DDate: invInfo.DDate,
                CUserN: invInfo.CUserN,
                BranchId: invInfo.BranchId,
                Note: invInfo.Note,
                Blocking: invInfo.Blocking,
                Acc: invInfo.Acc,
                Block: invInfo.Block,
            });
            return resp.then(r => r.UpdatedData);
        }
        RecalcRCount(t1Id:number): angular.IPromise<any> {
            return <angular.IPromise<any>>this.httpPost("/Warehousing/Inventarisation/Details/ReCalcR/"+t1Id+"&jsconfig=dh:iso8601",{});
        }

        DeleteInventoryInfo(t1Id: number): angular.IPromise<any> {
            return <angular.IPromise<any>>this.httpDelete("/Warehousing/Inventarisation/Info/" + t1Id);
        }

        SaveInvDetails(details: DTOs.IInventarisationDetails): angular.IPromise<any> {
            return <angular.IPromise<any>>this.httpPost("/Warehousing/Inventarisation/Details/", {
                T2Id: details.T2Id,
                T1Id: details.T1Id,
                ICountI: details.IcountI,
                ProdId: details.ProdPPId,
                WithAdd: false,
            });
        }

        DeleteInvDetails(Id: Number): angular.IPromise<any> {
            return <angular.IPromise<any>>this.httpDelete("/Warehousing/Inventarisation/Details/" + Id);
        }
        //Inventory/
    }
}