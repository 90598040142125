/// <reference path="../../_reference.ts" />
module Apex.Operations.Controllers {
    export interface IOrderDistributionComponents extends IOrderBaseComponents {
        presalerLookup: Components.IApxLookup;
    }
    export interface IOrderDistributionModel extends IOrderBaseModel  {
        presaler: any;
        isGift: boolean;
    }
    export interface IOrderDistributionScope extends IBasePreOrderScope {
        components: IOrderDistributionComponents;
        model: IOrderDistributionModel;       
    }
    export class OrderDistribution extends BaseCrmOrderModal {
        $scope: IOrderDistributionScope;
        constructor($scope: IOrderDistributionScope, $rootScope: any, data: any, crmService: Crm.Services.Crm, commonService: Services.Common, distributionService: Services.Distribution, accountingService: Account.Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, $rootScope, data, crmService, commonService, distributionService, accountingService, $uibModalInstance, toaster, $http);
            this.$scope = $scope;
            if ($scope.Order != undefined) {
                this.$scope.model.isGift = $scope.Order.IsGift;
            }

            $scope.components.presalerLookup = {
                columns: [{ field: 'Code', width:50, titleTranslated: 'code' },
                    { field: 'FullName', titleTranslated: 'name' }
                ],
                valueText: 'FullName',
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: (options) => {
                            var model = this.$scope;

                            var fn = distributionService.getPresalers();
                            fn.then(result => {
                                if ($scope.Order != undefined) {
                                    var presaler = _.where(result.Result, { Id: $scope.Order.PresalerId })[0];
                                    $scope.model.presaler = presaler;
                                }

                                options.success(result.Result || []);
                            });
                        }
                    }
                })
            };

            crmService.getCompanyBranchesByStatus(Crm.Services.CompanyBranchStatus.Own).then((data) => {
                $scope.components.senderLookup.dataSource.data(data.Result);
                return data;
            });    
                       

            $scope.$watch('model.recipient', (recipientBranch: any) => {
                if (!$scope.Order && recipientBranch != undefined) {
                    crmService.getDeliveryTransport($scope.model.presaler.Id, recipientBranch.Id).then(res => {
                        if (res.success != false) {                          
                            if (res.CarInfo != undefined) {
                                $scope.model.deliveryInfo.carNumber = res.CarInfo.Number;
                                $scope.model.deliveryInfo.carMark = res.CarInfo.Description;
                            }
                            else {
                                $scope.model.deliveryInfo.carNumber = '';
                                $scope.model.deliveryInfo.carMark = '';
                            }
                            if (res.DriverInfo != undefined) {
                                $scope.model.deliveryInfo.driverName = res.DriverInfo.Name;
                                $scope.model.deliveryInfo.driverPersonalNumber = res.DriverInfo.PerNum;
                            }
                            else {
                                $scope.model.deliveryInfo.driverName = '';
                                $scope.model.deliveryInfo.driverPersonalNumber = '';
                            }
                            if (res.ForwarderUserId != undefined) {                               
                                var forwarder = _.where($scope.components.deliveryInfoComponents.mobileUsersLookup.dataSource.data(), { Id: res.ForwarderUserId })[0];
                                $scope.model.deliveryInfo.forwarder = forwarder;
                            }
                            else delete $scope.model.deliveryInfo.forwarder;

                        }
                    });
                }
            });
            
            $scope.$watch('model.presaler', (presaler: any) => {
                if (presaler != undefined) {               
                    distributionService.getMobileUsersByPresaler(presaler.Id).then(res => {
                        if (res.success != false) {
                            var users: Array<any> = res.Result;                         
                            if (users != undefined && users.length == 1) {
                                distributionService.getMobileUserCustomersView(users[0].Id).then(response => {
                                    var customers = _.map(response.Result, function (item: any) { return item.Customer; });
                                    $scope.components.recipientLookup.dataSource.data(customers);
                                });
                            }
                            else {
                                if (this.$scope.Order == undefined) {
                                    $scope.components.recipientLookup.dataSource.data([]);
                                    delete $scope.model.recipient;
                                }
                            }
                        }
                    });
                }
            });

            $scope.saveOrder = this.saveOrder;

        }

        saveOrder = () => {
            var order = this.convertOrderForSave(Utils.PreorderType.OrderDistribution);
            if (order != null) {                
                if (this.$scope.model.presaler != undefined)
                {
                    order.PresalerId = this.$scope.model.presaler.Id;
                    order.IsGift = this.$scope.model.isGift;
                }
                this.saveCrmOrderOrder(order);
            }
        };

        getPartial(): string {
            return 'modals\order.distribution.html';
        }
    }

}