/// <reference path="../_reference.ts" />
module Apex.Operations.Interfaces {
    export interface ILabelPrintJob {
        Id?: number;
        BranchId?: number;
        CrDate?: Date;
        UserId?: number;
        Note?: string;
        UserName?: string;
        Branch?: Crm.Interface.ICompanyBranch;
    }
}
