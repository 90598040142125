/// <reference path='../_reference.ts' />
module Apex.Operations.Utils {
    "use strict";

    export enum PreorderType {
        OrderRetail = 1,
        OrderWholesale = 2,
        OrderDistribution = 3,
        OrderTranzit = 10
    }

    export class CrmPreOrders {
        private static _crmService: Crm.Services.Crm;

        private static getCrmService(): Crm.Services.Crm {
            if (CrmPreOrders._crmService === undefined)
                CrmPreOrders._crmService = Core.Utils.Helper.getServiceFromJs("crmService");
            return CrmPreOrders._crmService;
        }

        public static DeleteCrmPreorder = (orderId: number, callback: Function = null) => {
            var confirmation = Core.Utils.ModalForm.openConfirmation("დარწმუნებული ხართ, რომ გსურთ შეკვეთის წაშლა?");
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            confirmation.result.then(() => {
                CrmPreOrders.getCrmService().deleteCrmOrder(orderId).then((result) => {
                    if (!!result.statusCode) {
                        toaster.pop('error', "Error", translate.instant('dontDelete'));
                    } else {
                        toaster.pop('success', translate.instant('Info'), translate.instant('successDeleted'));
                        if (callback !== null && callback !== undefined)
                            callback();
                    }
                    return "";
                });
            }
            );
        }

        public static OpenEditCrmPreorderForm(orderID, callback: Function = null) {
            var order;
            kendo.ui.progress($(document.body), true);
            CrmPreOrders.getCrmService().getCrmOrder(orderID).then(result => {
                order = result.Result;
                kendo.ui.progress($(document.body), false);
                
                if (order != undefined) {
                    var fixedValsForCrmOrders = Operations.Controllers.PreOrders.FixedValuesForCrmOrderFunctionality;           
                    var formData: any = { order: order, recipientStats: result.RecipientStats, contractDets: result.ContractDets, fixedVals: fixedValsForCrmOrders };
                    var dlg: any;
                    var modalHtmlName = "preorder.modal.html";
                    var modalSize = Core.Utils.ModalSize.Large;
                    var rootScope = Core.Utils.Helper.getServiceFromJs(Globals.rootScope);                 
                    switch (order.OrderType) {
                        case PreorderType.OrderRetail:
                            formData.formName = 'საცალო რეალიზაცია. შეკვეთის №:' + order.OrderId;
                            formData.PreorderType = 'OrderRetail';
                            dlg = Core.Utils.ModalForm.openModalForm(modalHtmlName, 'orderRetail' + 'Controller', formData, modalSize, false, '');
                            break;
                        case PreorderType.OrderWholesale:                           
                            formData.formName = 'საბითუმო რეალიზაცია. შეკვეთის №:' + order.OrderId;
                            formData.PreorderType = 'OrderWholesale';
                            dlg = Core.Utils.ModalForm.openModalForm(modalHtmlName, 'orderWholesale' + 'Controller', formData, modalSize, false, '');
                            break;
                        case PreorderType.OrderDistribution:
                            formData.formName = 'დისტრიბუცია. შეკვეთის №:' + order.OrderId;
                            formData.PreorderType = 'OrderDistribution';
                            dlg = Core.Utils.ModalForm.openModalForm(modalHtmlName, 'orderDistribution' + 'Controller', formData, modalSize, false, '');
                            break;
                        case PreorderType.OrderTranzit:
                            formData.formName = 'გადაადგილება. შეკვეთის №:' + order.OrderId;
                            formData.PreorderType = 'OrderTranzit';
                            dlg = Core.Utils.ModalForm.openModalForm(modalHtmlName, 'orderTranzit' + 'Controller', formData, modalSize, false, '');
                            break;
                    }                   
                    dlg.result.then(result => {
                        if (result != undefined && result.NewOrderId != undefined)
                            if (callback)
                                callback();
                        return "";
                    });
                }
            });
        }

        public static OpenAddCrmPreorderForm(type: number, callback: Function = null) {
            var fixedValsForCrmOrders = Operations.Controllers.PreOrders.FixedValuesForCrmOrderFunctionality;            
            var formData: any = { fixedVals: fixedValsForCrmOrders };
            var dlg: any;
            var modalHtmlName = "preorder.modal.html";
            var modalSize = Core.Utils.ModalSize.Large;
            var rootScope = Core.Utils.Helper.getServiceFromJs(Globals.rootScope);
            switch (type) {
                case PreorderType.OrderRetail:
                    formData.formName = 'საცალო რეალიზაცია';
                    formData.PreorderType = 'OrderRetail';
                    dlg = Core.Utils.ModalForm.openModalForm(modalHtmlName, 'orderRetail' + 'Controller', formData, modalSize, false, '');
                    break;
                case PreorderType.OrderWholesale:
                    formData.formName = 'საბითუმო რეალიზაცია';   
                    formData.PreorderType = 'OrderWholesale';                
                    dlg = Core.Utils.ModalForm.openModalForm(modalHtmlName, 'orderWholesale' + 'Controller', formData, modalSize, false, '');
                    break;
                case PreorderType.OrderDistribution:
                    formData.formName = 'დისტრიბუცია';
                    formData.PreorderType = 'OrderDistribution';
                    dlg = Core.Utils.ModalForm.openModalForm(modalHtmlName, 'orderDistribution' + 'Controller', formData, modalSize, false, '');
                    break;
                case PreorderType.OrderTranzit:
                    formData.formName = 'გადაადგილება';       
                    formData.PreorderType = 'OrderTranzit';           
                    dlg = Core.Utils.ModalForm.openModalForm(modalHtmlName, 'orderTranzit' + 'Controller', formData, modalSize, false, '');
                    break;
            }

            dlg.result.then(result => {
                if (result != undefined && result.NewOrderId != undefined)
                    if (callback)
                        callback();
                return "";
            });
        }
    }
}