/// <reference path='../_reference.ts' />
module Apex.Operations.Controllers {
    export interface ILabelsPrintScope extends angular.IScope {
        selectedProdNotExists: boolean;
        selectedJobNotExists: boolean;
        showRefreshInfo: boolean;
        branchesList: Array<Crm.Interface.ICompanyBranch>;
        model: {
            branch?: Apex.Crm.Interface.ICompanyBranch,
            dateBetween: any,
            report?: any
        };
        events: {
            refreshJobs: Function;
            addJob: Function;
            addProd: Function;
            editJob: Function;
            deleteJob: Function;
            deleteProdList: Function;
            print: Function;
        };
        components: {
            branchesLookup: Components.IApxLookup;
            reportsLookup: Components.IApxLookup;
            jobsGrid: Components.IApxGrid;
            prodsGrid: Components.IApxGrid;
        }
    }
    export class LabelsPrint extends Core.Controller {
        $scope: ILabelsPrintScope;
        warehousingService: Services.Warehousing;
        public static $inject = [Globals.scope, 'warehousingService', '$timeout', Globals.translate, Globals.toaster];
        constructor($scope: ILabelsPrintScope, warehousingService: Services.Warehousing, private $timeout: any, private $translate: any, private toaster: any) {
            super(arguments, LabelsPrint.$inject);
            this.$scope = $scope;
            this.warehousingService = warehousingService;
            var crmService: Crm.Services.Crm = Core.Utils.Helper.getServiceFromJs('crmService');
            $scope.selectedJobNotExists = $scope.selectedProdNotExists = true;
            $scope.showRefreshInfo = true;
            $scope.model = { dateBetween: {} };
            $timeout(() => {
                $("#labels-print-splitter").find(".split-handler").css("left", "40%");
            });
            $scope.components = {
                jobsGrid: this.getJobsGrid(),
                prodsGrid: this.getProdsGrid(),
                branchesLookup: this.getBranchesLookup(),
                reportsLookup: this.getReportsLookup()
            };

            $scope.events = {
                deleteProdList: this.deleteProdList,
                deleteJob: this.deleteJob,
                refreshJobs: this.refreshJobs,
                print: this.print,
                addJob: this.addJob,
                addProd: this.addProd,
                editJob: this.editJob
            };

            crmService.getCompanyBranchesByStatus(Crm.Services.CompanyBranchStatus.Own).then((data) => {
                $scope.components.branchesLookup.dataSource.data(data.Result);
                this.$scope.branchesList = data.Result;
                return data;
            });

            warehousingService.getLabelPrintDynamicForms().then((data) => {
                $scope.components.reportsLookup.dataSource.data(data.Result);
                return data;
            });

            $scope.$watch('model.dateBetween', () => { $scope.showRefreshInfo = true; });
            $scope.$watch('model.branch', () => { $scope.showRefreshInfo = true; });

            $scope.$watch('components.jobsGrid.grid', (grid: kendo.ui.Grid) => {
                if (grid != undefined) {
                    $("#lsbelprintjob-context-menu").kendoContextMenu({
                        target: "#targetGrid",
                        open: (e) => {
                            if ($scope.selectedJobNotExists) {
                                $("#editJob").css("opacity", "0.4");
                                $("#deleteJob").css("opacity", "0.4");

                            }
                            else {
                                $("#editJob").css("opacity", "1");
                                $("#deleteJob").css("opacity", "1");
                            }
                        }
                    });
                }
            });

        }

        addProd = () => {
            var data: any = { selectedJob: this.$scope.components.jobsGrid.selectedObject };
            var modalSize = Core.Utils.ModalSize.Large;
            var dlg = Core.Utils.ModalForm.openModalForm("add.labelprintprod.modal.html", "addLabelPrintProd" + 'Controller', data, modalSize, false);
            dlg.result.then(result => {
                if (typeof result != undefined && result !== false) {
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    Core.Utils.Helper.safeApply(this.$scope, () => {
                        this.refreshProds(); // todo check functionality here
                    });
                }
            });
        }

        addJob = () => {

            var data: any = { branchesList: this.$scope.branchesList }
            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("add.labelprintjob.modal.html", "addLabelPrintJob" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result != undefined && result !== false) {
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    Core.Utils.Helper.safeApply(this.$scope, () => {
                        // this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                        this.$scope.events.refreshJobs();
                    });
                }
            });

        }

        editJob = () => {

            var data: any = { branchesList: this.$scope.branchesList, selectedJob: this.$scope.components.jobsGrid.selectedObject };

            var modalSize = Core.Utils.ModalSize.Small;
            var dlg = Core.Utils.ModalForm.openModalForm("add.labelprintjob.modal.html", "addLabelPrintJob" + 'Controller', data, modalSize);
            dlg.result.then(result => {
                if (typeof result != undefined && result !== false) {
                    var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
                    Core.Utils.Helper.safeApply(this.$scope, () => {
                        //this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                        this.$scope.events.refreshJobs();
                    });
                }
            });

        }

        print = () => { 
            
            var accountingService: Account.Services.Account = Core.Utils.Helper.getServiceFromJs('accountService');
            accountingService.printReport(this.$scope.model.report.Unid, this.$scope.model.report.LayoutId, "@JobId", this.$scope.components.jobsGrid.selectedObject.Id);
        }

        deleteProdList = () => {
            var selectedRows = this.$scope.components.prodsGrid.grid.select();

            if (selectedRows.length == 0)
                return;

            var r: any;
            if (selectedRows.length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }

            r.result.then(() => {
                this.$scope.components.prodsGrid.gridView.showLoading();
                var listForDeletion: Array<number> = [];
                _.forEach(selectedRows, (item) => {
                    let obj = this.$scope.components.prodsGrid.grid.dataItem(item);
                    listForDeletion.push(obj.get("Id"));
                });
                this.warehousingService.deleteLabelPrintProdList(listForDeletion).then(res => {
                    if (res.success != false) {
                        this.$scope.selectedProdNotExists = true;
                        this.refreshProds();
                    }
                });
            });
        }

        deleteJob = () => { 
            if (!this.$scope.selectedJobNotExists) {
                var selectedObject: Interfaces.ILabelPrintJob = this.$scope.components.jobsGrid.selectedObject;
                var deleteConfirmationAlert = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("confirmdeletewarning"));
                deleteConfirmationAlert.result.then(() => {
                    this.warehousingService.deleteLabelPrintJob(selectedObject.Id).then(res => {
                        if (res.success != false) {
                            this.refreshJobs();
                        }
                    });
                });
            }

        }

        getBranchesLookup() {
            var lookUp: Components.IApxLookup = {
                dataSource: new kendo.data.DataSource({
                    data: [], schema: {
                        model: {
                            fields: {
                                'Id': { type: 'number' },
                                'Name': { type: 'string' },
                                'Code': { type: 'string' },
                                'Company.Code': { type: 'string' },
                                'Address.FullAddress': { type: 'string' }
                            }
                        }
                    }
                }),
                columns: [
                    { field: 'Id', hidden: true },
                    { field: 'Name', width: 150, titleTranslated: 'nameCaption' },
                    { field: 'Code', titleTranslated: 'code', width: 100 },
                    { field: 'Company.Code', titleTranslated: 'snFull', width: 60 },
                    { field: 'Address.FullAddress', titleTranslated: 'address', width: 100 },
                ],
                valueText: 'Name'
            };
            return lookUp;
        }

        getReportsLookup() {
            var lookUp: Components.IApxLookup = {
                dataSource: new kendo.data.DataSource({
                    data: [],
                    schema: {
                        model: {
                            fields: {
                                DynamicFormName: { type: 'string' },
                                LayoutName: { type: 'string' }
                            }
                        }
                    }
                }),
                columns: [
                    {
                        field: 'DynamicFormName', width: 150, titleTranslated: 'dynamicForm'
                    },
                    { field: 'LayoutName', titleTranslated: 'layout', width: 150 },

                ],
                valueText: 'LayoutName'
            };
            return lookUp;
        }

        refreshJobs = () => {
            this.$scope.components.jobsGrid.gridView.showLoading();
            if (this.$scope.showRefreshInfo) {
                delete this.$scope.components.jobsGrid.selectedObject;
                this.$scope.components.prodsGrid.grid.dataSource.data([]);
            }
            this.warehousingService.getLabelPrintJobs(
                Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.model.dateBetween.fromDate),
                Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.model.dateBetween.toDate),
                this.$scope.model.branch != undefined ? this.$scope.model.branch.Id : null).then(
                response => {
                    this.$scope.components.jobsGrid.dataSource.data(response.Result);
                    this.$scope.components.jobsGrid.gridView.hideLoading();
                    this.$scope.showRefreshInfo = false;
                }
                )
        };

        getJobsGrid() {
            var grid: Components.IApxGrid = {
                settings: { pageable: true },
                columns: [
                    { field: 'CrDate', titleTranslated: 'crtime', width: 120, format: Core.Kendo.Format.DateTime},
                    { field: 'Branch.Name', titleTranslated: 'branch' },
                    { field: 'Note', titleTranslated: 'note', width: 70 },
                    { field: 'UserName', titleTranslated: 'user', width: 60 }
                ],
                dataSource: new kendo.data.DataSource({
                    data: [],
                    schema: {
                        model: {
                            id: "Id",
                            fields: {
                                'CrDate': { type: 'date' },
                                'Branch.Name': { type: 'string' },
                                'Note': { type: 'string' },
                                'UserName': { type: 'string' }
                            }
                        }
                    },
                    pageSize: 100,
                    aggregate:
                    [
                        { field: "CrDate", aggregate: "count" }
                    ],
                }),
                gridEvents: [{
                    eType: Components.EventType.Change, eFunction: this.refreshProds
                },
                    {
                        eType: Components.EventType.DataBoud, eFunction: () => {
                            if (this.$scope.components.jobsGrid.selectedObject != undefined && this.$scope.components.jobsGrid.selectedObject != null) {
                                this.$scope.components.prodsGrid.gridView.showLoading();
                                this.warehousingService.getLabelPrintJobProds(this.$scope.components.jobsGrid.selectedObject.Id).then
                                    (res => {
                                        this.$scope.components.prodsGrid.dataSource.data(res.Result);
                                        this.$scope.components.prodsGrid.gridView.hideLoading();
                                    });
                            }
                        }
                    }]
            };
            return grid;
        }
        getProdsGrid() {
            var grid: Components.IApxGrid = {
                columns: [
                    { field: 'Good.BCode', titleTranslated: 'barcode', width: 150},
                    { field: 'Good.InCode', titleTranslated: 'inCode', width: 150 },
                    { field: 'Good.Name', titleTranslated: 'caption', width: 250 },
                    { field: 'Good.Unit', titleTranslated: 'unit', width: 100 },
                    { field: 'Good.Producer.ProducerName', titleTranslated: 'producer', width: 250 },
                    //{ field: 'Quantity', titleTranslated: 'Count' }
                ],
                gridEvents: [{ eType: Components.EventType.Change, eFunction: this.selectedProdChanged }],
                dataSource: new kendo.data.DataSource({
                    data: [],
                    pageSize: 100,
                    aggregate:
                    [
                        { field: "BCode", aggregate: "count" }
                    ],
                    schema: {
                        model: {
                            fields: {
                                'Good.BCode': { type: 'string' },
                                'Good.InCode': { type: 'string' },
                                'Good.Name': { type: 'string' },
                                'Good.Unit': { type: 'string' },
                                'Good.Producer.ProducerName': { type: 'string' }
                            }
                        }
                    }
                }),
                settings: { pageable: true, selectable: 'multiple' }
            };
            return grid;
        }
        selectedProdChanged = () => {
            if (this.$scope.components.prodsGrid.selectedObject == undefined || this.$scope.components.prodsGrid.selectedObject == null) {

                Core.Utils.Helper.safeApply(this.$scope, () => { this.$scope.selectedProdNotExists = true; });
            }
            else {
                Core.Utils.Helper.safeApply(this.$scope, () => { this.$scope.selectedProdNotExists = false; });
            }
        }

        refreshProds = () => {
            if (this.$scope.components.jobsGrid.selectedObject == undefined || this.$scope.components.jobsGrid.selectedObject == null) {
                this.$scope.components.prodsGrid.dataSource.data([]);
                Core.Utils.Helper.safeApply(this.$scope, () => { this.$scope.selectedJobNotExists = true; });
            }
            else {
                Core.Utils.Helper.safeApply(this.$scope, () => { this.$scope.selectedJobNotExists = false; });
                this.$scope.components.prodsGrid.gridView.showLoading();
                this.warehousingService.getLabelPrintJobProds(this.$scope.components.jobsGrid.selectedObject.Id).then
                    (res => {
                        this.$scope.components.prodsGrid.dataSource.data(res.Result);
                        this.$scope.components.prodsGrid.gridView.hideLoading();
                    });
            }
        }
    }

    export class AddLabelPrintJob extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'warehousingService', Globals.modalInstance, Globals.toaster, Globals.http, Globals.translate
        ];
        constructor(private $scope: any, data: any, private warehousingService: Services.Warehousing,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $translate: any) {
            super(arguments, AddLabelPrintJob.$inject);
            $scope.branchesLookup = this.getBranchesLookup(data.branchesList);
            var selectedJob: Interfaces.ILabelPrintJob = $scope.selectedJob = data.selectedJob;
            $scope.job = {};
            if (selectedJob == undefined) {
                $scope.formName = "ფასმაჩვენებლების ჯგუფის დამატება";
                $scope.job.CrDate = new Date();
            }
            else {
                $scope.formName = "ფასმაჩვენებლების ჯგუფის რედაქტირება";
                $scope.job.CrDate = Account.Controllers.BaseBook.toDate(selectedJob.CrDate);
                $scope.job.Note = selectedJob.Note;
                $scope.job.branch = selectedJob.Branch;
                $scope.job.Id = selectedJob.Id;
            }

            $scope.closeForm = this.closeForm;
            $scope.save = this.save;

        }

        getBranchesLookup = (branches: Array<Crm.Interface.ICompanyBranch>) => {
            var lookUp: Components.IApxLookup = {
                dataSource: new kendo.data.DataSource({ data: branches }),
                columns: [
                    { field: 'Id', hidden: true },
                    { field: 'Name', width: 150, titleTranslated: 'nameCaption' },
                    { field: 'Code', titleTranslated: 'code', width: 50 },
                    { field: 'Company.Code', titleTranslated: 'snFull', width: 60 },
                    { field: 'Address.FullAddress', titleTranslated: 'address', width: 100 },
                ],
                valueText: 'Name'
            };
            return lookUp;
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            if (this.$scope.job.branch == undefined) {
                this.toaster.pop('error', this.$translate.instant('Info'), this.$translate.instant('choosebranch'));
                return;
            }
            var jobForSave: Interfaces.ILabelPrintJob = {
                CrDate: this.$scope.job.CrDate,
                Note: this.$scope.job.Note,
                BranchId: this.$scope.job.branch.Id
            };

            if (this.$scope.selectedJob != undefined) {
                jobForSave.Id = this.$scope.selectedJob.Id;
            }

            this.warehousingService.saveLabelPrintJob(jobForSave).then((result) => {
                if (result.success != false)
                    this.$uibModalInstance.close(true);
                else {
                    this.$uibModalInstance.close(false);
                }
            });

        }
    }


    export interface IAddLabelPrintProdScope extends angular.IScope {
        selectedJob: Interfaces.ILabelPrintJob;
        events: {
            addAll: Function;
            selectAll: Function;
            unselectAll: Function;
            disableNextButton: Function;
            closeForm: Function;
            save: Function;
            refresh: Function;
            next: Function;
        };
        model: {
            dataCapacity: any;
            category: any;
            producer: Account.Interfaces.IProducer;
            name: string;
            bCode: string;
        };
        formName: string;
        allCapacities: Array<any>;
        goodList: Array<Account.Interfaces.IGoodSelectable>;
        components: {
            productsGrid: Components.IApxGrid;
            categoriesLookup: Components.IApxLookup;
            producersLookup: Components.IApxLookup;
        };
        nextBtnDisabled: boolean;
        addedProds: Array<Interfaces.ILabelPrintProd>;
        totalPages: number;
        pageNum: number;
    }

    export class AddLabelPrintProd extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'warehousingService', Globals.modalInstance, Globals.toaster, Globals.http, Globals.translate,
        ];
        dictionaryService: Dictionary.Services.Dictionary;
        constructor(private $scope: IAddLabelPrintProdScope, data: any, private warehousingService: Services.Warehousing,
            private $uibModalInstance, private toaster, private $http: angular.IHttpService, private $translate: any) {
            super(arguments, AddLabelPrintProd.$inject);
            this.dictionaryService = Apex.Core.Utils.Helper.getServiceFromJs("dictionaryService");
            $scope.selectedJob = data.selectedJob;
            $scope.events = {
                addAll: this.addAll,
                selectAll: this.selectAll,
                unselectAll: this.unselectAll,
                disableNextButton: () => {
                    $scope.nextBtnDisabled = true
                },
                closeForm: this.closeForm,
                save: this.save,
                next: this.next,
                refresh: this.refresh
            };

            $scope.model = {
                dataCapacity: 100,
                bCode: '',
                category: {},
                name: '',
                producer: {}
            };
            $scope.goodList = [];

            $scope.components = {
                categoriesLookup: this.getCategoriesLookup(),
                productsGrid: this.getProductsGrid(),
                producersLookup: this.getProducersLookup(),
            };

            $scope.formName = "საქონლის დამატება";
            $scope.allCapacities = [
                { id: 100, value: '100' },
                { id: 200, value: '200' },
                { id: 300, value: '300' },
                { id: 400, value: '400' },
                { id: 500, value: '500' },
                { id: 1000, value: '1000' }
            ];

            $scope.nextBtnDisabled = false;
            this.$scope.pageNum = 0;

            warehousingService.getLabelPrintJobProds($scope.selectedJob.Id).then(resp => {
                this.$scope.components.productsGrid.gridView.showLoading();
                $scope.addedProds = resp.Result;
                warehousingService.getAllGoods($scope.model.name, $scope.model.bCode, null, null, null, this.$scope.pageNum, 0)
                    .then(res => {
                        for (var i = 0; i < res.Result.length; i++) {
                            var good: any = res.Result[i];
                            for (var j = 0; j < this.$scope.addedProds.length; j++) {
                                var prod: any = this.$scope.addedProds[j];
                                if (prod.ProdPPId == good.ID) {
                                    good.isAdded = true;
                                    break;
                                }
                            };
                            var selectableGood = <Account.Interfaces.IGoodSelectable>good;
                            selectableGood.isSelected = false;
                            this.$scope.goodList.push(selectableGood);
                        };
                        this.$scope.components.productsGrid.dataSource.read();
                        this.$scope.components.productsGrid.gridView.hideLoading()
                        if (res.TotalPages != undefined)
                            this.$scope.totalPages = res.TotalPages;
                    });
            });
        }

        unselectAll = () => {
            this.$scope.components.productsGrid.gridView.showLoading();
            _.forEach(this.$scope.goodList, (obj: any) => {
                obj.isSelected = false;
            });
            this.$scope.components.productsGrid.dataSource.read();
        };

        addAll = () => {
            this.warehousingService.getProductsNumber(this.$scope.model.name, this.$scope.model.bCode, this.$scope.model.category == null ? null : this.$scope.model.category.CategoryId, this.$scope.model.producer == null ? null : this.$scope.model.producer.ProducerID)
                .then(response => {
                    var alert = Core.Utils.ModalForm.openConfirmation('დაემატება ' + response.Result + ' ჩანაწერი. გსურთ გაგრძელება?');
                    alert.result.then(() => {
                        this.warehousingService.mergeLabelPrints(this.$scope.model.name,
                            this.$scope.model.bCode,
                            this.$scope.model.category == null ? null : this.$scope.model.category.CategoryId,
                            this.$scope.model.producer == null ? null : this.$scope.model.producer.ProducerID,
                            this.$scope.selectedJob.Id, 1).then(resp => {
                                if (resp.success != false) {
                                    this.$uibModalInstance.close(true);
                                }
                            })
                    });
                });
        };

        selectAll = () => {
            this.$scope.components.productsGrid.gridView.showLoading();
            _.forEach(this.$scope.goodList, (obj: any) => {
                obj.isSelected = true;
            });
            this.$scope.components.productsGrid.dataSource.read();
        };

        next = () => {
            if (this.$scope.totalPages > 0 && this.$scope.pageNum < this.$scope.totalPages) {
                this.$scope.components.productsGrid.gridView.showLoading();
                this.$scope.pageNum++;
                this.warehousingService.getAllGoods(this.$scope.model.name, this.$scope.model.bCode, this.$scope.model.category == null ? null : this.$scope.model.category.CategoryId, this.$scope.model.producer == null ? null : this.$scope.model.producer.ProducerID, null, this.$scope.pageNum, this.$scope.model.dataCapacity)
                    .then(res => {
                        _.forEach(res.Result, (good: any) => {
                            _.forEach(this.$scope.addedProds, (prod) => {
                                if (prod.ProdPPId == good.ID) {
                                    good.isAdded = true;
                                }
                            });
                            var selectableGood = <Account.Interfaces.IGoodSelectable>good;
                            selectableGood.isSelected = false;
                            this.$scope.goodList.push(selectableGood);
                        });
                        this.$scope.components.productsGrid.dataSource.read();
                        this.$scope.components.productsGrid.gridView.hideLoading();

                        if (res.TotalPages != undefined)
                            this.$scope.totalPages = res.TotalPages;
                        else
                            res.TotalPages = 0;
                    });
            }
        };

        refresh = () => {
            this.$scope.pageNum = 0;
            //if (this.$scope.totalPages > 0 && this.$scope.pageNum < this.$scope.totalPages) {
            this.$scope.components.productsGrid.gridView.showLoading();
            this.warehousingService.getAllGoods(this.$scope.model.name, this.$scope.model.bCode, this.$scope.model.category == null ? null : this.$scope.model.category.CategoryId, this.$scope.model.producer == null ? null : this.$scope.model.producer.ProducerID, null, this.$scope.pageNum, this.$scope.model.dataCapacity)
                .then(res => {
                    this.$scope.goodList.length = 0;
                    _.forEach(res.Result, (good: any) => {
                        _.forEach(this.$scope.addedProds, (prod) => {
                            if (prod.ProdPPId == good.ID) {
                                good.isAdded = true;
                            }
                        });
                        var selectableGood = <Account.Interfaces.IGoodSelectable>good;
                        selectableGood.isSelected = false;
                        this.$scope.goodList.push(selectableGood);
                    });
                    this.$scope.components.productsGrid.dataSource.read();
                    this.$scope.components.productsGrid.gridView.hideLoading();
                    this.$scope.nextBtnDisabled = false;
                    if (res.TotalPages != undefined) {
                        this.$scope.totalPages = res.TotalPages;
                        //this.$scope.pageNum++;
                    }
                });
            //}
        };

        getProductsGrid = () => {
            var grid: Components.IApxGrid = {
                dataSource: new kendo.data.DataSource({
                    data: this.$scope.goodList,
                    pageSize: 100,
                    aggregate:
                    [
                        { field: "BCode", aggregate: "count" }
                    ],
                    schema: {
                        model: {
                            fields: {
                                isSelected: { type: 'boolean' },
                                BCode: { type: 'string' },
                                Name: { type: 'string' },
                                Unit: { type: 'string' },
                                'Category.CategoryName': { type: 'string' },
                                'Producer.ProducerName': { type: 'string' },
                                PriceGR: { type: 'number' },
                                PriceGT: { type: 'number' },
                                isAdded: { type: 'boolean' }
                            }
                        }
                    }
                }),
                settings: {
                    height: 550,
                    pageable: true
                },
                columns: [
                    {
                        field: 'isSelected', width: 85, filterable: 'boolean', titleTranslated: 'isSelected',
                        template: '<apx-checkbox without-caption ng-model="dataItem.isSelected" style="margin-bottom: 0px;"></apx-checkbox>'
                    },
                    {
                        field: 'BCode', width: 150, titleTranslated: 'barcode',
                        footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.BCode ? data.BCode.count : 0) #',
                        template: '<span style="#= (Spend==true) ? "color: \\#6b6c6d;" : "" #">#: BCode #</span>'
                    },
                    {
                        field: 'Name', titleTranslated: 'nameCaption', width: 300,
                        template: '<span style="#= (Spend==true) ? "color: \\#6b6c6d;" : "" #">#: Name #</span>'
                    },
                    {
                        field: 'Unit', titleTranslated: 'unit', width: 100,
                        template: '<span style="#= (Spend==true) ? "color: \\#6b6c6d;" : "" #">#: Unit #</span>'
                    },
                    {
                        field: 'Category.CategoryName', titleTranslated: 'categoryName', width: 300,
                        template: '<span style="#= (Spend==true) ? "color: \\#6b6c6d;" : "" #">#: Category.CategoryName #</span>'
                    },
                    {
                        field: 'Producer.ProducerName', titleTranslated: 'producer', width: 300,
                        template: '<span style="#= (Spend==true) ? "color: \\#6b6c6d;" : "" #">#: Producer.ProducerName #</span>'
                    },
                    {
                        field: 'PriceGR', width: 100, titleTranslated: 'priceRetail', format: Core.Kendo.Format.Number,
                        template: '<span ng-hide="#=PriceGR==0#" style="#= (Spend==true) ? "color: \\#6b6c6d;" : "" #">#: PriceGR.toFixed(2)  # </span>'
                    },
                    {
                        field: 'PriceGT', width: 100, titleTranslated: 'priceTrade', format: Core.Kendo.Format.Number,
                        template: '<span ng-hide="#=PriceGT==0#" style="#= (Spend==true) ? "color: \\#6b6c6d;" : ""   #">#: PriceGT.toFixed(2)  #</span>'
                    },
                    {
                        field: 'isAdded', width: 85, titleTranslated: 'existsInList', filterable: 'boolean',
                        template: '<div class="text-center" ng-if="dataItem.isAdded"><span class="fa fa-check" style="color:green"></span></div>'
                    }
                ]
            };
            return grid;
        }

        getCategoriesLookup = () => {
            var that = this;
            var lookUp: Components.IApxLookup = {
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: function (options) {
                            var fn = that.dictionaryService.getCategories();
                            fn.then(function (result) {
                                options.success(result.Result);
                            });
                        }
                    },
                    schema: {
                        model: {
                            fields: {
                                CategoryName: {type:'string'}
                            }
                        }
                    }
                }),
                columns: [{ field: 'CategoryName' }],
                valueText: 'CategoryName'
            }
            return lookUp;
        }

        getProducersLookup = () => {
            var that = this;
            var lookUp: Components.IApxLookup = {
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: function (options) {
                            var fn = that.dictionaryService.getManufactures();
                            fn.then(function (result) {
                                options.success(result.Result);
                            });
                        }
                    },
                    schema: {
                        model: {
                            fields: {
                                ProducerName: { type:'string' }
                            }
                        }
                    }
                }),
                columns: [{ field: 'ProducerName' }],
                valueText: 'ProducerName'
            }
            return lookUp;
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            var selectedProducts = _.where(this.$scope.components.productsGrid.dataSource.data(), { isSelected: true });

            if (selectedProducts.length == 0)
                return;

            kendo.ui.progress($('.modal-content'), true);
            var listForSave: Array<Interfaces.ILabelPrintProd> = [];
            for (var i = 0; i < selectedProducts.length; i++) {
                var target = selectedProducts[i];
                if (target.isAdded != true) {
                    var obj: Interfaces.ILabelPrintProd = { ProdPPId: target.ID, Quantity: 1, JobId: this.$scope.selectedJob.Id };
                    listForSave.push(obj);
                }
            }

            if (listForSave.length == 0) {
                this.toaster.pop('error', this.$translate.instant('Attention'), 'ყველა საქონელი დამატებულია');
                kendo.ui.progress($('.modal-content'), false);
                return;
            }

            this.warehousingService.saveLabelPrintProdList(listForSave).then(response => {
                if (response.success != false && response.Result != undefined && response.Result.length > 0) {
                    this.$uibModalInstance.close(true);
                }
                kendo.ui.progress($('.modal-content'), false);
            });
        }
    }
}