/// <reference path="../_reference.ts" />
module Apex.Operations.Services {
    'use strict';
    export class Common extends Core.Service {
        
        getDrivers(): angular.IPromise<any> {
            return this.httpGet("/Common/Dictionary/Drivers");
        }

        getCars(): angular.IPromise<any> {
            return this.httpGet("/Common/Dictionary/Cars");
        }

        getBranchesByPermissions(): angular.IPromise<any> {
            return this.httpGet("/Common/Branches/Filtered");
        }
        getDTAny(mgrid:string):angular.IPromise<any>{
            return this.httpGet("/Common/Dictionary/DTAny/"+mgrid);
        }

        getLoyaltyCard(cardNumber: any): angular.IPromise<any> {
            return this.httpGet('/LoyaltyCards/' + cardNumber);
        }

        getCoupon(couponCode: any): angular.IPromise<any> {
            return this.httpGet("/Coupons/" + couponCode);
        }

        getPriceExcel(branchId: Number): angular.IPromise<any> {
            var url  =this.filterUrl("/Warehousing/Prices/Document/",null);
            if(!!branchId){
                url= url + branchId;
            }
            return this.http.get(url).then((result: any) => {
                
                if (result.status === 401) {
                    var deferred = this.q.defer();
                    this.location.path('/access/login');
                    return deferred.promise;
                }
                
                if (result.status === 200) {
                    var a =<any> angular.element('a');
                    var blob = new Blob([result], {'type': 'application/octet-stream'});
                    a.href = window.URL.createObjectURL(blob);
                    a.download = "result.xlsx";
                    a.click();
                    return deferred.promise;
                }
            });
        }
    }
  
}
