/// <reference path='../_reference.ts' />
module Apex.Operations.Controllers {
    export class BookRealization extends BookOperationsBase {
        $scope: any;
        accountService: Account.Services.Account;

        constructor($scope: any, accountService: Account.Services.Account, dialogs: any, $translate: any, toaster: any) {
            super($scope, accountService, dialogs, $translate, toaster);
            $scope.components.events = {
                refresh: this.refreshDocuments,
                add: this.addDocument,
                edit: this.editDocument,
                delete: this.deleteDocument
            };
        }


        private refreshDocuments = () => {
            this.$scope.components.docsGrid.gridView.showLoading();
            this.$scope.components.docsGrid.gridView.setFilter(this.getBookFilters());
            this.$scope.components.docsGrid.gridView.refresh();
            this.$scope.showRefreshInfo = false;
            this.$scope.selectedDoc = this.$scope.components.docsGrid.selectedObject;
        }

        getBookFilters = () => {
            var filters: Array<Core.Interfaces.IGridFilter> = [
                { filterName: 'OperId', filterOperator: 'IN', filterValue: ["223"] }
            ];

            if (this.checkeNullAndUndefined(this.$scope.filters.acc) && this.checkeNullAndUndefined(this.$scope.filters.acc.crOrDB)) {
                filters.push({ filterName: this.$scope.filters.acc.crOrDB, filterOperator: 'StartsWith', filterValue: this.$scope.filters.acc.value });
            }
            if (this.checkeNullAndUndefined(this.$scope.filters.dateBetween.fromDate) && this.$scope.filters.dateBetween.fromDate.getTime() > 0 &&
                this.checkeNullAndUndefined(this.$scope.filters.dateBetween.toDate) && this.$scope.filters.dateBetween.toDate.getTime() > 0) {
                var from = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.dateBetween.fromDate);
                var to = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.dateBetween.toDate);
                filters.push({
                    filterName: 'Date',
                    filterOperator: 'Between',
                    filterValue: [from, to]
                });
                return filters;
            }
            return [];
        }

        private addDocument = () => {

            var operation: Account.Interfaces.IOperInfo = _.findWhere(this.$scope.allowedOperations, { FFormsID: Account.Interfaces.FForms.GoodRealization });
            if (operation == undefined)
                return;

            Account.Utils.Documents.addDoc({
                WebFormName: "realization.by.price", Name: 'საქონლის რეალიზაცია', OperID: operation.OperID, FFormsID: Account.Interfaces.FForms.GoodRealization
            }, this.refreshDocuments);
        }

        private editDocument = () => { 
            if (this.$scope.selectedDoc != undefined) {
                Account.Utils.Documents.openDoc(this.$scope.selectedDoc.DocID, this.$scope.selectedDoc.blocking,this.refreshDocuments);
            }
        }

        private deleteDocument = () => {
            if (this.$scope.selectedDoc != undefined) {
                Account.Utils.Documents.deleteDoc(this.$scope.selectedDoc.DocID, this.refreshDocuments);
            }
        }
    }
}