/// <reference path="../_reference.ts" />

namespace Apex.Operations {
    export module DTOs {
        
        

        export interface IInventarisationInfo {
            T1Id?: number;
            DDate?: Date;
            InvN1?: number;
            Acc?: string;
            CUserN?: string;
            Blocking?: number;
            Note?: string;
            CUser?: string;
            CrTime?: string;
            AccNu?: string;
            BranchId?: number;
            BranchName?: string;
            Block?: boolean;
        }

        export interface SaveInventarisationInfoResponse {
            ResponseStatus?: Apex.Core.Interfaces.ResponseStatus;

            UpdatedData?: IInventarisationInfo;
        }

        export interface IInventarisationDetails {
            T2Id?: number;
            T1Id?: number;
            InvN2?: string;
            ProdPPId?: string;
            RCount?: number;
            ICount?: number;
            IcountI?: number;
            ICountM?: number;
            CrTime?: Date;
            ProductsNu?: string;
            BCode?: string;
            BBCode?: string;
            InCode?: string;
            CategoryCode?: string;
            ProducerId?: string;
            ProducerNu?: string;
            CategoryNu?: string;
            PackCount?: number;
            PackBCount?: number;
            Unit?: string;
            PriceGR?: number;
            CostG?: number;
            VG?: number;
            RemVG?: number;
            Vat?: number;
            ByWeight?: number;
        }

        export interface IInventarisationRDetails {
            T2Id?: number;
            T1Id?: number;
            InvN2?: string;
            ProdPPId?: string;
            CrTime?: string;
            ProductName?: string;
            BCode?: string;
            BBCode?: string;
            InCode?: string;
            CategoryCode?: string;
            ProducerId?: string;
            ProducerName?: string;
            CategoryName?: string;
            PackCount?: number;
            PackBCount?: number;
            RCount?: number;
            ICount?: number;
            ICountI?: number;
            ICountM?: number;
            RegCount?: number;
            PriceGR?: number;
            CostG?: number;
            VG?: number;
            Vat?: number;
            Unit?: string;
            RegLeft?: number;
            RegLeftVg?: number;
            RemVg?: number;
        }

        export interface IDocView {
            DocID?: string;
            DocType?: number;
            BookType?: number;
            OperID?: string;
            Currency?: ICurrency;
            DBView?: IAccountInfo;
            CRView?: IAccountInfo;
            DDate?: string;
            NumberIn?: string;
            NumberOut?: string;
            RSWaybillNum?: string;
            DocWaybillNum?: string;
            RSWaybillID?: number;
            Vg?: number;
            Contents?: string;
            VgCurrent?: number;
            OpDetId?: string;
            CUser?: string;
            BookNote?: string;
        }

        export interface ICurrency {
            CurrencyID?: string;
            Name?: string;
            NameEn?: string;
            Code?: string;
            CurType?: number;
            ReCalc?: boolean;
            LastDate?: string;
            Rate?: number;
            Spend?: boolean;
            CurrencyCnt?: number;
            Currency_N?: string;
            LDefault?: boolean;
        }

        export interface IAccountInfo {
            Acc?: string;
            Name?: string;
        }

        export interface ISimpleListResult<T> {
            Result: Array<T>;
        }
        export interface ISimpleResult<T> {
            Result: T;
        }

        export interface IOperInfo {
            OperID?: string;
            Name?: string;
            DocsTypeID?: string;
            FFormsID?: number;
            DocType?: number;
            Spend?: boolean;
            LAutoNum?: boolean;
            AutoNum?: number;
            Dets?: IOpDetInfo[];
            WebFormName?: string;
        }

        export interface IOpDetInfo {
            OpDetID?: string;
            OperID?: string;
            Name?: string;
            DBTemplate?: string;
            CRTemplate?: string;
            BookType?: number;
            SubDoc?: number;
            DBTemplateType?: number;
            CRTemplateType?: number;
            ChangeAcc?: boolean;
            PositiveTemplate?: string;
            NegativTemplate?: string;
        }

        export interface IReagInventarisationPair {
            T2Id?: number;
            Reag?: number;
        }

        
        export interface IProductQuery {
            ProdPPId?: string,
            BCode?: string,
            BBCode?: string,
            InCode?: string,
            CategoryCode?: string,
            ProducerId?: string,
            CategoryId?: string,
            ProductsNu?: string,
            ProductsNe?: string,
            DestNumber?: string,
            PPCatId?: string,
            PCatId?: number,
            ProducerNu?: string,
            CategoryNu?: string,
            Vat?: number,
            ByWeight?: Number,
            PackCount?: Number,
            PackBCount?: number,
            Unit?: string,
            MDTrade?: number,
            MDRetail?: number,
            Imported?: boolean,
            PrCountryNu?: string,
            Netto?: number,
            Capacity?: number,
            PriceGR?: number,
            PriceGT?: number,
            PDose?: string,
            PriceUR?: number,
            PriceUT?: number,
            LifeDate?: Number,
            MinCountTrade?: Number,
            MinCountRetail?: Number,
            Spend?: boolean,
            LNotInPrice?: boolean,
            Lown?: boolean,
            Products_id?: string,
            PackCCount?: Number,
            Brutto?: number,
            IsLinked?: boolean,
            ReCalc?: boolean,
            SupDays?: Number,
            VatType?: Number,
            ExciseID?: Number,
            Excise?: number,
            ProdPPPId?: string,
            NumberCodeId?: string,
            PPCatNu?: string,
            Specific?: boolean,
            PackTypeID?: Number,
            ExciseC?: number
        }
    }
}