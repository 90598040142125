/// <reference path='../_reference.ts' />
module Apex.Operations.Controllers {
    export interface IBookOperationsBaseScope extends angular.IScope {
        allowedOperations: Array<Account.Interfaces.IOperInfo>;
        showRefreshInfo: boolean;
        components: {
            docsGrid: Components.IApxGrid;
        };
        filters: {
            dateBetween: any;
        };
        selectedDoc: any;
        deleteMode: boolean;
    }
    export class BookOperationsBase extends Core.Controller {
        public static $inject = [
            Globals.scope, 'accountService', 'dialogs', Globals.translate, Globals.toaster
        ];

        $scope: any;
        accountService: Account.Services.Account;

        constructor($scope: IBookOperationsBaseScope, accountService: Account.Services.Account, private dialogs: any, private $translate: any, private toaster: any) {
            super(arguments, BookOperationsBase.$inject);
            this.$scope = $scope;
            this.accountService = accountService;
            $scope.selectedDoc = {};
            $scope.showRefreshInfo = true;
            $scope.deleteMode = false;
            $scope.components = {
                docsGrid: this.initDocGridOptions()
            };

            $scope.filters = {
                dateBetween: {}
            };

            $scope.$watch('filters', () => {
                $scope.showRefreshInfo = true;
            }, true);

            accountService.getOpers().then(res => {
                $scope.allowedOperations = res.Result;
            });

        }

        checkeNullAndUndefined(val) {
            return typeof val !== "undefined" && val !== null;
        }

        initDocGridOptions() {
            var model = {
                id: "Id",
                fields: {
                    blocking: { type: 'boolean' },
                    recstatus: { type: 'number' },
                    Date: { type: "date" },
                    OpDetId: { type: "string" },
                    DB: { type: "string" },
                    CR: { type: "string" },
                    CurrencyId: { type: "string" },
                    Amount: { type: "number" },
                    numberin: { type: "string" },
                    numberout: { type: "string" },
                    code: { type: "string" },
                    cuser: { type: "string" },
                    crtime: { type: "date" }
                }
            };
            var docGridOptions: Components.IApxGrid = {
                columns: this.columns,
                templates: this.templates,
                settings: {
                    autoBind: false,
                    pageable: true
                },
                gridEvents: [
                    {
                        eType: Components.EventType.Change, eFunction: () => {
                            Core.Utils.Helper.safeApply(this.$scope, () => {
                                this.$scope.selectedDoc = docGridOptions.gridView.selectedItem();

                                if (!!this.$scope.selectedDoc && this.$scope.selectedDoc.blocking == true)
                                    this.$scope.deleteMode = true;
                                else
                                    this.$scope.deleteMode = false;

                            });
                        }
                    }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.accountService.booksQueryUrl, model,"&jsconfig=dh:iso8601")
            };
            // docGridOptions.dataSource.sort({ field: "crtime", dir: "desc" });
            docGridOptions.dataSource.options.schema.model.id = "Id";
            return docGridOptions;
        }

        columns: Array<Core.Interfaces.IGridColumn> = [
            {
                field: 'blocking', titleTranslated: 'Blocking', width: 40, objectType: 'boolean', filterable: 'custom',
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: this.$translate.instant('ALL') },
                            { value: 1, text: '+' },
                            { value: 0, text: '-' }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value",

                    })
                }
            },
            {
                field: 'recstatus', titleTranslated: 'Project', width: 40, objectType: 'boolean', filterable: 'custom',
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: this.$translate.instant('ALL') },
                            { value: 1, text: '+' },
                            { value: 0, text: '-' }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value",

                    })
                },
            },
            { field: 'Date', titleTranslated: 'DATE', width: 90, filterable: false },
            { field: 'OpDetId', titleTranslated: 'OpDetId', width: 75, isId: true },
            { field: 'DB', titleTranslated: 'DB' },
            { field: 'CR', titleTranslated: 'CR' },
            { field: 'CurrencyId', titleTranslated: 'Currency', width: 60, filterOperator: 'eq' },
            { field: 'Amount', titleTranslated: 'amount', width: 90, filterOperator: 'eq' },
            { field: 'numberin', titleTranslated: 'numberin', filterOperator: 'eq', width: 80 },
            { field: 'numberout', titleTranslated: 'numberout', filterOperator: 'eq', width: 80 },
            { field: 'code', titleTranslated: 'waybillNumShort', filterOperator: 'eq', width: 100 },
            { field: 'cuser', titleTranslated: 'cuser', filterOperator: 'contains', width: 70 },
            { field: 'crtime', titleTranslated: 'crtime', width: 105, filterable: false }
        ];

        templates: Array<Core.Interfaces.IGridColumnTemplate> =
        [
            { fieldName: 'blocking', template: '<div class="text-center" ng-if="dataItem.blocking"><img src="./img/lock.png" width="16"></div>' },
            { fieldName: 'recstatus', template: '<div class="text-center" ng-if="dataItem.recstatus"><img src="./img/fx.png" width="16"></div>' },
            { fieldName: 'Date', template: '<span>{{::dataItem.Date | dateFormat | date:"dd/MM/yyyy"}}</span>' },
            { fieldName: 'Amount', template: '<div class="apx-grid-cell-number">{{::dataItem.Amount| number:2}}</div>' },
            { fieldName: 'crtime', template: '<span>{{::dataItem.crtime | dateFormat | date:"dd/MM/yy HH:mm"}}</span>' }
        ];

    }
}