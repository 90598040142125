/// <reference path="../_reference.ts" />
module Apex.Operations.Interfaces {
    export interface IPreOrder {

    }

    export interface IPreorderType {
        Id: number;
        Value:string;
    }

    export interface IPreorderStatus {
        Id: number;
        Value: string;
    }
}
