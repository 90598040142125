/// <reference path="../../_reference.ts" />
module Apex.Operations.Controllers {
    export interface IOrderRetailScope extends IBasePreOrderScope {
        model: IOrderRetailModel;
    }

    export interface IOrderRetailModel extends IOrderBaseModel {
        VatTypeBool: boolean;
    }

    export class OrderRetail extends BaseCrmOrderModal {
        $scope: IOrderRetailScope;
        constructor($scope: IOrderRetailScope, $rootScope: any, data: any, crmService: Crm.Services.Crm, commonService: Services.Common, distributionService: Services.Distribution, accountingService: Account.Services.Account, $uibModalInstance, toaster, $http: angular.IHttpService) {
            super($scope, $rootScope, data, crmService, commonService, distributionService, accountingService, $uibModalInstance, toaster, $http);
            this.$scope = $scope;
            $scope.OrderType=1;
            crmService.getCompanyBranchesByStatus(Crm.Services.CompanyBranchStatus.Own).then((data) => {
                $scope.components.senderLookup.dataSource.data(data.Result);
                return data;
            });
          
            if (this.$scope.Order != undefined) {
                if ($scope.model.vatType == 1)
                    $scope.model.VatTypeBool = true;
                else
                    $scope.model.VatTypeBool = false;

                $scope.model.RecipientName = $scope.Order.Recipient.Name;
                $scope.model.RecipientSn = $scope.Order.Recipient.Code; 
               

            }
            else $scope.model.VatTypeBool = false;


            $scope.saveOrder = this.saveOrder;


            $scope.$watch('model.supplier', (supplierBranch: any) => {
                if (!this.$scope.Order && supplierBranch != undefined) {
                    if (this.$scope.model.RecipientName != undefined && this.$scope.model.RecipientSn != undefined && this.$scope.model.RecipientName.toString().trim().length > 0 && this.$scope.model.RecipientSn.toString().trim().length > 0) {
                        $('#addProduct').removeClass("k-state-disabled");
                    }
                    else {
                        $('#addProduct').addClass("k-state-disabled");
                    }

                    if (supplierBranch.Address != undefined && supplierBranch.Address.FullAddress != undefined) {
                        $scope.model.deliveryInfo.addressFrom = supplierBranch.Address.FullAddress;
                    }
                    else $scope.model.deliveryInfo.addressFrom = '';
                }
            });

            $scope.$watch('[model.RecipientName,model.RecipientSn]', ([name, sn]: Array<any>) => {
                if (!this.$scope.Order) {
                    if (name != undefined && sn != undefined && this.$scope.model.supplier != undefined && name.toString().trim().length > 0 && sn.toString().trim().length > 0) {
                        $('#addProduct').removeClass("k-state-disabled");
                    }
                    else $('#addProduct').addClass("k-state-disabled");

                }
            });

            $scope.$watch('model.VatTypeBool', (vattypebool: boolean) => {
                if (vattypebool != undefined) {
                    if (vattypebool == true) {
                        this.$scope.model.vatType = 1;
                    }
                    else {
                        this.$scope.model.vatType = 0;
                    }
                }
            });

        }

        saveOrder = () => {
            var order = this.convertOrderForSave(Utils.PreorderType.OrderRetail);
            if (order != null) {
                order.Recipient = {
                    Name: this.$scope.model.RecipientName, Code: this.$scope.model.RecipientSn
                };

                if (this.$scope.model.VatTypeBool == true)
                    order.VatType = 1;
                else
                    order.VatType = 0;
                

                this.saveCrmOrderOrder(order);
            }
        };


        getPartial(): string {
            return 'modals\order.retail.html';
        }
    }

}