/// <reference path='../_reference.ts' />
module Apex.Operations.Controllers {
    export interface IInventoryScope extends angular.IScope {
        components: {
            inventoryInfoGridOptions?: Components.IApxGrid,
            inventoryDetailsGridOptions?: Components.IApxGrid,
            inventoryDocsGridOptions?: Components.IApxGrid,
            inventoryRGridOptions?: Components.IApxGrid,
            tabControl: {
                selectedTabPageIndex: Number,
                secondPageLoaded: Boolean,
                tabEvents: {
                    tabChange: Function
                }
            }
        },
        filters: {
            dateBetween: {
                fromDate?: Date,
                toDate?: Date
            },
            branchLookupOptions?: Components.IApxLookup
        },
        events: {
            RefreshData: Function,
            Inv1TChanged: any,
            reag: {
                reag: Function,
                unReag: Function,
                showColumns: Function
            },
            genInvList: Function,
            invInfo: {
                add: Function,
                edit: Function,
                delete: Function,
                changeStatus: Function
            },
            details: {
                add: Function,
                edit: Function,
                delete: Function,
                rowChanged: any,
                reCalcRCount: Function
            }
        },
        model: {
            branch: Crm.Interface.ICompanyBranch,
            oldInfo: DTOs.IInventarisationInfo,
            currentInfoStatus: {
                b: Boolean,
                s: InvStatus
            },
            reag: {
                reagEnabled: Boolean,
                unReagEnabled: Boolean,
                buttonsVisible: Boolean,
                countCheck: Boolean,
                valsCheck: Boolean
            },
            details: {
                buttonsVisible: Boolean,
                buttonsDisabled: boolean,
                rowNotEditable: boolean
            },
            invInfo: {
                rowNotEditable: boolean
            }
        },
        cm: {
            invInfo: {
                open: Function,
                select: Function
                editDisabled: boolean,
                deleteDisabled: boolean
            },

            invDetails: {
                open: Function,
                select: Function
            }
        }
        progress: number;
    }

    export class Inventory extends Core.Controller {
        public static $inject = [Globals.scope, 'crmService', 'warehousingService', 'accountService', 'dialogs', Globals.translate, Globals.toaster, Globals.q];

        constructor(private $scope: IInventoryScope, private crmService: Crm.Services.Crm, private warehousingService: Services.Warehousing, private accountService: Apex.Account.Services.Account, private dialogs: any, private $translate: any, private toaster: any, private $q: angular.IQService) {
            super(arguments, Inventory.$inject);
            this.setupScope($scope);
        }
        detailsLoaded = false;
        rDetailsLoaded = false;
        setupScope = (scope: IInventoryScope) => {
            scope.progress = 0;
            scope.events = {
                RefreshData: this.RefreshData,
                Inv1TChanged: this.Inv1TChanged,
                reag: {
                    reag: this.reagInv,
                    unReag: this.unReagInv,
                    showColumns: this.showReagColumns
                },
                genInvList: this.genInvList,
                invInfo: {
                    add: this.addInfo,
                    edit: this.editInfo,
                    delete: this.deleteInfo,
                    changeStatus: this.changeInfoStatus
                },
                details: {
                    add: this.addDetails,
                    edit: this.editDetails,
                    delete: this.deleteDetails,
                    rowChanged: this.Inv2TChanged,
                    reCalcRCount: this.reCalcRCount
                }
            };
            scope.components = {
                inventoryInfoGridOptions: new inventoryInfoGridOptions(scope, this.$translate),
                inventoryDetailsGridOptions: new inventoryDetailsGridOptions(scope),
                inventoryDocsGridOptions: new inventoryDocsGridOptions(),
                inventoryRGridOptions: new inventoryRGridOptions(),
                tabControl: {
                    selectedTabPageIndex: 0,
                    secondPageLoaded: false,
                    tabEvents: {
                        tabChange: (index) => {
                            scope.components.tabControl.selectedTabPageIndex = index;
                            scope.model.reag.buttonsVisible = index == 1;
                            scope.model.details.buttonsVisible = index == 0;
                            if (index == 1 && !scope.components.tabControl.secondPageLoaded) {
                                scope.components.tabControl.secondPageLoaded = true;
                                scope.components.inventoryRGridOptions.grid.refresh();
                            }
                        }
                    }
                }
            };
            scope.filters = {
                dateBetween: {},
                branchLookupOptions: new branchLookupOptions(scope, this.crmService)
            };
            scope.model = {
                branch: null,
                oldInfo: null,
                invInfo: {
                    rowNotEditable: true
                },
                currentInfoStatus: {
                    b: false,
                    s: InvStatus.none
                },
                reag: {
                    reagEnabled: false,
                    unReagEnabled: false,
                    buttonsVisible: false,
                    countCheck: true,
                    valsCheck: false
                },
                details: {
                    buttonsVisible: true,
                    buttonsDisabled: true,
                    rowNotEditable: true
                }
            }
            scope.cm = {
                invInfo: {
                    open: () => { },
                    select: (e) => { },
                    editDisabled: !scope.components.inventoryInfoGridOptions.selectedObject,
                    deleteDisabled: !scope.components.inventoryInfoGridOptions.selectedObject
                },
                invDetails: {
                    open: () => { },
                    select: (e) => { }
                }
            }
        }
        reCalcRCount = () => {
            var t1 = this.$scope.components.inventoryInfoGridOptions.selectedObject as DTOs.IInventarisationInfo;
            if (!t1.Block && (t1.Blocking == 0 || t1.Blocking == 5)) {
                Core.Utils.ModalForm.openConfirmation("confirmReCalcRCount", true).result.then((r) => {
                    this.warehousingService.RecalcRCount(t1.T1Id).then(x => {
                        this.RefreshData();
                    });
                });
            }
        }

        showReagColumns = () => {
            if (this.$scope.model.reag.countCheck) {
                this.$scope.components.inventoryRGridOptions.grid.showColumn("RCount");
                this.$scope.components.inventoryRGridOptions.grid.showColumn("ICount");
                this.$scope.components.inventoryRGridOptions.grid.showColumn("GetDiffCount");
            } else {
                this.$scope.components.inventoryRGridOptions.grid.hideColumn("RCount");
                this.$scope.components.inventoryRGridOptions.grid.hideColumn("ICount");
                this.$scope.components.inventoryRGridOptions.grid.hideColumn("GetDiffCount");
            }
            if (this.$scope.model.reag.valsCheck) {
                this.$scope.components.inventoryRGridOptions.grid.showColumn("RemVg");
                this.$scope.components.inventoryRGridOptions.grid.showColumn("VG");
                this.$scope.components.inventoryRGridOptions.grid.showColumn("GetVGDiffCount");
            } else {
                this.$scope.components.inventoryRGridOptions.grid.hideColumn("RemVg");
                this.$scope.components.inventoryRGridOptions.grid.hideColumn("VG");
                this.$scope.components.inventoryRGridOptions.grid.hideColumn("GetVGDiffCount");
            }
        }

        changeInfoStatus = (status: number) => {
            if (status != null && typeof status != undefined) {
                var row = this.$scope.components.inventoryInfoGridOptions.selectedObject as DTOs.IInventarisationInfo;
                if (row) {
                    Core.Utils.Helper.safeApply(this.$scope, () => {
                        if (status == InvStatus.blocked)
                            row.Block = !row.Block;
                        else {
                            row.Blocking = status;
                        }
                        this.warehousingService.SaveInventoryInfo(row).then(x => {
                            this.RefreshData();
                        });
                    });
                }
            }
        }

        addDetails = () => {
            var item = <DTOs.IInventarisationInfo>this.$scope.components.inventoryInfoGridOptions.selectedObject;
            if (!item) return;
            var data = {
                edit: false,
                invDetails: { T1Id: item.T1Id } as DTOs.IInventarisationDetails
            } as IEditInvDetailsModalData;
            var dlg = Core.Utils.ModalForm.openModalForm("edit.invdetails.modal", EditInvdetailsModal.getName(), data, Core.Utils.ModalSize.Small);
            dlg.result.then(result => {
                if (result && result.status == 1)
                    this.RefreshTabs(this.$scope.components.inventoryInfoGridOptions.selectedObject);
            });
        }

        editDetails = () => {
            var item = <DTOs.IInventarisationDetails>this.$scope.components.inventoryDetailsGridOptions.selectedObject;
            if (!item) return;
            var data = {
                edit: true,
                invDetails: item
            } as IEditInvDetailsModalData;
            var dlg = Core.Utils.ModalForm.openModalForm("edit.invdetails.modal", EditInvdetailsModal.getName(), data, Core.Utils.ModalSize.Small);
            dlg.result.then(result => {
                if (result && result.status == 1)
                    this.RefreshTabs(this.$scope.components.inventoryInfoGridOptions.selectedObject);
            });
        }

        deleteDetails = () => {
            var item = <DTOs.IInventarisationDetails>this.$scope.components.inventoryDetailsGridOptions.selectedObject;
            if (!item) return;
            Core.Utils.ModalForm.openConfirmation("confirmDelete", true).result.then((r) => {
                this.warehousingService.DeleteInvDetails(item.T2Id).then(res => {
                    this.RefreshTabs(this.$scope.components.inventoryInfoGridOptions.selectedObject);
                });
            });
        }

        addInfo = () => {
            var data = {
                edit: false,
                invInfo: null
            } as EditInventoryModalData;
            var dlg = Core.Utils.ModalForm.openModalForm("edit.inventory.modal", EditInventoryModal.getName(), data, Core.Utils.ModalSize.Small);
            dlg.result.then(result => {
                if (result && result.status == 1)
                    this.RefreshData(result.T1Id);
            });
        }

        editInfo = () => {
            var item = <DTOs.IInventarisationInfo>this.$scope.components.inventoryInfoGridOptions.selectedObject;
            if (!item) return;
            var data = <EditInventoryModalData>{
                edit: true,
                invInfo: item
            }
            var dlg = Core.Utils.ModalForm.openModalForm("edit.inventory.modal", EditInventoryModal.getName(), data, Core.Utils.ModalSize.Small);
            dlg.result.then(result => {
                if (result && result.status == 1)
                    this.$scope.events.RefreshData(result.T1Id);
            });
        }

        deleteInfo = () => {
            var item = <DTOs.IInventarisationInfo>this.$scope.components.inventoryInfoGridOptions.selectedObject;
            if (!item) return;
            Core.Utils.ModalForm.openConfirmation("confirmDelete", true).result.then((r) => {
                this.warehousingService.DeleteInventoryInfo(item.T1Id).then(res => {
                    this.RefreshData();
                });
            });
        }

        genInvList = () => {
            if (!this.$scope.components.inventoryInfoGridOptions.selectedObject) return;
            var data = {
                t1Id: (<DTOs.IInventarisationInfo>this.$scope.components.inventoryInfoGridOptions.selectedObject).T1Id
            }
            var dlg = Core.Utils.ModalForm.openModalForm("gen.invlist.modal", "genInvListModalController", data, Core.Utils.ModalSize.Medium);
            dlg.result.then(result => {
                if (result && result.status == 1)
                    this.RefreshData(data.t1Id);
            });
        }

        reagInv = () => {
            if (!this.$scope.components.inventoryInfoGridOptions.selectedObject) return;
            var data = {
                t1Id: (<DTOs.IInventarisationInfo>this.$scope.components.inventoryInfoGridOptions.selectedObject).T1Id
            }
            var dlg = Core.Utils.ModalForm.openModalForm("book.inventory.modal", BookInventoryModal.getName(), data, Core.Utils.ModalSize.Small);
            dlg.result.then(result => {
                if (result && result.status == 1)
                    this.RefreshData(data.t1Id);
            });
        }
        unReagInv = () => {
            var t1 = (<DTOs.IInventarisationInfo>this.$scope.components.inventoryInfoGridOptions.selectedObject);
            if (!t1 || (t1.Blocking != 1 && t1.Blocking != 4)) {
                Core.Utils.ModalForm.openError("ინვენტარიზაცია ვერ მოიძებნა!");
                return;
            }
            Core.Utils.ModalForm.openConfirmation("გსურთ გაუქმება?").result.then((res) => {
                if (res) {
                    this.startProgress();
                    this.warehousingService.unBookInventory(t1.T1Id).then(res => {
                        this.stopProgress();
                        Core.Utils.Helper.safeApply(this.$scope, () => {
                            this.RefreshData(t1.T1Id);
                        });
                    });
                }
            });
        }

        Inv2TChanged = (e: kendo.ui.GridEvent): void => {
            var selected = this.$scope.components.inventoryDetailsGridOptions.selectedObject as DTOs.IInventarisationDetails;
            var t1 = this.$scope.components.inventoryInfoGridOptions.selectedObject as DTOs.IInventarisationInfo;
            Core.Utils.Helper.safeApply(this.$scope, () => {
                this.$scope.model.details.rowNotEditable = !selected || t1.Block || (t1.Blocking != InvStatus.normal && t1.Blocking != InvStatus.doneInv);
            });
        }

        Inv1TChanged = (e: kendo.ui.GridEvent | boolean) => {
            var force = false;
            if (typeof e === "boolean") {
                force = true;
            }
            var selected = this.$scope.components.inventoryInfoGridOptions.selectedObject as DTOs.IInventarisationInfo;
            if (selected) {
                this.$scope.model.currentInfoStatus = {
                    b: selected.Block,
                    s: selected.Blocking
                }
            } else {
                this.$scope.model.currentInfoStatus = {
                    b: false,
                    s: InvStatus.none
                };
            }
            var it = (sed) => {
                this.$scope.model.oldInfo = sed;
                this.RefreshTabs(selected);
            }
            if (!selected) {
                selected = null;
                Core.Utils.Helper.safeApply(this.$scope, () => {
                    this.$scope.model.invInfo.rowNotEditable = true;
                    this.$scope.model.details.buttonsDisabled = true;
                });
                it(selected);
            } else if (selected && this.$scope.model.oldInfo) {
                if (force || selected.T1Id != this.$scope.model.oldInfo.T1Id)
                    it(selected);
                Core.Utils.Helper.safeApply(this.$scope, () => {
                    this.$scope.model.invInfo.rowNotEditable = false;
                    this.$scope.model.details.buttonsDisabled = (selected.Block || (selected.Blocking != 0 && selected.Blocking != 5));
                });
            }
            else {
                it(selected);
                Core.Utils.Helper.safeApply(this.$scope, () => {
                    this.$scope.model.invInfo.rowNotEditable = false;
                    this.$scope.model.details.buttonsDisabled = (selected.Block || (selected.Blocking != 0 && selected.Blocking != 5));
                });
            }
        }

        RefreshTabs = (it: DTOs.IInventarisationInfo) => {
            if (!!it) {
                this.stopTabsProgress();
                this.startTabsProgress();

                var a = this.warehousingService.GetInventoryDocs(it.T1Id).then(res => {
                    this.$scope.components.inventoryDocsGridOptions.dataSource.data(this.setResOrE(res));
                    this.$scope.progress++;
                    if (this.$scope.progress == 3) {
                        this.stopTabsProgress();
                        this.$scope.progress = 0;
                    }
                });
                this.warehousingService.GetInventoryDetails(it.T1Id).then(res => {
                    this.$scope.components.inventoryDetailsGridOptions.dataSource.data(this.setResOrE(res));
                    this.$scope.progress++;
                    if (this.$scope.progress == 3) {
                        this.stopTabsProgress();
                        this.$scope.progress = 0;
                    }
                });
                this.warehousingService.GetInventoryRDetails(it.T1Id).then(res => {
                    this.$scope.components.inventoryRGridOptions.dataSource.data(this.setResOrE(res));
                    this.$scope.progress++;
                    if (this.$scope.progress == 3) {
                        this.stopTabsProgress();
                        this.$scope.progress = 0;
                    }
                });
                this.$scope.model.reag.reagEnabled = !it.Block && it.Blocking === 0 || it.Blocking === 5;
                this.$scope.model.reag.unReagEnabled = !it.Block && it.Blocking === 1 || it.Blocking === 4;
            } else {
                this.$scope.components.inventoryDocsGridOptions.dataSource.data([]);
                this.$scope.components.inventoryDetailsGridOptions.dataSource.data([]);
                this.$scope.components.inventoryRGridOptions.dataSource.data([]);
                delete this.$scope.components.inventoryDocsGridOptions.selectedObject;
                delete this.$scope.components.inventoryDetailsGridOptions.selectedObject;
                delete this.$scope.components.inventoryRGridOptions.selectedObject;
                this.$scope.model.reag.reagEnabled = false;
                this.$scope.model.reag.unReagEnabled = false;
            }
        }
        setResOrE = (res) => { return (res && res.Result) ? res.Result : []; }
        RefreshData = (t1Id: Number = null) => {
            this.$scope.model.oldInfo = this.$scope.components.inventoryInfoGridOptions.selectedObject;
            this.warehousingService.GetInventoryInfo(null, this.$scope.model.branch ? this.$scope.model.branch.Id : null, Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.dateBetween.fromDate), Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.filters.dateBetween.toDate)).then(res => {

                this.$scope.components.inventoryInfoGridOptions.dataSource.data(this.setResOrE(res));
                var selected = this.$scope.components.inventoryInfoGridOptions.selectedObject;
                if (!selected) {
                    this.$scope.model.oldInfo = null;
                }
                // var selected = this.$scope.components.inventoryInfoGridOptions.selectedObject;
                // if (selected) {
                //     this.$scope.model.oldInfo = selected;// = null;
                //     this.RefreshTabs(selected);
                // } else {
                //     this.$scope.model.oldInfo = null;
                // }
                // this.$scope.components.inventoryDetailsGridOptions.dataSource.data([]);
                // this.$scope.components.inventoryRGridOptions.dataSource.data([]);
                // this.$scope.components.inventoryDocsGridOptions.dataSource.data([]);
                this.Inv1TChanged(true);
            });
        }

        startProgress = () => {
            kendo.ui.progress($('#content'), true);
        }

        stopProgress = () => {
            kendo.ui.progress($('#content'), false);
        }

        startTabsProgress = () => {
            this.$scope.components.inventoryDocsGridOptions.gridView.showLoading();
            this.$scope.components.inventoryDetailsGridOptions.gridView.showLoading();
            this.$scope.components.inventoryRGridOptions.gridView.showLoading();
        }

        stopTabsProgress = () => {
            this.$scope.components.inventoryDocsGridOptions.gridView.hideLoading();
            this.$scope.components.inventoryDetailsGridOptions.gridView.hideLoading();
            this.$scope.components.inventoryRGridOptions.gridView.hideLoading();
        }
    }

    class inventoryInfoGridOptions implements Components.IApxGrid {
        constructor(private scope: IInventoryScope, private translate) {

        }
        settings = <Core.Kendo.IGridSettings>{
            // filterDisabled: true, 
            sortable: Core.Kendo.SortType.single,
            editble: false,
        };
        getBlockingTemplate = (val) => {
            var tpl = "";
            switch (val.value) {
                case 0: {
                    tpl = '<span title="' + this.translate.instant('ALL') + '" class="fa fa-flag text-success" ></span>';
                    break;
                }
                case 1: {
                    tpl = '<span title="' + this.translate.instant('ALL') + '" class="fa fa-flag text-info"></span>';
                    break;
                }
                case 2: {
                    tpl = '<span title="' + this.translate.instant('ALL') + '" class="fa fa-flag text-warning" ></span>';
                    break;
                }
                case 3: {
                    tpl = '<span title="' + this.translate.instant('ALL') + '" class="fa fa-lock text-warning" ></span>';
                    break;
                }
                case 4: {
                    tpl = '<span title="' + this.translate.instant('ALL') + '" class="fa fa-flag-o text-success" ></span>';
                    break;
                }
                case 5: {
                    tpl = '<span title="' + this.translate.instant('ALL') + '" class="fa fa-circle text-warning" ></span>';
                    break;
                }
                default: {
                    tpl = " ";
                    break;
                }
            }
            return tpl;
        }
        getBlockTemplate = (val) => {
            var tpl = "";

            switch (val.value) {
                case false: {
                    tpl = '<span title="' + this.translate.instant('Unlocked') + '"></span>';
                    break;
                }
                case true: {
                    tpl = '<span title="' + this.translate.instant('Locked') + '" class="fa fa-lock text-warning"></span>';
                    break;
                }
                default: {
                    tpl = " ";
                    break;
                }
            }
            return tpl;
        }

        columns = [
            {
                field: 'Block', titleTranslated: 'Block', width: 90,
                filterable: "custom",
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: ' ' },
                            { value: false, text: "მოხსნილი" },
                            { value: true, text: 'ბლოკირებული' }
                            // { value: null, text: '<div style="width:60px;"></div>' },
                            // { value: false, text: "{{'notLocked'|translate}}" },
                            // { value: true, text: '<span class="fa fa-lock text-warning text-center"></span>' }
                        ],
                        valueTemplate: this.getBlockTemplate,
                        template: this.getBlockTemplate,
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value"
                    })
                },
            },
            {
                field: 'Blocking', titleTranslated: 'status', width: 75,
                filterable: "custom",
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList(<kendo.ui.DropDownListOptions>{
                        dataSource: [
                            { value: null, text: '   ' },
                            { value: 0, text: 'გახსნილი', },
                            { value: 1, text: 'დასრულებული' },
                            { value: 2, text: 'გაუქმებული' },
                            { value: 3, text: 'ბლოკირებული' },
                            { value: 4, text: 'დასრულებული' },
                            { value: 5, text: 'აღწერილი' }
                        ],
                        template: this.getBlockingTemplate,
                        valueTemplate: this.getBlockingTemplate,
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value"
                    })
                },
            },
            { field: 'InvN1', titleTranslated: 'n', width: 65, footerTemplate: '#= kendo.toString(data.T1Id && data.T1Id.count ? data.T1Id.count : 0)  #' },
            { field: 'DDate', titleTranslated: 'DATE', width: 110, format: Core.Kendo.Format.Date, },
            { field: 'Acc', titleTranslated: 'acc', width: 80 },
            { field: 'CUserN', titleTranslated: 'InvUsers', width: 200 },
            { field: 'CUser', titleTranslated: 'user', width: 80 },
            { field: 'BranchName', titleTranslated: 'branch', width: 150, },
        ];
        templates =
        [<Core.Interfaces.IGridColumnTemplate>{
            fieldName: "Block",
            template: '<div class="text-center" ng-if="dataItem.Block"><span class="fa fa-lock text-warning text-center"></span></div>',
        }, <Core.Interfaces.IGridColumnTemplate>{
            fieldName: "Blocking",
            template: `<div class="text-center" ng-switch="dataItem.Blocking">
                        <span ng-switch-when="0" class="fa fa-flag text-success"></span>
                        <span ng-switch-when="1" class="fa fa-flag text-info"></span>
                        <span ng-switch-when="2" class="fa fa-flag text-warning" ></span> 
                        <span ng-switch-when="3" class="fa fa-lock text-warning" ></span>
                        <span ng-switch-when="4" class="fa fa-flag-o text-success" ></span>
                        <span ng-switch-when="5" class="fa fa-circle text-warning" ></span>
                        <span ng-switch-default></span></div>
                        `

        }];
        dataSource = new kendo.data.DataSource({
            data: [],
            aggregate: [
                { field: "T1Id", aggregate: "count" }
            ],
            schema: {
                model: {
                    id: "T1Id",
                    fields: {
                        InvN1: { type: 'number', },
                        Block: { type: 'boolean', },
                        Blocking: { type: 'number', },
                        DDate: { type: 'date', },
                        Acc: { type: 'string' },
                        CUserN: { type: 'string' },
                        CUser: { type: 'string' },
                        BranchName: { type: 'string' }
                    }
                }
            },

        });

        gridEvents: Array<Components.IEvent> = [<Components.IEvent>{
            eType: Components.EventType.Change, eFunction: this.scope.events.Inv1TChanged
        }];
    }

    class inventoryDetailsGridOptions implements Components.IApxGrid {
        constructor(private scope: IInventoryScope) {

        }
        settings = <Core.Kendo.IGridSettings>{
            editble: false, pageable: true, sortable: Core.Kendo.SortType.single
        };
        columns = [
            { field: 'InCode', titleTranslated: 'inCode', width: 100, },
            { field: 'BCode', titleTranslated: 'barcode', width: 120, },
            { field: 'ProductsNu', titleTranslated: 'nameCaption', width: 80 },
            { field: 'RCount', titleTranslated: 'RCount', width: 100, format: Core.Kendo.Format.Price4, },
            { field: 'ICountM', titleTranslated: 'ICountM', width: 100, format: Core.Kendo.Format.Price4, },
            { field: 'IcountI', titleTranslated: 'ICountI', width: 100, format: Core.Kendo.Format.Price4, },
            { field: 'ICount', titleTranslated: 'ICount', width: 100, format: Core.Kendo.Format.Price4, },
            { field: 'InvN2', titleTranslated: 'n', width: 50 },
            { field: 'VG', titleTranslated: 'vg', width: 50, format: Core.Kendo.Format.Price4, },
            { field: 'PriceGR', titleTranslated: 'priceGR', width: 100, format: Core.Kendo.Format.Price4, },
            { field: 'CostG', titleTranslated: 'costL', width: 100, format: Core.Kendo.Format.Price4, },
            // { field: 'CrTime', titleTranslated: 'Note', width: 80, format: Core.Kendo.Format.Percent },
            // { field: 'BBCode', titleTranslated: 'AccountName', width: 80, format: Core.Kendo.Format.Price4 },
            // { field: 'CategoryCode', titleTranslated: 'CategoryCode', width: 50, format: Core.Kendo.Format.Price4, },
            // { field: 'ProducerNu', titleTranslated: 'ProducerNu', width: 50, format: Core.Kendo.Format.Price4, },
            // { field: 'CategoryNu', titleTranslated: 'CategoryNu', width: 50, format: Core.Kendo.Format.Price4, },
            // { field: 'PackCount', titleTranslated: 'PackCount', width: 50, format: Core.Kendo.Format.Price4, },
            // { field: 'PackBCount', titleTranslated: 'PackBCount', width: 50, format: Core.Kendo.Format.Price4, },
            // { field: 'Unit', titleTranslated: 'Unit', width: 50, format: Core.Kendo.Format.Price4, },
            // { field: 'RemVG', titleTranslated: 'RemVG', width: 50, format: Core.Kendo.Format.Price4, },
            // { field: 'Vat', titleTranslated: 'Vat', width: 50, format: Core.Kendo.Format.Price4, },
            // { field: 'ByWeight', titleTranslated: 'ByWeight', width: 50, format: Core.Kendo.Format.Price4, }

        ];
        dataSource = new kendo.data.DataSource({
            data: [],
            aggregate: [
                { field: "BCode", aggregate: "count" }
            ],
            pageSize: 50,
            schema: {
                model: {
                    id: "T2Id",
                    fields: {
                        InCode: { type: 'string' },
                        BCode: { type: 'string' },
                        ProductsNu: { type: 'string' },
                        RCount: { type: 'number' },
                        ICountM: { type: 'number' },
                        IcountI: { type: 'number' },
                        ICount: { type: 'number' },
                        InvN2: { type: 'string' },
                        VG: { type: 'number' },
                        PriceGR: { type: 'number' },
                        CostG: { type: 'number' },
                    }
                }
            },
        });

        gridEvents: Array<Components.IEvent> = [<Components.IEvent>{
            eType: Components.EventType.Change, eFunction: this.scope.events.details.rowChanged
        }];
    }

    class inventoryDocsGridOptions implements Components.IApxGrid {
        settings = <Core.Kendo.IGridSettings>{ filterDisabled: true, editble: false, sortable: Core.Kendo.SortType.single };
        columns = [
            { field: 'OperID', titleTranslated: 'operation', width: 50, },
            { field: 'DDate', titleTranslated: 'DATE', width: 80, format: Core.Kendo.Format.Date, },
            { field: 'NumberIn', titleTranslated: 'numberin', width: 110 },
            { field: 'NumberOut', titleTranslated: 'numberout', width: 110 },
        ];
        dataSource = new kendo.data.DataSource({
            data: []
        });
    }

    class inventoryRGridOptions implements Components.IApxGrid {
        settings = <Core.Kendo.IGridSettings>{
            editble: false, pageable: true, sortable: Core.Kendo.SortType.single
        };
        columns = [
            { field: 'BCode', titleTranslated: 'barcode', width: 110 },
            { field: 'ProductName', titleTranslated: 'nameCaption', width: 110 },
            { field: 'Unit', titleTranslated: 'unit', width: 110 },
            { field: 'RCount', titleTranslated: 'RCount', width: 110, format: Core.Kendo.Format.Price4, },
            { field: 'ICount', titleTranslated: 'ICount', width: 110, format: Core.Kendo.Format.Price4, },
            { field: 'GetDiffCount()', titleTranslated: 'DiffCount', width: 110, format: Core.Kendo.Format.Price4, },
            { field: 'RemVg', hidden: true, titleTranslated: 'RemVG', width: 110, format: Core.Kendo.Format.Price4, },
            { field: 'VG', hidden: true, titleTranslated: 'vg', width: 110, format: Core.Kendo.Format.Price4, },
            { field: 'GetVGDiffCount()', hidden: true, titleTranslated: 'VGDiffCount', width: 110, format: Core.Kendo.Format.Price4, },
            { field: 'RegLeft', titleTranslated: 'RegLeft', width: 110, format: Core.Kendo.Format.Price4, },
            { field: 'RegCount', titleTranslated: 'RegCount', width: 110, format: Core.Kendo.Format.Price4, }
        ];
        dataSource = new kendo.data.DataSource({
            data: [],
            aggregate: [
                { field: "BCode", aggregate: "count" }
            ],
            pageSize: 50,
            schema: {
                model: {
                    id: "T3Id",
                    GetDiffCount: function () {
                        return this.ICount - this.RCount;
                    },
                    GetVGDiffCount: function () {
                        return this.VG - this.RemVg;
                    },
                    fields: {
                        BCode: { type: 'string' },
                        ProductName: { type: 'string' },
                        Unit: { type: 'string' },
                        RCount: { type: 'number' },
                        ICount: { type: 'number' },
                        'GetDiffCount()': { type: 'number' },
                        RemVg: { type: 'number' },
                        VG: { type: 'number' },
                        'GetVGDiffCount()': { type: 'number' },
                        RegLeft: { type: 'number' },
                        RegCount: { type: 'number' },
                    }
                },

            }
        });
    }

    class branchLookupOptions implements Components.IApxLookup {
        constructor(private scope: IInventoryScope, private crmService: Crm.Services.Crm) {

        }
        dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    this.crmService.getBranchesExp(Crm.Services.CompanyBranchStatus.Own, false, true, false).then(function (result) {
                        result.Result.forEach(x => {
                            if (x.BranchAccounts) {
                                var accs = _.filter(x.BranchAccounts, (y => { return y.AccType == Crm.Services.AccountType.Warehouse; }));
                                if (accs && accs.length > 0) {
                                    (<any>x).wHAcc = accs[0].Account;
                                }
                            }
                        });
                        var res = result.Result.filter(x => (<any>x).wHAcc);
                        options.success(res);
                    });
                }
            }
        });
        columns = [
            { field: 'Id', hidden: true },
            { field: 'Name', width: 150, titleTranslated: 'nameCaption' },
            { field: 'wHAcc', width: 100, titleTranslated: 'acc' },
            { field: 'Code', titleTranslated: 'code', width: 50 },
            { field: 'Company.Code', titleTranslated: 'snFull', width: 60 },
            { field: 'Address.FullAddress', titleTranslated: 'address', width: 100 }
        ];
        valueText = 'Name';
    }

    export enum InvStatus {
        normal = 0,
        finished = 1,
        canceled = 2,
        blocked = 3,
        finishedEmpty = 4,
        doneInv = 5,
        none = -1
    }
}