/// <reference path='../../_reference.ts' />
module Apex.Operations.Controllers {
    export interface IEditInventoryModalScope extends angular.IScope {
        model: {
            invInfo: DTOs.IInventarisationInfo,
            edit: boolean,
            branch: IBranchExt,
            partialEdit: boolean
        },
        events?: {
            save: Function,
            cancel: Function
        },
        components: {
            branchLookupOptions: Components.IApxLookup
        }
    }

    export class EditInventoryModal extends Core.Controller {
        "use strict";
        public static $inject = [Globals.scope, 'crmService', 'warehousingService', 'accountService', 'dialogs', Globals.translate, Globals.toaster, 'data', Globals.modalInstance];

        constructor(private $scope: IEditInventoryModalScope, private crmService: Crm.Services.Crm, private warehousingService: Services.Warehousing, private accountService: Apex.Account.Services.Account, private dialogs: any, private $translate: any, private toaster: any, private data: EditInventoryModalData, private $uibModalInstance) {
            super(arguments, EditInventoryModal.$inject);
            this.setupScope($scope, crmService, data);
            this.loadData();
        }

        setupScope = (scope: IEditInventoryModalScope, crmService: Crm.Services.Crm, data: EditInventoryModalData) => {
            if (data.edit) {
                data.invInfo.DDate = new Date(parseInt((<string><any>data.invInfo.DDate).substr(6)));
                scope.model = {
                    invInfo: data.invInfo,
                    edit: true,
                    branch: null,
                    partialEdit: true
                }
            } else {
                scope.model = {
                    invInfo: <DTOs.IInventarisationInfo>{
                        DDate: new Date(),
                    },
                    edit: false,
                    branch: null,
                    partialEdit: false
                }
            }
            scope.events = {
                save: this.save,
                cancel: () => {
                    this.$uibModalInstance.close({ status: 0, message: "cancel" });
                }
            }
            scope.components = {
                branchLookupOptions: new branchLookupOptions(scope, crmService)
            }
        }

        save = () => {
            if (!this.$scope.model.branch) {
                this.toaster.pop("error", "შეცდომა", "აირჩიეთ ფილიალი!");
                return;
            }
            if (this.$scope.model.edit) {
                this.$scope.model.invInfo.Acc = this.$scope.model.branch.wHAcc;
                this.$scope.model.invInfo.BranchId = this.$scope.model.branch.Id;
            } else {
                this.$scope.model.invInfo.Acc = this.$scope.model.branch.wHAcc;
                this.$scope.model.invInfo.BranchId = this.$scope.model.branch.Id;
            }

            this.startProgress();
            this.warehousingService.SaveInventoryInfo(this.$scope.model.invInfo)
                .then(res => {
                    this.stopProgress();
                    this.$uibModalInstance.close({ status: 1, message: "saveSuccessfull" });
                });
        }

        loadData = (res2: DTOs.ISimpleListResult<DTOs.IInventarisationInfo> = null) => {
            if (this.$scope.model.edit) {
                this.warehousingService.GetInventoryInfo(this.$scope.model.invInfo.T1Id, null, null, null).then(r => {
                    if (r && r.Result && r.Result.length > 0) {
                        this.$scope.model.invInfo = r.Result[0];
                        this.$scope.model.invInfo.DDate = new Date(parseInt((<string><any>this.$scope.model.invInfo.DDate).substr(6)));
                        if (this.$scope.components.branchLookupOptions.dataSource.data) {
                            this.$scope.components.branchLookupOptions.dataSource.data().forEach(x => {
                                var y = x as IBranchExt;
                                if (y.Id == this.$scope.model.invInfo.BranchId) {
                                    this.$scope.model.branch = y;
                                }
                            });
                        }

                    } else {
                        this.$uibModalInstance.close({ status: 2, message: "InvNotFound" });
                    }
                });
                this.warehousingService.GetInventoryDetails(this.$scope.model.invInfo.T1Id).then(data => {
                    if (data && data.Result && data.Result.length > 0) {
                        this.$scope.model.partialEdit = true;
                    } else {
                        this.$scope.model.partialEdit = false;
                    }
                })
            } else {
                this.setInventoryInfoN();
            }
        }

        setInventoryInfoN() {
            this.warehousingService.GetInventoryInfoN().then((res) => {
                if (res && res.Result)
                    Core.Utils.Helper.safeApply(this.$scope, () => {
                        this.$scope.model.invInfo.InvN1 = res.Result;
                    });
                else
                    this.$uibModalInstance.close({ status: 2, message: "InvNNotFound" });
            });
        }


        startProgress = () => {
            kendo.ui.progress($('.modal-body'), true);
        }
        stopProgress = () => {
            kendo.ui.progress($('.modal-body'), false);
        }
    }

    class branchLookupOptions implements Components.IApxLookup {
        constructor(private scope: IEditInventoryModalScope, private crmService: Crm.Services.Crm) {

        }

        dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    this.crmService.getBranchesExp(Crm.Services.CompanyBranchStatus.Own, false, true, false).then((result) => {
                        result.Result.forEach(x => {
                            if (x.BranchAccounts) {
                                var accs = _.filter(x.BranchAccounts, (y => { return y.AccType == Crm.Services.AccountType.Warehouse; }));
                                if (accs && accs.length > 0) {
                                    (<any>x).wHAcc = accs[0].Account;
                                }
                            }
                        });
                        var res = result.Result.filter(x => (<any>x).wHAcc);
                        // if(!this.scope.model.branch && this.scope.model.invInfo){

                        var item = _.filter(res, x => {
                            return this.scope.model.invInfo.BranchId == x.Id;
                        });
                        this.scope.model.branch = item[0];
                        // }
                        options.success(res);
                    });
                }
            }
        });

        columns = [
            { field: 'Id', hidden: true },
            { field: 'Name', width: 150, titleTranslated: 'nameCaption' },
            { field: 'wHAcc', width: 100, titleTranslated: 'acc' },
            { field: 'Code', titleTranslated: 'code', width: 50 },
            { field: 'Company.Code', titleTranslated: 'snFull', width: 60 },
            { field: 'Address.FullAddress', titleTranslated: 'address', width: 100 }
        ];
        valueText = 'Name';
    }

    export interface EditInventoryModalData {
        edit: boolean;
        invInfo: DTOs.IInventarisationInfo;
    }
    export interface IBranchExt extends Crm.Interface.ICompanyBranch {
        wHAcc?: string
    }
}