/// <reference path="../_reference.ts" />
module Apex.Operations.Services {
    'use strict';
    export class Distribution extends Core.Service {
        getMobileusers(): angular.IPromise<any> {
            return this.httpGet("/Distribution/MobileUsers");
        }

        getPresalers(): angular.IPromise<any> {
            return this.httpGet('/Distribution/Presalers/View');
        }

        getMobileUsersByPresaler(presalerId: number): angular.IPromise<any>
        {
            return this.httpGet('/Distribution/MobileUsers?PresalerId=' + presalerId);
        }

        getMobileUserCustomersView(mobileUserId: number): angular.IPromise<any> {
            return this.httpGet('/Distribution/MobileUsers/' + mobileUserId + '/Customers/View');
        }

    }
}
