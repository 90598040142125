/// <reference path='../../_reference.ts' />
module Apex.Operations.Controllers {
    export interface IEditInvdetailsModal extends angular.IScope {
        model: {
            invDetails: DTOs.IInventarisationDetails,
            edit: boolean,
            good: DTOs.IProductQuery
        },
        events?: {
            save: Function,
            cancel: Function
        },
        components: {
            goodLookupOptions: Components.IApxLookup,
            tBICountOptions:Apex.Components.IApxNumber,
            tBICountIOptions:Apex.Components.IApxNumber
        }
    }

    export class EditInvdetailsModal extends Core.Controller {
        "use strict";
        public static $inject = [Globals.scope, 'crmService', 'warehousingService', 'accountService', 'dialogs', Globals.translate, Globals.toaster, 'data', Globals.modalInstance];

        constructor(private $scope: IEditInvdetailsModal, private crmService: Crm.Services.Crm, private warehousingService: Services.Warehousing, private accountService: Apex.Account.Services.Account, private dialogs: any, private $translate: any, private toaster: any, private data: IEditInvDetailsModalData, private $uibModalInstance) {
            super(arguments, EditInvdetailsModal.$inject);
            this.setupScope($scope, warehousingService, data);
            this.loadData();
        }

        setupScope = (scope: IEditInvdetailsModal, warehousingService: Apex.Operations.Services.Warehousing, data: IEditInvDetailsModalData) => {
            if (data.edit) {
                scope.model = {
                    invDetails: data.invDetails,
                    edit: true,
                    good: { ProdPPId: data.invDetails.ProdPPId, ProductsNu: data.invDetails.ProductsNu } as DTOs.IProductQuery
                }
            } else {
                scope.model = {
                    invDetails: {
                        T1Id: data.invDetails.T1Id
                    } as DTOs.IInventarisationDetails,
                    edit: false,
                    good: null
                }
            }
            scope.events = {
                save: this.save,
                cancel: () => {
                    this.$uibModalInstance.close({ status: 0, message: "cancel" });
                }
            }
            scope.components = {
                goodLookupOptions: new goodLookupOptions(scope, warehousingService),
                tBICountOptions:
                {
                    allowMinus: false,
                    decimals: 4,
                    min: 0,
                    max:9999999,
                    numberType: Components.NumberType.Decimal
                },
                tBICountIOptions:
                {
                    allowMinus: false,
                    decimals: 4,
                    min: 0,
                    max:9999999,
                    numberType: Components.NumberType.Decimal
                }
            }
        }

        save = () => {
            if (!this.$scope.model.good) {
                this.toaster.pop("error", "შეცდომა", "აირჩიეთ პროდუქტი!");
                return;
            }
            this.$scope.model.invDetails.ProdPPId = this.$scope.model.good.ProdPPId;
            // if (this.$scope.model.edit) {

            // } else {
            //     // this.$scope.model.invInfo.Acc = this.$scope.model.branch.wHAcc;
            //     // this.$scope.model.invInfo.BranchId = this.$scope.model.branch.Id;
            // }

            this.startProgress();
            this.warehousingService.SaveInvDetails(this.$scope.model.invDetails)
                .then(res => {
                    this.stopProgress();
                    this.$uibModalInstance.close({ status: 1, message: "successSaved" });
                });
        }

        loadData = (res2: DTOs.ISimpleListResult<DTOs.IInventarisationInfo> = null) => {
            if (this.$scope.model.edit) {
                this.warehousingService.GetInventoryDetail(this.$scope.model.invDetails.T2Id).then(r => {
                    if (r && r.Result && r.Result.length > 0) {
                        this.$scope.model.invDetails = r.Result[0];
                        this.$scope.model.invDetails.CrTime = new Date(parseInt((<string><any>this.$scope.model.invDetails.CrTime).substr(6)));

                    } else {
                        this.$uibModalInstance.close({ status: 2, message: "InvNotFound" });
                    }
                });
            } else {
                this.setInventoryInfoN();
            }
        }

        setInventoryInfoN() {
            this.warehousingService.GetInvDetailsN().then((res) => {
                if (res && res.Result)
                    this.$scope.model.invDetails.InvN2 = res.Result;
                else
                    this.$uibModalInstance.close({ status: 2, message: "InvNNotFound" });
            });
        }


        startProgress = () => {
            kendo.ui.progress($('.modal-body'), true);
        }
        stopProgress = () => {
            kendo.ui.progress($('.modal-body'), false);
        }
    }

    class goodLookupOptions implements Components.IApxLookup {
        dataSource: kendo.data.DataSource = null;
        constructor(private scope: IEditInvdetailsModal, private warehousingService: Apex.Operations.Services.Warehousing) {
            this.dataSource = Core.Kendo.GridView.initAutoQueryDataSource(this.warehousingService.queryProdView);
            this.dataSource.options.schema.model.id = "ProdPPId";
        }



        // dataSource = new kendo.data.DataSource({
        //     transport: {
        //         read: (options) => {
        //             this.warehousingService.getGoods().then((result) => {
        //                 result.Result.forEach(x => {

        //                     if (this.scope.model.edit && !this.scope.model.good && this.scope.model.invDetails) {

        //                         var item = _.filter(result.Result, x => {
        //                             return this.scope.model.invDetails.ProdPPId == x.ID;
        //                         });
        //                         this.scope.model.good = item[0];
        //                     }
        //                     options.success(result.Result);
        //                 });
        //             });
        //         }
        //     }
        // });

        columns = [
            { field: 'ProdPPId', hidden: true },
            { field: 'BCode', width: 150, titleTranslated: 'barcode' },
            { field: 'ProductsNu', width: 150, titleTranslated: 'nameCaption' }
        ];
        valueText = 'ProductsNu';
    }

    export interface IEditInvDetailsModalData {
        edit: boolean;
        invDetails: DTOs.IInventarisationDetails;
    }
}