/// <reference path="../../_reference.ts" />
module Apex.Operations.Controllers {
    export interface IOrderBaseComponents {
        deliveryInfoComponents?: IOrderWholesaleDeliveryInfoComponents;
        productsGrid: Components.IApxGrid;
        servicesGrid?: Components.IApxGrid;
        contractsLookUp?: Components.IApxLookup;
        couponsGrid?: Components.IApxGrid;
        recipientLookup: Components.IApxLookup;
        senderLookup: Components.IApxLookup;
    }
    export interface IOrderBaseModel {
        supplier?: any;
        recipient?: any;
        paymentDate?: Date;
        date?: Date,
        contract?: any;
        vatType?: number;
        loyaltyCard?: any;
        RecipientSn?: string;
        RecipientName?: string;
        deliveryInfo?: {
            deliveryData: Array<any>,
            deliveryType?: any,
            preparedListType?: any,
            deliveryDate?: Date,
            addressFrom?: string,
            addressTo?: string;
            transporterPersonalNumber?: any;
            receiverPersonalNumber?: any;
            transporterNameLastname?: any;
            receiverNameLastname?: any;
            waybillType?: any;
            waybillTransportType?: any;
            carNumber?: any;
            carMark?: any;
            driverName?: string;
            driverPersonalNumber?: string;
            forwarder?: any;
            note?: string;
        }
    }

    export interface IBasePreOrderScope extends angular.IScope {
        contractDets:any;        
        getCrmOrderTabsContent: Function;
        eventOccured: boolean;
        supplierInfo: string;
        recipientInfo: string;
        preorderType: string;
        gridsAreDirty: boolean;
        modalIsOpened?: boolean;
        FixedValuesForCrmOrderFunctionality: Array<any>;
        closeOrder: Function;
        saveOrder: Function;
        LabelsModel: { recipientStats: any; text: string, contractDets?: any; };
        makeFormDirty: Function;
        manageRCount: { val: boolean };
        manageRcountSwitchValueChanged: Function;
        deliveryInfoSelectedTabIndex: number;
        deliveryTypes: Array<any>;
        waybillTypes: Array<any>;
        waybillTransportTypes: Array<any>;
        deliveryInfoSelectedTabPageChanged: Function;
        events: {
            cancelCouponUi?: Function, 
            searchCoupon?: Function, 
            searchCouponKeyEvent?: Function, 
            SearchLoyaltyCard?: Function, 
            removeLoyaltyCard?: Function,
            addProducts:Function,
            deleteProduct:Function
        };
        components: IOrderBaseComponents;
        model: IOrderBaseModel;
        loyaltyCardAdded: boolean;
        loyaltyCardOwnerText: string;
        productModel: {
            productsForDelete: any[],
            modalMarkedProducts: any,
            addedProducts: any,
            productsList: any[],
            productsGrid: Components.IApxGrid,
            productsFilterArrayForRcount: Array<any>,
            productsFilterForRcount: any
        };
        serviceModel: {
            servicesForDelete: any[],
            addedService: any,
            services: Array<any>,
            servicesLookupData: Array<any>,
            recalculation: Function,
            servicesLookup: Components.IApxLookup
        };
        couponModel: {
            coupons: Array<any>,
            couponsForDelete: any[],
            couponForAdd: {
                code?: any,
                expirationDateForViewBinding?: string,
                coupon?: any
            };
        }
        Visibility: {
            servicesTabInvisible: boolean;
            contractsInvisible: boolean;
            loyaltyCardInvisible: boolean;
            couponsTabInvisible: boolean;
        };
        formName: string;
        Order: any;
        OrderType?:number;
        closeHandle: Function;
        isDirty: boolean;
        isBusy: boolean;
        getPartial: Function;
        getTest: Function;
        disableDeleteBtn:boolean;
    }

    export class BaseCrmOrderModal extends Core.Controller {

        public static $inject = [
            Globals.scope, Globals.rootScope, 'data', 'crmService', 'commonService', 'distributionService', 'accountService', Globals.modalInstance, Globals.toaster, Globals.http
        ];
        $scope: IBasePreOrderScope;
        $rootScope: any;
        crmService: Crm.Services.Crm;
        commonService: Services.Common;
        toaster: any;
        translate: any;
        constructor($scope: IBasePreOrderScope, $rootScope: any,
            private data: any,
            crmService: Crm.Services.Crm,
            commonService: Services.Common,
            private distributionService: Services.Distribution,
            private accountingService: Account.Services.Account,
            private $uibModalInstance: any,
            toaster: any,
            private $http: angular.IHttpService) {

            super(arguments, BaseCrmOrderModal.$inject);
            this.$scope = $scope;
            this.$rootScope = $rootScope;
            this.toaster = toaster;
            this.crmService = crmService;
            this.commonService = commonService;
            this.translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            $scope.formName = data.formName;
            $scope.Order = data.order;
            $scope.FixedValuesForCrmOrderFunctionality = data.fixedVals;
            $scope.preorderType = data.PreorderType;
            $scope.LabelsModel = { recipientStats: data.recipientStats, text: '' };
            $scope.contractDets = data.contractDets;
            this.initialization($scope);

            angular.element(document).ready( ()=> {
                this.ngOnInit();
            });
           
        }

        initialization(scope:IBasePreOrderScope){
            scope.disableDeleteBtn=true;
            scope.events = {
                addProducts:this.addProducts,
                deleteProduct:this.deleteProducts
            };
            scope.productModel = {
                productsFilterForRcount: 0,
                productsFilterArrayForRcount: this.getProductsFilterArrayForRcount(),
                productsForDelete: [],
                addedProducts: {},
                productsList: [],
                productsGrid: this.getProductsGridConfigForModal(),
                modalMarkedProducts: {}
            }
            scope.model = {
                deliveryInfo: { addressFrom: '', addressTo: '', deliveryData: [] },
                date: new Date(),
                paymentDate: new Date()
            };

            scope.deliveryInfoSelectedTabPageChanged = (tabIndex) => {
                this.$scope.deliveryInfoSelectedTabIndex = tabIndex;
                if (this.$scope.Order != undefined && _.values(this.$scope.Order.DeliveryInfo).length > 1) {
                    this.$scope.model.deliveryInfo.deliveryDate = this.todate(_.values(this.$scope.Order.DeliveryInfo)[tabIndex].DeliveryDate);
                }
            };
            scope.components =
                {
                    recipientLookup: {
                        columns: PreOrders.branchcolumns,
                        valueText: 'Name',
                        dataSource: new kendo.data.DataSource({
                            data: [],
                            schema: {
                                model: {
                                    fields: {
                                        'Id': { type: 'number' },
                                        'Name': { type: 'string' },
                                        'Code': { type: 'string' },
                                        'Company.Code': { type: 'string' },
                                        'Address.FullAddress': { type: 'string' }
                                    }
                                }
                            }
                        })
                    },
                    senderLookup: {
                        columns: PreOrders.branchcolumns,
                        valueText: 'Name',
                        dataSource: new kendo.data.DataSource({
                            data: [],
                            schema: {
                                model: {
                                    fields: {
                                        'Id': { type: 'number' },
                                        'Name': { type: 'string' },
                                        'Code': { type: 'string' },
                                        'Company.Code': { type: 'string' },
                                        'Address.FullAddress': { type: 'string' }
                                    }
                                }
                            }
                        })
                    },
                    deliveryInfoComponents: {
                        crmOrderPrepareListLookup: {
                            columns: this.getPreparedListColumns(), valueText: "Number", dataSource: new kendo.data.DataSource({
                                data: [],
                                schema: {
                                    model: {
                                        fields: {
                                            'Id': { type: 'number' },
                                            'Number': { type: 'string' }
                                        }
                                    }
                                }
                            })
                        },
                        mobileUsersLookup: {
                            columns: this.getMobileUserColumns(), valueText: "NameU", dataSource: new kendo.data.DataSource({
                                data: [],
                                schema: {
                                    model: {
                                        fields: {
                                            'Id': { type: 'number' },
                                            'NameU': { type: 'string' },
                                            'UserName': { type: 'string' }
                                        }
                                    }
                                }

                            })
                        }
                    },
                    productsGrid: this.getProductsGridConfig()

                };

        }

        ngOnInit = () => {
            
            
            this.$scope.closeOrder = this.closeOrder;
            this.$scope.getPartial = this.getPartial;
            this.$scope.isDirty = false;
            this.$scope.isBusy = false;
            this.$scope.makeFormDirty = this.makeFormDirty;
            this.$scope.closeHandle = this.closeHandle;
            $(document).keydown(this.closeHandle);
            this.$scope.manageRCount = { val: false };
            this.$scope.Visibility = { servicesTabInvisible: false, contractsInvisible: false, loyaltyCardInvisible: false, couponsTabInvisible: false };
            if (this.$scope.preorderType == 'OrderRetail')
                this.$scope.Visibility.contractsInvisible = true;

            if (this.$scope.FixedValuesForCrmOrderFunctionality != undefined)
                this.changeValuesByFixVals(this.$scope.preorderType);
            this.$scope.getCrmOrderTabsContent = this.getTabsContent;

            // setTimeout(()=>{
            if (!this.$scope.Visibility.loyaltyCardInvisible) {
                this.$scope.events.removeLoyaltyCard = () => {
                    var alert = Core.Utils.ModalForm.openConfirmation('ნამდვილად გსურთ ბარათის ჩახსნა?');
                    alert.result.then(() => {
                        delete this.$scope.model.loyaltyCard;
                        this.$scope.loyaltyCardAdded = false;
                        this.$scope.loyaltyCardOwnerText = '';
                        this.makeFormDirty();
                    });
                };

                this.$scope.events.SearchLoyaltyCard = this.SearchLoyaltyCard;

                // setTimeout(() => {
                    $('#loyaltyCardInput').keydown((e) => {
                        if (e.keyCode == 13) {
                            this.SearchLoyaltyCard();
                        }
                    })
                // }, 300);

                this.$scope.loyaltyCardAdded = this.$scope.eventOccured = false;
            }

            if (!this.$scope.Visibility.couponsTabInvisible) {
                this.$scope.events.searchCoupon = this.searchCoupon;
                this.$scope.events.searchCouponKeyEvent = this.searchCouponKeyEvent;
                this.$scope.events.cancelCouponUi = this.cancelCouponUi;
            }
            

            if (this.$scope.preorderType != 'OrderTranzit') {
                this.crmService.getCrmOrderPrepareList().then((data) => {
                    this.$scope.components.deliveryInfoComponents.crmOrderPrepareListLookup.dataSource.data(data.Result);
                    return data;
                });
            }

            if (!this.$scope.Visibility.servicesTabInvisible) {
                this.$scope.serviceModel = {
                    servicesForDelete: [],
                    servicesLookup: this.getServicesLookupConfig(),
                    recalculation: this.recalculation,
                    addedService: {},
                    services: [],
                    servicesLookupData: []
                };

                this.$scope.components.servicesGrid = this.getServicesGridConfig();

                this.accountingService.getServices().then((data) => {
                    this.$scope.serviceModel.servicesLookupData = data.Result;
                    this.$scope.serviceModel.servicesLookup.dataSource = data.Result.toDatasource();
                    return data;
                });
            }

            if (!this.$scope.Visibility.couponsTabInvisible) {
                this.$scope.components.couponsGrid = this.initCouponsGrid();
                this.$scope.couponModel =
                    {
                        coupons: [],
                        couponForAdd: {},
                        couponsForDelete: []
                    };
            }
            if (!this.$scope.Visibility.contractsInvisible) {
                this.$scope.components.contractsLookUp = {
                    dataSource: new kendo.data.DataSource({ data: [] }),
                    columns: [
                        { field: 'Cntnum', titleTranslated: 'n' },
                        { field: 'Cntdate', titleTranslated: 'DATE', format: Core.Kendo.Format.Date },
                        { field: 'Comment', titleTranslated: 'comment' },
                        { field: 'CntObject', titleTranslated: 'object' }
                    ], valueText: "Cntnum"
                };

            }
            this.$scope.deliveryTypes = this.getDeliveryTypes();
            this.$scope.waybillTransportTypes = this.getWaybillTransportTypes();
            this.$scope.waybillTypes = this.getWaybillTypes();


            if (this.$scope.Order != undefined) {
                this.$scope.model.recipient = this.$scope.Order.Recipient;
                this.$scope.model.vatType = this.$scope.Order.VatType;
                this.$scope.model.supplier = this.$scope.Order.Supplier;
                this.$scope.model.date = this.todate(this.$scope.Order.OrderDate);
                this.$scope.model.paymentDate = this.todate(this.$scope.Order.PayDate);

                if (!this.$scope.Visibility.loyaltyCardInvisible && this.$scope.Order.LoyaltyCard != undefined) {
                    this.$scope.model.loyaltyCard = this.$scope.Order.LoyaltyCard;
                    if (this.$scope.model.loyaltyCard.Owner != undefined) {
                        this.$scope.loyaltyCardOwnerText = this.$scope.model.loyaltyCard.Owner.FullName + '(' + this.$scope.model.loyaltyCard.Owner.Sn.trim() + '); ';
                    }
                    this.$scope.loyaltyCardAdded = true;
                }

                if (!this.$scope.Visibility.couponsTabInvisible && this.$scope.Order.Coupons != undefined && this.$scope.Order.Coupons.length > 0) {
                    _.forEach(this.$scope.Order.Coupons, (coupon: any) => {
                        this.$scope.couponModel.coupons.push({
                            Id: coupon.Id,
                            Coupon: coupon.Coupon,
                            Code: coupon.Coupon.Code,
                            ExpirationDate: coupon.Coupon.ExpirationDate
                        });
                    });
                    this.$scope.components.couponsGrid.dataSource.data(this.$scope.couponModel.coupons);
                }

                if (this.$scope.Order.Products != undefined) {

                    _.forEach(this.$scope.Order.Products, (item: any) => {
                        var prod =
                            {
                                Prod: { ID: item.Prod.ID },
                                Price: item.Price,
                                OCount: item.OCount,
                                Id: item.Id,
                                bcode: item.Prod.BCode,
                                prodpp_id: item.Prod.ID,
                                unit: item.Prod.Unit,
                                Discount: item.Discount,
                                packcount: item.Prod.PackCount,
                                count: item.OCount,
                                price: item.Price,
                                MinPrice: item.MinPrice,
                                Pricegr: item.Price,
                                products_nu: item.Prod.Name,
                                category_nu: item.Prod.Category.CategoryName,
                                producer_nu: item.Prod.Producer.ProducerName
                            };
                        this.$scope.productModel.addedProducts['"' + item.Prod.ID + '"'] = prod;
                    });
                }

                if (this.$scope.Order.Services != undefined) {
                    _.forEach(this.$scope.Order.Services, (item: any) => {
                        var service =
                            {
                                Service: { ServiceID: item.Service.ServiceID },
                                SCount: item.SCount,
                                Price: item.Price,
                                Name: item.Service.Name,
                                Discount: item.Discount,
                                Code: item.Service.Code,
                                PriceG: item.Price,
                                ID: item.Id,
                                Vg: item.Price * item.SCount,
                                Vat: item.Service.Vat
                            }
                        this.$scope.serviceModel.services.push(service);
                    });
                }

                if (!this.$scope.Visibility.contractsInvisible) {
                    this.$scope.model.contract = this.$scope.Order.Contract;
                    this.$scope.LabelsModel.contractDets = this.$scope.contractDets;
                    this.initLabelValues();
                }
                else {
                    this.initLabelValues();
                }

                this.$scope.model.deliveryInfo.deliveryData = _.values(this.$scope.Order.DeliveryInfo);
                if (this.$scope.model.deliveryInfo.deliveryData.length == 1) {
                    var deliveryInfo = _.first(this.$scope.model.deliveryInfo.deliveryData);                  
                    this.$scope.model.deliveryInfo.addressFrom = deliveryInfo.FromAddress;
                    this.$scope.model.deliveryInfo.addressTo = deliveryInfo.ToAddress;
                    this.$scope.model.deliveryInfo.carMark = deliveryInfo.TransportDescription;
                    this.$scope.model.deliveryInfo.carNumber = deliveryInfo.TransportNumber;
                    this.$scope.model.deliveryInfo.deliveryDate = this.todate(deliveryInfo.DeliveryDate);
                    this.$scope.model.deliveryInfo.driverName = deliveryInfo.DriverName;
                    this.$scope.model.deliveryInfo.driverPersonalNumber = deliveryInfo.DriverSN;
                    this.$scope.model.deliveryInfo.note = deliveryInfo.Note;
                    this.$scope.model.deliveryInfo.transporterNameLastname = deliveryInfo.DeliveryPersonName;
                    this.$scope.model.deliveryInfo.transporterPersonalNumber = deliveryInfo.DeliveryPersonSn;
                    this.$scope.model.deliveryInfo.deliveryType = deliveryInfo.DeliveryType;
                    this.$scope.model.deliveryInfo.waybillTransportType = deliveryInfo.RSTransportType;
                    this.$scope.model.deliveryInfo.waybillType = deliveryInfo.RSWaybillType;
                    this.$scope.model.deliveryInfo.preparedListType = deliveryInfo.PrepareList;
                    this.$scope.model.deliveryInfo.receiverNameLastname = deliveryInfo.RecipientName;
                    this.$scope.model.deliveryInfo.receiverPersonalNumber = deliveryInfo.RecipientSn;
                }
                else {
                    if (this.$scope.model.deliveryInfo.deliveryData.length > 0) {
                        this.$scope.model.deliveryInfo.deliveryDate = this.todate(_.first(this.$scope.model.deliveryInfo.deliveryData).DeliveryDate);
                    }
                }


                this.$scope.supplierInfo = '';
                if (this.$scope.Order.Supplier != undefined && this.$scope.Order.Supplier.Company != undefined) {
                    this.$scope.supplierInfo += this.translate.instant('company') + ': ' + this.$scope.Order.Supplier.Company.Name + '. '
                    this.$scope.supplierInfo += this.translate.instant('snFull1') + ': ' + this.$scope.Order.Supplier.Company.Code + '. ';
                }

                this.$scope.recipientInfo = '';
                if (this.$scope.Order.Recipient != undefined && this.$scope.Order.Recipient.Company != undefined) {
                    this.$scope.recipientInfo += this.translate.instant('company') + ': ' + this.$scope.Order.Recipient.Company.Name + '. '
                    this.$scope.recipientInfo += this.translate.instant('snFull1') + ': ' + this.$scope.Order.Recipient.Company.Code + '. ';
                }
                

            }



            // setTimeout(() => {
                if (this.$scope.Order == undefined) {
                    $('#addProduct').addClass("k-state-disabled");
                }
                if (this.$scope.Order != undefined && this.$scope.Order.OrderStatus != 0) {
                    $('#addProduct').addClass("k-state-disabled");
                    $('#addService').addClass("k-state-disabled");
                    $('#multiDeleteProduct').addClass("k-state-disabled");
                    $('#multiDeleteService').addClass("k-state-disabled");
                }

            // }, 70);

            this.$scope.$watch('model.deliveryInfo.deliveryDate', (val) => {
                if (val != undefined) {
                    if (this.$scope.Order != undefined) {
                        var deliveryData = _.values(this.$scope.Order.DeliveryInfo);
                        if (this.$scope.deliveryInfoSelectedTabIndex != undefined) {
                            deliveryData[this.$scope.deliveryInfoSelectedTabIndex].DeliveryDate = val;
                        }
                        else {
                            if (deliveryData.length > 0)
                                _.first(_.values(this.$scope.Order.DeliveryInfo)).DeliveryDate = val;
                        }
                    }
                }
            });

            // setTimeout(() => {
                $('#addProduct').on('click', (e) => {
                    this.$scope.isBusy = true;
                    if (this.$scope.productModel.productsGrid.gridView != undefined) {
                        this.$scope.productModel.productsFilterForRcount = 0;
                    }
                });

                $('#addService').on('click', (e) => {
                    this.$scope.isBusy = true;
                    if (this.$scope.Order == undefined && this.$scope.serviceModel.services.length > 0) {
                        this.filterServicesDatasource();
                    }
                });

            // }, 100);

            if (this.$scope.Order == undefined || (this.$scope.Order != undefined && this.$scope.Order.OrderStatus == 0)) {
                // setTimeout(() => {
                    $('#multiDeleteService').on('click', () => {
                        this.multiDeleteServicesGrid();
                    });
                // }, 300);
                // setTimeout(() => {
                    // $('#multiDeleteProduct').on('click', () => {
                    //     this.multiDeleteProductsGrid();
                    // });
                // }, 300);
            }

            if (!this.$scope.Visibility.couponsTabInvisible) {
                // setTimeout(() => {
                    $('#multiDeleteCoupon').on('click', () => {
                        this.multiDeleteCouponsGrid();
                    });

                    $('#addCoupon').on('click', (e) => {
                        this.$scope.isBusy = true;
                        // setTimeout(() => {
                            $('#couponCodeInput').focusin();
                        // }, 300);
                    });
                // }, 300);
            }

            if (this.$scope.preorderType != 'OrderTranzit') {
                this.distributionService.getMobileusers().then((data) => {
                    this.$scope.components.deliveryInfoComponents.mobileUsersLookup.dataSource.data(data.Result);
                    if (this.$scope.Order != undefined) {
                        var deliveryData = _.values(this.$scope.Order.DeliveryInfo);
                        if (deliveryData.length > 1) {
                            _.forEach(deliveryData, (item) => {
                                if (item.ForwarderUserId != undefined) {
                                    var forwarder = _.where(data.Result, { Id: item.ForwarderUserId })[0];
                                    item.ForwarderUserId = forwarder;
                                }
                            });
                        }
                        else {
                            if (deliveryData.length > 0 && _.first(deliveryData).ForwarderUserId != undefined) {
                                var forwarder = _.where(data.Result, { Id: _.first(deliveryData).ForwarderUserId })[0];
                                this.$scope.model.deliveryInfo.forwarder = forwarder;
                            }
                        }
                    }
                    return data;
                });
            }

            this.$scope.$watch('productModel.productsFilterForRcount', (val: any) => {
                if (val != undefined) {
                    var filter;
                    switch (val) {
                        case '1': {
                            filter = { filterName: 'rcount', filterOperator: 'GreaterThan', filterValue: '0' };
                            break;
                        }
                        case '2':
                            {
                                filter = { filterName: 'sys_rcount', filterOperator: 'GreaterThan', filterValue: '0' };
                                break;
                            }
                    }
                    if (this.$scope.productModel.productsGrid.gridView != undefined) {
                        var filters = this.getFiltersForModalProductsGrid();
                        if (filter != undefined) {
                            filters.push(filter);
                        }

                        this.$scope.productModel.productsGrid.gridView.showLoading();
                        this.$scope.productModel.productsGrid.gridView.setFilter(filters);
                        this.$scope.productModel.productsGrid.gridView.refresh();
                    }
                }
            });

            this.$scope.$watch('serviceModel.addedService.service', (service: any) => {
                if (service != undefined) {
                    this.$scope.serviceModel.addedService.Price = service.Price;
                    this.$scope.serviceModel.addedService.count = this.$scope.serviceModel.addedService.sum = 0;
                }
            });
            this.$scope.$watch('model.recipient', (recipientBranch: any) => {
                if (!this.$scope.Order && recipientBranch != undefined) {

                    this.$scope.model.vatType = recipientBranch.Company.VatType;

                    if (recipientBranch.CustomerParameters != undefined && recipientBranch.CustomerParameters.CreditDays != undefined && recipientBranch.CustomerParameters.CreditDays != 0) {
                        var newPaymentDate = new Date();
                        newPaymentDate.setDate(this.$scope.model.date.getDate() + recipientBranch.CustomerParameters.CreditDays);
                        this.$scope.model.paymentDate = newPaymentDate;
                    }
                    else this.$scope.model.paymentDate = this.$scope.model.date;

                    if (this.$scope.model.supplier != undefined) {
                        $('#addProduct').removeClass("k-state-disabled");
                        this.updateContractsDatasource();
                    }
                    this.crmService.getBranchCrmOrderStats(this.$scope.model.recipient.Id).then(
                        res => {
                            this.$scope.LabelsModel.recipientStats = res;
                            delete this.$scope.LabelsModel.contractDets;
                            this.initLabelValues();
                        }
                    );

                    if (recipientBranch.Address != undefined && recipientBranch.Address.FullAddress != undefined) {
                        this.$scope.model.deliveryInfo.addressTo = recipientBranch.Address.FullAddress;
                    }
                    else this.$scope.model.deliveryInfo.addressTo = '';
                }

            });

            this.$scope.$watch('model.contract', (contract: any) => {
                if (!this.$scope.Order && contract != undefined) {
                    var newPaymentDate = new Date();
                    newPaymentDate.setDate(this.$scope.model.date.getDate() + contract.Creditdays);
                    this.$scope.model.paymentDate = newPaymentDate;
                    this.$scope.LabelsModel.contractDets = contract;
                    this.initLabelValues();
                }
            });


            this.$scope.$watch('model.supplier', (supplierBranch: any) => {
                if (!this.$scope.Order && supplierBranch != undefined) {
                    if (this.$scope.model.recipient != undefined) {
                        $('#addProduct').removeClass("k-state-disabled");
                        this.updateContractsDatasource();
                        delete this.$scope.LabelsModel.contractDets;
                        this.initLabelValues();
                    }


                    if (supplierBranch.Address != undefined && supplierBranch.Address.FullAddress != undefined) {
                        this.$scope.model.deliveryInfo.addressFrom = supplierBranch.Address.FullAddress;
                    }
                    else this.$scope.model.deliveryInfo.addressFrom = '';
                }
            });


            this.commonService.getCars().then((data) => {
                var scope = this.$scope;
                this.$scope.components.deliveryInfoComponents.carDropdownOptions = {
                    placeholder: "აირჩიეთ მანქანა",
                    filter: "contains",
                    dataTextField: "Number",
                    select: function (e) {
                        if (e != undefined && e != null && e.item != undefined && e.item != null) {
                            var dataItem = this.dataItem(e.item.index());
                            if (dataItem != undefined && dataItem != null) {
                                if (this.$scope.deliveryInfoSelectedTabIndex != undefined) {
                                    //$scope.Order.DeliveryInfo[($scope.deliveryInfoSelectedTabIndex + 1)].TransportDescription = dataItem.Description;
                                    scope.model.deliveryInfo.deliveryData[this.$scope.deliveryInfoSelectedTabIndex].TransportDescription = dataItem.Description;
                                }
                                else {
                                    if (this.$scope.Order != undefined && _.values(this.$scope.Order.DeliveryInfo).length > 1) {
                                        _.first(_.values(this.$scope.Order.DeliveryInfo)).TransportDescription = dataItem.Description;
                                    } else this.$scope.model.deliveryInfo.carMark = dataItem.Description;
                                }
                            }
                        }
                    },
                    change: function (e) {
                        if (this.$scope.deliveryInfoSelectedTabIndex != undefined) {
                            this.$scope.Order.DeliveryInfo[(this.$scope.deliveryInfoSelectedTabIndex + 1)].TransportNumber = e.sender._prev;
                        }
                        else this.$scope.model.deliveryInfo.carNumber = e.sender._prev;

                        this.$scope.makeFormDirty();
                    },
                    template: "#=Description # - #=Number #",
                    dataValueField: "Number",
                    dataSource: { data: data.Result }
                }
            });

            this.commonService.getDrivers().then((data) => {
                var scope = this.$scope;
                this.$scope.components.deliveryInfoComponents.driverPersonalNumbersDropdownOptions = {
                    placeholder: "პირადი ნომერი",
                    filter: "contains",
                    dataTextField: "PerNum",
                    select: function (e) {
                        if (e != undefined && e != null && e.item != undefined && e.item != null) {
                            var dataItem = this.dataItem(e.item.index());
                            if (dataItem != undefined && dataItem != null) {
                                if (this.$scope.deliveryInfoSelectedTabIndex != undefined) {
                                    // $scope.Order.DeliveryInfo[($scope.deliveryInfoSelectedTabIndex + 1)].DriverName = dataItem.Name;
                                    scope.model.deliveryInfo.deliveryData[this.$scope.deliveryInfoSelectedTabIndex].DriverName = dataItem.Name;
                                }
                                else {
                                    if (this.$scope.Order != undefined && _.values(this.$scope.Order.DeliveryInfo).length > 1) {
                                        _.first(_.values(this.$scope.Order.DeliveryInfo)).DriverName = dataItem.Name;
                                    } else this.$scope.model.deliveryInfo.driverName = dataItem.Name;
                                }
                            }
                        }
                    },
                    change: function (e) {
                        if (this.$scope.deliveryInfoSelectedTabIndex != undefined) {
                            this.$scope.Order.DeliveryInfo[(this.$scope.deliveryInfoSelectedTabIndex + 1)].DriverSN = e.sender._prev;
                        }
                        else this.$scope.model.deliveryInfo.driverPersonalNumber = e.sender._prev;

                        this.$scope.makeFormDirty();
                    },
                    template: "#=Name # - #=PerNum #",
                    dataValueField: "PerNum",
                    dataSource: { data: data.Result }
                }

                this.$scope.components.deliveryInfoComponents.driverNamesDropdownOptions = {
                    placeholder: "სახელი",
                    filter: "contains",
                    dataTextField: "Name",
                    select: function (e) {
                        if (e != undefined && e != null && e.item != undefined && e.item != null) {
                            var dataItem = this.dataItem(e.item.index());
                            if (dataItem != undefined && dataItem != null) {
                                if (this.$scope.deliveryInfoSelectedTabIndex != undefined) {
                                    scope.model.deliveryInfo.deliveryData[this.$scope.deliveryInfoSelectedTabIndex].DriverSN = dataItem.PerNum;
                                }
                                else {
                                    if (this.$scope.Order != undefined && _.values(this.$scope.Order.DeliveryInfo).length > 1) {
                                        _.first(_.values(this.$scope.Order.DeliveryInfo)).DriverSN = dataItem.PerNum;
                                    }
                                    else this.$scope.model.deliveryInfo.driverPersonalNumber = dataItem.PerNum;
                                }
                            }
                        }
                    },
                    change: function (e) {
                        if (this.$scope.deliveryInfoSelectedTabIndex != undefined) {
                            this.$scope.Order.DeliveryInfo[(this.$scope.deliveryInfoSelectedTabIndex + 1)].DriverName = e.sender._prev;
                        }
                        else this.$scope.model.deliveryInfo.driverName = e.sender._prev;

                        this.$scope.makeFormDirty();
                    },
                    template: "#=Name # - #=PerNum #",
                    dataValueField: "Name",
                    dataSource: { data: data.Result }
                }
            });
            // },3000);
        }
        addProducts=()=>{
            // var data={recipient:this.$scope.Order.Recipient,supplier:this.$scope.Order.Supplier, Order:this.$scope.Order, manageRCount:this.$scope.manageRCount.val}
            if(!this.$scope.Order && !this.$scope.model.supplier){
                this.toaster.pop("warning","შენიშვნა","გთხოვთ აირჩიოთ გამგზავნი!");
                return;
            }
            
            if(this.$scope.Order)
                this.openProductsModalForm({recipient:this.$scope.Order.Recipient,supplier:this.$scope.Order.Supplier, Order:this.$scope.Order, manageRCount:this.$scope.manageRCount.val, OrderType:this.$scope.OrderType});
            else 
                this.openProductsModalForm({recipient:null,supplier:this.$scope.model.supplier, Order:{}, manageRCount:this.$scope.manageRCount.val, OrderType:this.$scope.OrderType});
        }
        deleteProducts=()=>{
            this.multiDeleteProductsGrid();
        }

        openProductsModalForm=(data)=>{
            var dialog= Core.Utils.ModalForm.openModalForm("products.modal.html",Apex.Operations.Controllers.ProductsModal.getName(),data,Core.Utils.ModalSize.Medium,true );
            dialog.result.then(r=>{
                if(r){
                    for(var prop in r){
                        
                        r[prop].MinPrice=r[prop].min_price;
                        r[prop].Price=r[prop].price;
                        r[prop].Pricegr=r[prop].price;
                        r[prop].OCount=r[prop].count;
                        // if(this.$scope.productModel.addedProducts[prop]){
                        //     this.$scope.productModel.addedProducts[prop].count+=r[prop].count;
                        // } else 
                        this.$scope.productModel.addedProducts[prop]=r[prop];
                        this.$scope.gridsAreDirty=true;
                    }
                }
                this.$scope.components.productsGrid.dataSource.read();
            });
        }


        updateContractsDatasource = () => {
            if (this.$scope.Visibility != undefined && !this.$scope.Visibility.contractsInvisible) {
                {
                    this.crmService.GetContractDetailsByBranch(this.$scope.model.supplier.Id, this.$scope.model.recipient.Id).then(res => {
                        delete this.$scope.model.contract;
                        this.$scope.components.contractsLookUp.dataSource = res.Result.toDatasource();
                    });
                }
            }
        }

        filterServicesDatasource = () => {
            var tmp = _.map(this.$scope.serviceModel.services, (item: any) => { return item.Service.ServiceID });

            var result = _.reject(this.$scope.serviceModel.servicesLookupData, (item) => {
                return _.contains(tmp, item.ServiceID);
            });

            this.$scope.serviceModel.servicesLookup.dataSource = result.toDatasource();
        }


        private initCouponsGrid = (): Components.IApxGrid => {
            var grid: Components.IApxGrid = {
                settings: {
                    // selectable: 'multiple',
                    editble: {
                        popupTemplateID: 'add_coupon_popup_template',
                        popupWidth: 500
                    },
                    gridButtons: {
                        add: { enabled: true, id: 'Coupon' },
                        multiDelete: { enabled: true, id: 'Coupon' }
                    }
                },
                parentScope: this.$scope,
                columns: [{ field: 'Id', hidden: true },
                    { field: 'Code', titleTranslated: 'code' },
                    { field: 'ExpirationDate', titleTranslated: 'lifeDate', filterable: false, format: Core.Kendo.Format.DateTime }
                ],
                dataSource: new kendo.data.DataSource({
                    data: [], schema: {
                        model: {
                            id: 'Code',
                            fields: {
                                Code: { editable: false, type: 'string' },
                                ExpirationDate: { editable: false, type: 'date' },
                                Id: { type: 'number' }
                            }
                        }
                    }
                }),
                gridEvents: [this.getCouponsGridAddEvent(), this.getCouponsGridCancelEvent()]
            };
            return grid;
        }





        private getCouponsGridCancelEvent(): Components.IEvent {
            return {
                eType: Components.EventType.Cancel,
                eFunction: this.cancelCoupon
            };
        }

        multiDeleteServicesGrid = () => {
            var selectedRows = this.$scope.components.servicesGrid.grid.select();
            var length: number = selectedRows.length;
            if (length === 0)
                return;

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var r: any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }

            r.result.then(() => {
                _.forEach(selectedRows, (item) => {
                    var model: any = this.$scope.components.servicesGrid.grid.dataItem(item);

                    model.SCount = -1;
                    this.$scope.serviceModel.servicesForDelete.push(model);

                    for (var i = 0; i < this.$scope.serviceModel.services.length; i++) {
                        if (this.$scope.serviceModel.services[i].ID === model.ID) {
                            this.$scope.serviceModel.services.splice(i, 1);
                            break;
                        }
                    }
                    this.initLabelValues();
                });

                this.$scope.components.servicesGrid.grid.dataSource.data(this.$scope.serviceModel.services);
                this.makeFormDirty();
            });
        }

        multiDeleteProductsGrid = () => {
            var selectedRows = this.$scope.components.productsGrid.grid.select();
            var length: number = selectedRows.length;
            if (length === 0)
                return;

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var r: any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }

            r.result.then(() => {
                for (var i = 0; i < selectedRows.length; i++) {
                    var model: any = this.$scope.components.productsGrid.grid.dataItem(selectedRows[i]);
                    model.OCount = -1;
                    this.$scope.productModel.productsForDelete.push(model);
                    delete this.$scope.productModel.addedProducts['"' + model.prodpp_id + '"'];
                }
                this.initLabelValues();
                if (Object.keys(this.$scope.productModel.addedProducts).length == 0) {
                    if (this.$scope.gridsAreDirty) {
                        this.$scope.gridsAreDirty = false;
                    }
                }
                this.$scope.components.productsGrid.grid.dataSource.read();
            });

            this.makeFormDirty();
        }

        multiDeleteCouponsGrid = () => {
            var selectedRows = this.$scope.components.couponsGrid.grid.select();
            var length: number = selectedRows.length;
            if (length === 0)
                return;

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var r: any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }

            r.result.then(() => {
                _.forEach(selectedRows, (item) => {
                    var model: any = this.$scope.components.couponsGrid.grid.dataItem(item);
                    this.$scope.components.couponsGrid.grid.dataSource.remove(this.$scope.components.couponsGrid.grid.dataItem(item));

                    model.Id *= -1;
                    this.$scope.couponModel.couponsForDelete.push(model);

                    for (var i = 0; i < this.$scope.couponModel.coupons.length; i++) {
                        if (this.$scope.couponModel.coupons[i].Coupon.Id === model.Coupon.Id) {
                            this.$scope.couponModel.coupons.splice(i, 1);
                            break;
                        }
                    }
                    this.$scope.components.couponsGrid.dataSource.data(this.$scope.couponModel.coupons);
                });
                this.makeFormDirty();
            });
        }

        addCoupon = (e: kendo.ui.GridEvent) => {
            var curCoupon = this.$scope.couponModel.couponForAdd;
            if (this.$scope.couponModel.couponForAdd.coupon.Used == true) {
                Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('couponIsUsed')) });
                e.preventDefault();
                return;
            }

            if (_.some(this.$scope.couponModel.coupons, function (coupon) { return coupon.Code == curCoupon.code })) {
                Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('couponIsAdded')) });
                e.preventDefault();
                return;
            }

            this.$scope.couponModel.coupons.unshift({
                Coupon: curCoupon.coupon,
                Code: curCoupon.coupon.Code,
                ExpirationDate: curCoupon.coupon.ExpirationDate
            });

            this.$scope.components.couponsGrid.dataSource.data(this.$scope.couponModel.coupons);
            this.cancelCoupon();
            this.makeFormDirty();
        }

        private getCouponsGridAddEvent(): Components.IEvent {
            return {
                eType: Components.EventType.Save, eFunction: (e) => {
                    if (this.$scope.couponModel.couponForAdd.coupon == undefined) {
                        Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('chooseCoupon')) });
                        e.preventDefault();
                        return;
                    }
                    else {
                        this.addCoupon(e);
                    }
                }
            }
        }


        getPreparedListColumns = (): Array<Core.Interfaces.IGridColumn> => {
            return [
                { field: 'Id', hidden: true },
                { field: 'Number', width: 200 }
            ];
        };
        getMobileUserColumns = (): Array<Core.Interfaces.IGridColumn> => {
            return [
                { field: 'Id', hidden: true },
                { field: 'NameU', width: 200, titleTranslated: 'nameCaption' },
                { field: 'UserName', titleTranslated: 'user' }
            ];
        };

        private productsGridTemplates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                editformat: "{0:##.####}",
                fieldName: 'count', editor: function (container, options) {

                    $("<input class='apx-color-lemonchiffon' name='" + options.field + "' id=countInputProductsGrid />")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 4,
                            spinners: false,
                            min: 0,
                            step: 0
                        });
                }
            },
            {
                editformat: "{0:##.####}",
                fieldName: 'Pricegr', editor: function (container, options) {
                    $("<input class='apx-color-lemonchiffon' name='" + options.field + "'/>")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 4,
                            spinners: false,
                            min: 0,
                            step: 0
                        });
                }
            }
        ];

        getProductsGridConfig = (): Components.IApxGrid => {
            var columns = this.productsGridColumns;
            if (this.$scope.preorderType == 'OrderTranzit') {
                _.forEach(columns, (itm: Core.Interfaces.IGridColumn) => {
                    if (itm.field == 'Pricegr' || itm.field == 'MinPrice' || itm.field == 'Discount') {
                        itm.hidden = true;
                        return;
                    }
                });
            }
            var productsGrid: Components.IApxGrid;
            productsGrid = {
                columns: columns,
                templates: this.productsGridTemplates,
                dataSource: this.getProductsDatasource(),
                settings: {
                    selectable: "multiple",
                    // gridButtons: {
                    //     add: { enabled: true, id: 'Product', }, multiDelete: { enabled: true, id: 'Product' }
                    // },
                    height: 400,
                    editble: { popupTemplateID: 'product_popup_editor', popupWidth: 1100 }
                },
                isLookup: false,
                parentScope: this.$scope,
                gridEvents:
                [
                    this.getProductAddEvent(),
                    // this.getProductsCancelEvent(),
                    this.groductsGridChangeCellEvent(),
                    {
                        eType:Components.EventType.Change,
                        eFunction:(e)=>{
                            var selectedRow = e.sender.select();
                            Apex.Core.Utils.Helper.safeApply(this.$scope,()=>{
                                this.$scope.disableDeleteBtn= selectedRow.length==0;

                            });
                        }
                    }
                ]
            };
            return productsGrid;
        }

        getDatasource = (val) => {
            if (val.Prod === undefined)
                return _.values(val);
            else val;
        }

        private getProductsDatasource(): kendo.data.DataSource {
            var scope = this.$scope;
            var ds = new kendo.data.DataSource({
                data: this.$scope.productModel.addedProducts,
                aggregate: [
                    { field: 'Pricegr', aggregate: 'sum' },
                    { field: 'count', aggregate: 'sum' }
                ],
                schema: {
                    parse: this.getDatasource,
                    model: {
                        id: "prodpp_id",
                        fields: {
                            'bcode': { editable: false, type: 'string' },
                            'category_nu': { editable: false, type: 'string' },
                            'products_nu': { editable: false, type: 'string' },
                            'Discount': { editable: false, type: 'number' },
                            'producer_nu': { editable: false, type: 'string' },
                            'MinPrice': { editable: false, type: 'number' },
                            'unit': { editable: false, type: 'string' },
                            'Pricegr': (this.$scope.Order != undefined && this.$scope.Order.OrderStatus != 0) ? { editable: false, type: 'number' } : { type: 'number' },
                            'count': (this.$scope.Order != undefined && this.$scope.Order.OrderStatus != 0) ? { editable: false, type: 'number' } : { type: 'number' },
                            'prodpp_id': { type: 'string' },
                            'price': { type: 'number' },
                            'totalPrice':{type: 'number',  editable: false}
                        }
                    }
                }
            });
            return ds;
        }


        // private getProductsCancelEvent(): Components.IEvent {
        //     var cancelEventType: Components.EventType = Components.EventType.Cancel;
        //     var cancelEvent: Components.IEvent =
        //         {
        //             eType: cancelEventType,
        //             eFunction: this.productsGridCancelEventFunctionality
        //         };
        //     return cancelEvent;
        // }
        // productsGridCancelEventFunctionality = (e: kendo.ui.GridEvent) => {
        //     this.clearProductModel();
        //     setTimeout(() => { this.$scope.isBusy = false; }, 100);
        // }
        private getProductAddEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.productsGridAddEventFunctionality
                };
            return addEvent;
        }

        getDeliveryTypes = () => {
            return [{ cid: 0, cnf: 'ჩვეულებრივი' },
                { cid: 1, cnf: 'მიტანის გარეშე' },
                { cid: 2, cnf: 'აგენტი წაიღებს' }];
        }

        getWaybillTransportTypes = () => {
            return [{ id: 1, value: 'საავტომობილო' },
                { id: 6, value: 'საავტომობილო - უცხო ქვეყნის' },
                { id: 2, value: 'სარკინიგზო' },
                { id: 3, value: 'საავიაციო' },
                { id: 4, value: 'სხვა' }];
        }



        getWaybillTypes = () => {
            var res;
            if (this.$scope.preorderType != 'OrderTranzit') {
                res = [
                    { id: 2, value: 'ტრანსპორტირებით' },
                    { id: 3, value: 'ტრანსპორტირების გარეშე' }
                ];
                return res;
            }
            else {
                res = [
                    { id: 1, value: 'შიდა გადაზიდვა' }
                ];
                return res;
            }
        }

        productsGridAddEventFunctionality = (e) => {
            if (e.model != undefined) {
                switch (null) {
                    case e.values.count:
                        e.preventDefault();
                        return;
                    case e.values.Pricegr:
                        e.preventDefault();
                        return;
                }
                //edit in grid
                if (e.values.count != undefined) {
                    if (this.$scope.Order != undefined && this.$scope.manageRCount.val == true) {
                        this.crmService.checkCrmOrderProdRem(this.$scope.Order.OrderId, e.model.prodpp_id, e.values.count).then(res => {
                            if (res.CanOrder == true) {
                                e.model.count = e.model.OCount = e.values.count;
                                this.$scope.productModel.addedProducts['"' + e.model.prodpp_id + '"'] = e.model;
                                this.$scope.components.productsGrid.dataSource.read();
                                this.initLabelValues();
                                this.makeFormDirty();
                            }
                            else {
                                Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('notenoughremains')) });
                                this.$scope.components.productsGrid.dataSource.read();
                            }
                        });
                    }
                    else {
                        e.model.count = e.model.OCount = e.values.count;
                        this.$scope.productModel.addedProducts['"' + e.model.prodpp_id + '"'] = e.model;
                        this.$scope.components.productsGrid.dataSource.read();
                        this.initLabelValues();
                        this.makeFormDirty();
                    }
                    return;
                }
                if (e.values.Pricegr != undefined) {
                    if (e.model.MinPrice != undefined) {
                        if (e.values.Pricegr < e.model.MinPrice) {
                            Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('error', this.translate.instant('Attention'), 'საქონლის ფასი უნდა აღემატებოდეს მინიმალურ ფასს.') });
                            e.preventDefault();
                            return;
                        }
                        else {
                            e.model.Price = e.model.price = e.values.Pricegr;
                            this.$scope.productModel.addedProducts['"' + e.model.prodpp_id + '"'] = e.model;
                            this.$scope.components.productsGrid.dataSource.read();
                            this.initLabelValues();
                            this.makeFormDirty();
                            return;
                        }
                    }
                    else {
                        e.model.Price = e.model.price = e.values.Pricegr;
                        this.$scope.productModel.addedProducts['"' + e.model.prodpp_id + '"'] = e.model;
                        this.$scope.components.productsGrid.dataSource.read();
                        this.initLabelValues();
                        this.makeFormDirty();
                    }
                }
            }
            else {
                //add new items                 
                _.forEach(_.values(this.$scope.productModel.modalMarkedProducts), (item) => {
                    if (this.$scope.productModel.addedProducts['"' + item.prodpp_id + '"'] == undefined) {
                        item.Prod = { ID: item.prodpp_id };
                        item.Price = item.Pricegr = item.price;
                        item.MinPrice = item.min_price;
                        item.OCount = item.count;
                        item.Discount = 0;
                        if (this.$scope.model.vatType == 1 && item.vattype == 0) //todo check diplomat here
                        {
                            var notTaxedPrice = parseFloat((item.price - (item.price * 0.18)).toFixed(4));
                            item.Price = item.Pricegr = item.price = notTaxedPrice;
                        }

                        this.$scope.productModel.addedProducts['"' + item.prodpp_id + '"'] = item;
                    }
                });
                if (Object.keys(this.$scope.productModel.addedProducts).length > 0 && !this.$scope.gridsAreDirty) {
                    this.$scope.gridsAreDirty = true;
                }
                this.$scope.components.productsGrid.grid.dataSource.read();
                this.initLabelValues();
                if (Object.keys(this.$scope.productModel.modalMarkedProducts).length > 0)
                    this.makeFormDirty();
                this.$scope.productModel.modalMarkedProducts = {};
            }
        }

        private clearProductModel = () => {
            this.$scope.productModel.modalMarkedProducts = {};
            this.$scope.components.productsGrid.grid.dataSource.read();
        }

        SearchLoyaltyCard = () => {
            if(this.$scope.modalIsOpened)
                return ;

            this.$scope.modalIsOpened=true;
            if (this.$scope.model.loyaltyCard == undefined || this.$scope.model.loyaltyCard.Number == undefined || this.$scope.model.loyaltyCard.Number.trim().length == 0) {
                Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('error', 'ყურადღება', 'შეიყვანეთ ბარათის ნომერი.'); });
                return;
            }
            this.commonService.getLoyaltyCard(this.$scope.model.loyaltyCard.Number).then(res => {
                if (res.success != false && res.Result != undefined) {
                    var alertString = this.$scope.model.loyaltyCard.Number.toString() + '; ';
                    
                    if (res.Result.Owner != undefined) {
                        alertString += res.Result.Owner.FullName + '(' + res.Result.Owner.Sn.trim() + '); ';
                        this.$scope.loyaltyCardOwnerText = res.Result.Owner.FullName + '(' + res.Result.Owner.Sn.trim() + '); ';
                    }

                    if(res.Result.CardType==1){
                        alertString +="ფასდათმობის ბარათი ("+ Math.round(res.Result.Discount*10000)/100+"%)";
                    } else if(res.Result.CardType==2){
                        alertString += 'დაგროვების ბარათი (ქულა:' + res.Result.Points + ')';
                    } else if(res.Result.CardType==3){
                        alertString += 'ფასდათმობისა და დაგროვების ბარათი (ქულა:' + res.Result.Points +"; "+ Math.round(res.Result.Discount*10000)/100 + '%)';
                    }

                    var confirmationAalert = Core.Utils.ModalForm.openConfirmation(alertString);

                    confirmationAalert.result.then(() => {
                        this.$scope.model.loyaltyCard = res.Result;
                        this.$scope.loyaltyCardAdded = true;
                        this.$scope.modalIsOpened=false;
                    }, ()=>{this.$scope.modalIsOpened=false;});
                    
                }
                else {
                    this.toaster.pop('error', 'ყურადღება', 'ბარათი ვერ მოიძებნა.');
                    $('#loyaltyCardInput').select();
                }
            });
        }

        todate(d) {            
            if (!!d) {
                if (d.getMonth)
                    return d;
                var myDate = new Date(parseInt(d.replace('/Date(', '')));
                return myDate;
            }
            return null;
        }
        private recalculation = (keycode, val: string): void => {
            var model: any = this.$scope.serviceModel.addedService;
            switch (val) {
                case 'count':
                case 'price':
                    {
                        this.calculateFromCountAndPrice(model);
                        break;
                    }
            }
        }

        private calculateFromCountAndPrice = (model: any) => {
            if (model.count != undefined && model.count !== 0 && model.Price != undefined && model.Price !== 0) {
                model.sum = parseFloat((model.Price * model.count).toFixed(4));
            } else {
                model.sum = 0;
            }
            model.count = model.count * 1;
            model.Price = model.Price * 1;
        }

        convertOrderForSave(orderType: Utils.PreorderType): any {

            if (orderType != Utils.PreorderType.OrderRetail) {
                if (this.$scope.model.recipient == undefined || this.$scope.model.supplier == undefined) {
                    this.toaster.pop('error', this.translate.instant('Attention'), 'გთხოვთ აირჩიოთ მიმღები და გამგზავნი.')
                    return null;
                }
            }
            else {
                if (this.$scope.model.RecipientName == undefined || this.$scope.model.RecipientSn == undefined ||
                    (this.$scope.model.RecipientName != undefined && this.$scope.model.RecipientName.toString().trim().length == 0) ||
                    (this.$scope.model.RecipientSn != undefined && this.$scope.model.RecipientSn.toString().trim().length == 0)) {
                    this.toaster.pop('error', this.translate.instant('Attention'), 'გთხოვთ შეიყვანოთ მიმღების ინფორმაცია.');
                    return null;
                }
            }

            if (this.$scope.model.recipient && this.$scope.model.recipient.Id == this.$scope.model.supplier.Id) {
                this.toaster.pop('error', this.translate.instant('Attention'), 'გთხოვთ, აირჩიოთ განსხვავებული საწყობები.');
                return null;
            }

            if ((this.$scope.serviceModel != undefined && this.$scope.serviceModel.services.length == 0) && _.values(this.$scope.productModel.addedProducts).length == 0) {
                if (this.$scope.Visibility.servicesTabInvisible == true)
                    this.toaster.pop('error', this.translate.instant('Attention'), 'შენახვისათვის აუცილებელია შეავსოთ პროდუქტის ცხრილი.');
                else
                    this.toaster.pop('error', this.translate.instant('Attention'), 'შეკვეთის შესანახად აუცილებელია საქონლის ან მომსახურების დამატება,');
                return null;
            }

           

            // var intersection =_.intersection(_.values(this.$scope.productModel.addedProducts), this.$scope.productModel.productsForDelete);
    
            var products =_.union(_.values(this.$scope.productModel.addedProducts), this.$scope.productModel.productsForDelete);            
            
             if (_.findIndex(products,(item)=>{
                 return item.OCount==0;
                })>=0){
                this.toaster.pop('error', this.translate.instant('Attention'), 'შეკვეთის შესანახად აუცილებელია საქონლის ან მომსახურების დამატება.');
                return null;
             }
            var services: any;
            if (!this.$scope.Visibility.servicesTabInvisible) {
                services = _.union(this.$scope.serviceModel.services, this.$scope.serviceModel.servicesForDelete);
                if (_.some(services, function (service: any) { return service.Price == undefined || service.Price == 0; })) {
                    this.toaster.pop('error', this.translate.instant('Attention'), 'ყველა მომსახურებას უნდა ჰქონდეს ფასი.');
                    return null;
                }
            }

            if (this.$scope.preorderType != 'OrderTranzit') {
                if (_.some(products, function (prod) { return prod.Price == undefined || prod.Price == 0; })) {
                    this.toaster.pop('error', this.translate.instant('Attention'), 'ყველა საქონელს უნდა ჰქონდეს ფასი.');
                    return null;
                }
            }


            var order: any = {
                OrderId: null,
                OrderStatus: 0,
                VatType: this.$scope.model.vatType,
                CreateTime: this.$scope.model.date,
                OrderDate: this.$scope.model.date,
                PayDate: this.$scope.model.paymentDate,
                Supplier: { Id: this.$scope.model.supplier.Id },
                UserName: this.$rootScope.usernName,
                Services: services,
                Products: products,
                DeliveryInfo: {}
            }

            order.OrderType = orderType;

            if (this.$scope.model.recipient != undefined) {
                order.Recipient = { Id: this.$scope.model.recipient.Id };
            }

            if (!this.$scope.Visibility.contractsInvisible)
                order.Contract = this.$scope.model.contract;

            if (!this.$scope.Visibility.loyaltyCardInvisible)
                order.LoyaltyCard = this.$scope.model.loyaltyCard;

            if (!this.$scope.Visibility.couponsTabInvisible) {
                order.Coupons = _.union(this.$scope.couponModel.coupons, this.$scope.couponModel.couponsForDelete);
            }

            if (this.$scope.Order != undefined) {
                order.OrderId = this.$scope.Order.OrderId;
                order.OrderStatus = this.$scope.Order.Order
            }

            if (this.$scope.Order != undefined) {
                var deliveryInfoAsArray = _.values(this.$scope.Order.DeliveryInfo);
                if (deliveryInfoAsArray.length > 1) {
                    _.forEach(deliveryInfoAsArray, (item) => {
                        if (item.ForwarderUserId != undefined) {
                            item.ForwarderUserId = item.ForwarderUserId.Id;
                        }
                    });                    
                    order.DeliveryInfo = this.$scope.Order.DeliveryInfo;
                    
                }
                else {
                    var key: string = Object.keys(this.$scope.Order.DeliveryInfo)[0];

                    if (key == undefined)
                        key = "0";

                    order.DeliveryInfo = {
                        [key]: {
                            DeliveryType: this.$scope.model.deliveryInfo.deliveryType,
                            DeliveryDate: this.$scope.model.deliveryInfo.deliveryDate,
                            TransportNumber: this.$scope.model.deliveryInfo.carNumber,
                            TransportDescription: this.$scope.model.deliveryInfo.carMark,
                            DeliveryPersonSn: this.$scope.model.deliveryInfo.transporterPersonalNumber,
                            DeliveryPersonName: this.$scope.model.deliveryInfo.transporterNameLastname,
                            RSWaybillType: this.$scope.model.deliveryInfo.waybillType,
                            RSTransportType: this.$scope.model.deliveryInfo.waybillTransportType,
                            RecipientName: this.$scope.model.deliveryInfo.receiverNameLastname,
                            RecipientSn: this.$scope.model.deliveryInfo.receiverPersonalNumber,
                            ToAddress: this.$scope.model.deliveryInfo.addressTo,
                            FromAddress: this.$scope.model.deliveryInfo.addressFrom,
                            PrepareList: null,
                            Note: this.$scope.model.deliveryInfo.note,
                            DriverSN: this.$scope.model.deliveryInfo.driverPersonalNumber,
                            DriverName: this.$scope.model.deliveryInfo.driverName,
                            BranchId: this.$scope.model.supplier.Id,
                            ForwarderUserId: null
                        }
                    }
                }
            }
            else {
                order.DeliveryInfo = {
                    '0': {
                        DeliveryType: this.$scope.model.deliveryInfo.deliveryType,
                        DeliveryDate: this.$scope.model.deliveryInfo.deliveryDate,
                        TransportNumber: this.$scope.model.deliveryInfo.carNumber,
                        TransportDescription: this.$scope.model.deliveryInfo.carMark,
                        DeliveryPersonSn: this.$scope.model.deliveryInfo.transporterPersonalNumber,
                        DeliveryPersonName: this.$scope.model.deliveryInfo.transporterNameLastname,
                        RSWaybillType: this.$scope.model.deliveryInfo.waybillType,
                        RSTransportType: this.$scope.model.deliveryInfo.waybillTransportType,
                        RecipientName: this.$scope.model.deliveryInfo.receiverNameLastname,
                        RecipientSn: this.$scope.model.deliveryInfo.receiverPersonalNumber,
                        ToAddress: this.$scope.model.deliveryInfo.addressTo,
                        FromAddress: this.$scope.model.deliveryInfo.addressFrom,
                        PrepareList: null,
                        Note: this.$scope.model.deliveryInfo.note,
                        DriverSN: this.$scope.model.deliveryInfo.driverPersonalNumber,
                        DriverName: this.$scope.model.deliveryInfo.driverName,
                        BranchId: this.$scope.model.supplier.Id,
                        ForwarderUserId: null
                    }
                }
            }

            if (this.$scope.model.deliveryInfo.preparedListType) {
                _.values(order.DeliveryInfo)[0].PrepareList = { Id: this.$scope.model.deliveryInfo.preparedListType.Id }
            }

            if (this.$scope.model.deliveryInfo.forwarder) {
                _.values(order.DeliveryInfo)[0].ForwarderUserId = this.$scope.model.deliveryInfo.forwarder.Id
            }

            return order;
        }

        saveCrmOrderOrder = (order: any) => {
            kendo.ui.progress($('.modal-content'), true);
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            
            this.crmService.saveCrmOrder(order).then(result => {
                if (typeof result.NewOrderId !== "undefined") {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('successSaved'));
                    kendo.ui.progress($('.modal-content'), false);
                    this.$uibModalInstance.close(result);
                }
                else kendo.ui.progress($('.modal-content'), false);
            });
        }

        getProductsFilterArrayForRcount = () => {
            return [{ id: 0, value: 'ყველა' },
                { id: 1, value: 'ლოკალური ნაშთით' },
                { id: 2, value: 'სისტემური ნაშთით' }];
        }

        searchCoupon = () => {
            if (this.$scope.couponModel.couponForAdd == undefined || this.$scope.couponModel.couponForAdd.code == undefined || this.$scope.couponModel.couponForAdd.code.toString().trim().length == 0) {
                this.toaster.pop('error', this.translate.instant('Attention'), 'შეიყვანეთ კუპონის კოდი');
                return;
            }
            this.commonService.getCoupon(this.$scope.couponModel.couponForAdd.code).then(res => {

                if (res.success != false) {
                    if (res.Coupon) {
                        this.$scope.couponModel.couponForAdd.coupon = res.Coupon;
                        this.$scope.couponModel.couponForAdd.expirationDateForViewBinding = kendo.toString(new Date(parseInt(this.$scope.couponModel.couponForAdd.coupon.ExpirationDate.replace("/Date(", ""))), "dd/MM/yyyy")

                    }
                    else {
                        this.toaster.pop('error', this.translate.instant('Attention'), 'კუპონი ვერ მოიძებნა.');
                        setTimeout(() => { $('#couponCodeInput').select(); }, 50);
                    }
                }
            });

        }
        searchCouponKeyEvent = (e) => {
            if (!this.$scope.eventOccured) {
                if (e.keyCode == 13) {
                    this.searchCoupon();
                }
                this.$scope.eventOccured = true;
            }
            else
                this.$scope.eventOccured = false;

        };
        getTabsContent = () => {
            return 'modals\preorder.modal.shared.content.html'
        }


        cancelCoupon = () => {
            delete this.$scope.couponModel.couponForAdd.coupon;
            this.$scope.couponModel.couponForAdd.code = '';
            this.$scope.components.servicesGrid.grid.dataSource.read();
            setTimeout(() => { this.$scope.isBusy = false; }, 100);
        }
        cancelCouponUi = () => {
            delete this.$scope.couponModel.couponForAdd.coupon;
            setTimeout(() => { $('#couponCodeInput').select(); }, 50);
        }

        getServicesLookupConfig(): Components.IApxLookup {
            var lookUpOptions: Components.IApxLookup =
                {
                    columns: [
                        { field: 'Code', width: 80, titleTranslated: 'code' },
                        { field: 'Name', width: 230, titleTranslated: 'nameCaption' },
                        { field: 'Price', width: 80, titleTranslated: 'price', format: Core.Kendo.Format.Price4 },
                        { field: 'Vat', width: 50, titleTranslated: 'vat', format: Core.Kendo.Format.Percent }
                    ],
                    dataSource: [].toDatasource(),
                    valueText: 'Name',
                    valueID: 'ServiceId'
                };
            return lookUpOptions;
        }

        closeHandle = (e) => {
            if (e.keyCode === 27 && !this.$scope.isBusy) {
                this.closeOrder();
            }
        }

        productsGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'prodpp_id', hidden: true },
            { field: 'bcode', titleTranslated: 'barcode', width: 110 },
            { field: 'products_nu', titleTranslated: 'goods', width: 200 },
            { field: 'Discount', titleTranslated: 'discountpercent', width: 80, format: Core.Kendo.Format.Percent },
            { field: 'price', hidden: true },
            { field: 'MinPrice', format: Core.Kendo.Format.Price4, width: 80, titleTranslated: 'minprice' },
            { field: 'Pricegr', titleTranslated: 'PriceG', width: 80, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Pricegr && data.Pricegr.sum  ? data.Pricegr.sum.toFixed(4) : 0) #' },
            { field: 'count', titleTranslated: 'Count', width: 80, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.count && data.count.sum  ? data.count.sum.toFixed(4) : 0) #' },
            { field: 'totalPrice', titleTranslated: 'vg', width: 80, editable: false, format: Core.Kendo.Format.Price4, template: '#= kendo.toString ((data.Pricegr *  data.count).toFixed(4)) #' },
            { field: 'category_nu', titleTranslated: 'categoryName', width: 200 },
            { field: 'producer_nu', titleTranslated: 'producer', width: 200 },
            { field: 'unit', titleTranslated: 'unit', width: 50 }

        ];

        getProductsGridConfigForModal = (): Components.IApxGrid => {
            
            var datasource = Core.Kendo.GridView.initAutoQueryDataSource(this.crmService.crmProductListQueryUrl);
            var options = datasource.options;
            options.schema.model.fields = {
                'bcode': { type:"string", editable: false },
                'category_nu': {type:"string", editable: false },
                'products_nu': {type:"string", editable: false },
                'producer_nu': {type:"string", editable: false },
                'unit': {type:"number", editable: false },
                'price': { type:"number", editable: false },
                'Discount': {type:"number", editable: false },
                'min_price': {type:"number", editable: false },
                'MinPrice': {type:"number", editable: false },
                'rcount': {type:"number", editable: false },
                'sys_rcount': {type:"number", editable: false },
            };
            options.change = (e) => {
                _.forEach(this.$scope.productModel.productsGrid.dataSource.data(), (item: any) => {
                    if (this.$scope.productModel.modalMarkedProducts['"' + item.prodpp_id + '"'] != undefined) {
                        item.count = this.$scope.productModel.modalMarkedProducts['"' + item.prodpp_id + '"'].count;
                    }
                });
            };

            datasource.init(options);
            var productsGrid: Components.IApxGrid;
            productsGrid = {
                dataSource: datasource,
                templates: this.productsGridTemplatesForModel,
                columns: this.productsGridColumnsForModal,
                settings: {
                    height: 500,
                    pageable: true,
                    editble: {
                        disableEditRow: false
                    },
                    autoBind: false
                },
                initCallback: () => {
                    setTimeout(() => {
                        var filters = this.getFiltersForModalProductsGrid();
                        this.$scope.productModel.productsGrid.gridView.setFilter(filters);
                        this.$scope.productModel.productsGrid.gridView.refresh();
                    }, 200);
                },
                gridEvents: [
                    { eType: Components.EventType.Save, eFunction: this.productsGridModalEditEvent },
                    this.groductsGridChangeCellEventForModal()
                ],
                isLookup: false
            };
            return productsGrid;
        }

        productsGridModalEditEvent = (e) => {
            if (e != undefined && e.model != undefined) {
                if (e.values.count == null) {
                    delete this.$scope.productModel.modalMarkedProducts['"' + e.model.prodpp_id + '"'];
                }
                else {
                    if (this.$scope.manageRCount.val == true && e.values.count > e.model.rcount) {
                        Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('error', this.translate.instant('Attention'), this.translate.instant('notenoughremains')) });
                        e.preventDefault();
                        return;
                    }
                    e.model.Prod = { ID: e.model };
                    this.$scope.productModel.modalMarkedProducts['"' + e.model.prodpp_id + '"'] = e.model;
                }            
            }
        }

        productsGridTemplatesForModel: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                editformat: "{0:##.####}",
                fieldName: 'count', editor: function (container, options) {

                    $("<input class='apx-color-lemonchiffon' name='" + options.field + "' id=countInputProductsGridModal />")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 4,
                            spinners: false,
                            min: 0,
                            step: 0
                        });
                }
            }
        ];

        productsGridColumnsForModal: Array<Core.Interfaces.IGridColumn> = [
            { field: 'prodpp_id', hidden: true },
            { field: 'bcode', titleTranslated: 'barcode', width: 110 },
            { field: 'products_nu', titleTranslated: 'goods', width: 200 },
            { field: 'sys_rcount', titleTranslated: 'sysRcount', width: 80, format: Core.Kendo.Format.Number },
            { field: 'rcount', titleTranslated: 'rest', width: 80, format: Core.Kendo.Format.Number },
            { field: 'price', titleTranslated: 'price', width: 80, format: Core.Kendo.Format.Price4 },
            { field: 'min_price', titleTranslated: 'minprice', width: 80, format: Core.Kendo.Format.Price4 },
            { field: 'count', titleTranslated: 'Count', width: 80, editable: true, format: Core.Kendo.Format.Price4 },
            { field: 'category_nu', titleTranslated: 'categoryName', width: 200 },
            { field: 'producer_nu', titleTranslated: 'producer', width: 190 },
            { field: 'unit', titleTranslated: 'unit', width: 50 }
        ];

        getFiltersForModalProductsGrid = (): Array<Core.Interfaces.IGridFilter> => {
            var filters: Array<Core.Interfaces.IGridFilter> = [];
            if (this.$scope.model.recipient != undefined) {
                filters.push({ filterName: 'BranchId', filterOperator: '', filterValue: this.$scope.model.recipient.Id.toString() });
            }

            if (this.$scope.model.supplier != undefined) {
                filters.push({ filterName: 'SupplierBranchId', filterOperator: '', filterValue: this.$scope.model.supplier.Id.toString() });
            }

            if (this.$scope.Order != undefined) {
                filters.push({ filterName: 'OrderId', filterOperator: '', filterValue: this.$scope.Order.OrderId.toString() });
            }

            return filters;
        };

        serviceGridColumns: Array<Core.Interfaces.IGridColumn> =
        [
            { field: 'Name', titleTranslated: 'nameCaption' },
            { field: 'Code', titleTranslated: 'code', width: 150, footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.Code ? data.Code.count : 0) #' },
            { field: 'Discount', titleTranslated: 'discountpercent', width: 80, format: Core.Kendo.Format.Percent },
            { field: 'SCount', titleTranslated: 'Count', width: 140, editable: true, format: Core.Kendo.Format.Price4 },
            { field: 'PriceG', titleTranslated: 'PriceG', width: 140, editable: true, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.PriceG && data.PriceG.sum ? parseFloat(data.PriceG.sum).toFixed(4) : 0) #' },
            { field: 'Vg', titleTranslated: 'sumG', width: 140, format: Core.Kendo.Format.Price4, footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Vg && data.Vg.sum ? parseFloat(data.Vg.sum).toFixed(4) : 0) #' },
            { field: 'Vat', titleTranslated: 'vat', width: 140, format: Core.Kendo.Format.Percent },
            { field: "ID", hidden: true }
        ];

        private servicesGridColumnTempalates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                editformat: "{0:##.####}",
                fieldName: 'SCount', editor: function (container, options) {

                    $("<input class='apx-color-lemonchiffon' name='" + options.field + "' />")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 4,
                            spinners: false,
                            min: 0,
                            step: 0
                        });
                }
            },
            {
                editformat: "{0:##.####}",
                fieldName: 'PriceG', editor: function (container, options) {

                    $("<input class='apx-color-lemonchiffon' name='" + options.field + "'/>")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 4,
                            spinners: false,
                            min: 0,
                            step: 0
                        });
                }
            }
        ];

        getServicesDatasource(dataSource): kendo.data.DataSource {
            var ds = new kendo.data.DataSource({
                data: dataSource,
                aggregate:
                [
                    { field: "Vg", aggregate: "sum" },
                    { field: "PriceG", aggregate: "sum" },
                    { field: "Code", aggregate: "count" },
                    { field: "SCount", aggregate: "sum" }
                ],
                schema: {
                    model: {
                        id: "ID",
                        fields: {
                            "ID": { type: 'string' },
                            'Code': { editable: false, type: 'string' },
                            'Name': { editable: false, type: 'string' },
                            'Discount': { editable: false, type: 'number' },
                            'Vat': { editable: false, type: 'number' },
                            'Vg': { editable: false, type: 'number' },
                            'SCount': (this.$scope.Order != undefined && this.$scope.Order.OrderStatus != 0) ? { editable: false, type: 'number' } : { type: 'number' },
                            'PriceG': (this.$scope.Order != undefined && this.$scope.Order.OrderStatus != 0) ? { editable: false, type: 'number' } : { type: 'number' }
                        }
                    }
                }
            });
            return ds;
        }

        getServicesGridConfig = (): Components.IApxGrid => {
            var servicesGrid: Components.IApxGrid;
            servicesGrid = {
                dataSource: this.getServicesDatasource(this.$scope.serviceModel.services),
                columns: this.serviceGridColumns,
                templates: this.servicesGridColumnTempalates,
                settings: {
                    selectable: "multiple",
                    gridButtons: {
                        add: { enabled: true, id: 'Service' },
                        multiDelete: { enabled: true, id: 'Service' }
                    },
                    height: 400,
                    editble: {
                        popupTemplateID: 'service_popup_editor',
                        popupWidth: 700
                    }
                },
                isLookup: false,
                parentScope: this.$scope,
                gridEvents:
                [
                    this.getServiceAddEvent(),
                    this.getServiceCancelEvent()
                ]
            };
            return servicesGrid;
        }
        private getServiceCancelEvent(): Components.IEvent {
            var cancelEventType: Components.EventType = Components.EventType.Cancel;
            var cancelEvent: Components.IEvent =
                {
                    eType: cancelEventType,
                    eFunction: this.servicesGridCancelEventFunctionality
                };
            return cancelEvent;
        }

        servicesGridAddEventFunctionality = (e) => {
            if (e.model != undefined) {
                switch (null) {
                    case e.values.SCount:
                        e.preventDefault();
                        return;
                    case e.values.PriceG:
                        e.preventDefault();
                        return;
                }

                if (e.values.SCount != undefined) {
                    e.model.SCount = e.values.SCount;
                    e.model.Vg = e.model.SCount * e.model.PriceG;
                    var index = this.$scope.serviceModel.services.map(function (service) { return service.Service.ServiceID; }).indexOf(e.model.Service.ServiceID);
                    this.$scope.serviceModel.services[index] = e.model;
                    this.$scope.components.servicesGrid.dataSource.sync();
                    this.initLabelValues();
                    this.makeFormDirty();
                    return;
                }
                if (e.values.PriceG != undefined) {
                    e.model.PriceG = e.model.Price = e.values.PriceG;
                    e.model.Vg = e.model.SCount * e.model.PriceG;
                    var index = this.$scope.serviceModel.services.map(function (service) { return service.Service.ServiceID; }).indexOf(e.model.Service.ServiceID);
                    this.$scope.serviceModel.services[index] = e.model;
                    this.$scope.components.servicesGrid.dataSource.sync();
                    this.initLabelValues();
                    this.makeFormDirty();
                    return;
                }
            }
            else {
                if (this.$scope.serviceModel.addedService.service == undefined) {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), 'გთხოვთ აირჩიოთ მომსახურება'));
                    e.preventDefault();
                    return;
                }
                if (this.$scope.serviceModel.addedService.Price * 1 === 0 ||
                    this.$scope.serviceModel.addedService.count * 1 === 0) {
                    Core.Utils.Helper.safeApply(this.$scope, this.toaster.pop('error', this.translate.instant('Attention'), 'შეუძლებელია ჩანაწერის დამატება ნულოვანი მნიშვნელობით'));
                    e.preventDefault();
                    return;
                }
                else {
                    this.initServiceProperties(this.$scope.serviceModel.addedService);
                    this.$scope.serviceModel.services.unshift(this.$scope.serviceModel.addedService);
                    this.$scope.components.servicesGrid.grid.dataSource.read();
                    this.initLabelValues();
                    this.clearServiceModel();
                    this.makeFormDirty();
                }

            }
        }

        initLabelValues = () => {
            if (this.$scope.preorderType == 'OrderTranzit')
                return;

            var goods_amount = 0;
            var service_amount = 0;

            _.map(_.values(this.$scope.productModel.addedProducts), function (itm) {
                goods_amount += itm.OCount * itm.Price * (1 - itm.Discount);
            });

            if (!this.$scope.Visibility.servicesTabInvisible) {
                _.map(this.$scope.serviceModel.services, function (itm) {
                    service_amount += itm.SCount * itm.Price * (1 - itm.Discount);
                });
            }

            var total_amount = goods_amount + service_amount;

            this.$scope.LabelsModel.text = 'სულ: ' + goods_amount.toFixed(4) + ' + ' + service_amount.toFixed(4) + ' = ' + total_amount.toFixed(4) + '; ';

            if (this.$scope.LabelsModel.recipientStats != undefined && this.$scope.LabelsModel.recipientStats.CompanyBalance != undefined) {
                this.$scope.LabelsModel.text += ' მიმდინარე ბალანსი: ' + this.$scope.LabelsModel.recipientStats.CompanyBalance.Vg + ' (' + this.$scope.LabelsModel.recipientStats.BranchBalance.Vg + ');'
            }

            var contractDets = this.$scope.LabelsModel.contractDets;
            if (contractDets != undefined) {

                var needOverdraft = false;
                var cntLeftAmount = 0;

                if (!contractDets.Overdraft) contractDets.Overdraft = 0;
                if (!contractDets.Debts) contractDets.Debts = 0;

                needOverdraft = contractDets.Debts + goods_amount > contractDets.Creditamounts + contractDets.Overdraft;
                cntLeftAmount = (contractDets.Creditamounts + contractDets.Overdraft) - (contractDets.Debts + goods_amount);

                this.$scope.LabelsModel.text += " კონტრაქტის ნაშთი: " + contractDets.Debts;
                if (contractDets.Overdue > 0) {
                    this.$scope.LabelsModel.text += "/" + contractDets.Overdue;
                }
                this.$scope.LabelsModel.text += " ლიმიტი: " + cntLeftAmount;

                if (needOverdraft) {
                    this.$scope.LabelsModel.text += " ლიმიტი ამოწურულია!";
                }
            }
        }


        initServiceProperties = (currentService: any): void => {
            currentService.Name = this.$scope.serviceModel.addedService.service.Name;
            currentService.Code = this.$scope.serviceModel.addedService.service.Code;
            currentService.SCount = this.$scope.serviceModel.addedService.count;
            currentService.Discount = 0;
            currentService.Service = { ServiceID: this.$scope.serviceModel.addedService.service.ServiceID };
            currentService.Vat = this.$scope.serviceModel.addedService.service.Vat;
            currentService.PriceG = currentService.Price = this.$scope.serviceModel.addedService.Price;
            currentService.Vg = this.$scope.serviceModel.addedService.sum;
        }

        private servicesGridCancelEventFunctionality = (e: kendo.ui.GridEvent) => {
            this.clearServiceModel();
            setTimeout(() => { this.$scope.isBusy = false; }, 100);
        }

        private getServiceAddEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.servicesGridAddEventFunctionality
                };
            return addEvent;
        }


        clearServiceModel = () => {
            delete this.$scope.serviceModel.addedService;
            this.$scope.components.servicesGrid.grid.dataSource.read();
        }

        makeFormDirty = (e?) => {
            if (e == undefined) {
                if (this.$scope.isDirty === false) {
                    this.$scope.isDirty = true;
                }
            } else {
                if (this.$scope.isDirty === false && e.keyCode !== 13 && e.keyCode !== 27) {
                    this.$scope.isDirty = true;
                }
            }
        }

        changeValuesByFixVals = (preorderType: string) => {

            var servicesTabVisibilityId, contractsVisibilityId, couponsVsibilityId, loyaltyCardVisibilityId, manageRCountId;
            servicesTabVisibilityId = contractsVisibilityId = couponsVsibilityId = loyaltyCardVisibilityId = manageRCountId = '';

            switch (preorderType) {
                case 'OrderRetail':
                    {
                        servicesTabVisibilityId = '200d2c53bba049b6b91a827e9fd77dc5';
                        manageRCountId = 'f2a684f2d417446f8f57e0c4bb8722ac';
                        loyaltyCardVisibilityId = 'fb483ab16ff947f0a88c40ae82e8ebba';
                        couponsVsibilityId = '2c4e53b93ebc49a59faabe068b3a28c4';
                        break;
                    }
                case 'OrderWholesale':
                    {
                        servicesTabVisibilityId = '2c2bc339dff0493a96541d24d7319ab0';
                        contractsVisibilityId = 'e35b3d039f8649fbb5786a4b8163567d';
                        loyaltyCardVisibilityId = '580cb05d3f9f491b80f7df5f734b6da2';
                        couponsVsibilityId = 'abcc7ef497c34c0692ad631784ea7d83';
                        manageRCountId = '2c3f6be7312a475eb9df292451e7b311';
                        break;
                    }
                case 'OrderDistribution':
                    {
                        servicesTabVisibilityId = '0ff12291ed7b4b228ca18dd4ef748ece';
                        contractsVisibilityId = '84ae7e0f677b47ac81fe248ab2357492';
                        loyaltyCardVisibilityId = '756b9b145b8d440387cccb65585b8187';
                        couponsVsibilityId = 'cd8ff56df7954ce9a48356d1e055408f';
                        manageRCountId = 'f113d3ba21104d90848b049bf13b82c6';

                        break;
                    }
                case 'OrderTranzit':
                    {
                        manageRCountId = 'ab7ef3343dfc471e81a85e4f5a06a2f7';
                        break;
                    }

            }
            for (var i = 0; i < this.$scope.FixedValuesForCrmOrderFunctionality.length; i++) {
                var fixVal = this.$scope.FixedValuesForCrmOrderFunctionality[i];
                if (fixVal.CodeId == servicesTabVisibilityId && fixVal.Vi != undefined && fixVal.Vi == 0) {
                    this.$scope.Visibility.servicesTabInvisible = true;
                    continue;
                }
                if (fixVal.CodeId == contractsVisibilityId && fixVal.Vi != undefined && fixVal.Vi == 0) {
                    this.$scope.Visibility.contractsInvisible = true;
                    continue;
                }
                if (fixVal.CodeId == loyaltyCardVisibilityId && fixVal.Vi != undefined && fixVal.Vi == 0) {
                    this.$scope.Visibility.loyaltyCardInvisible = true;
                    continue;
                }
                if (fixVal.CodeId == couponsVsibilityId && fixVal.Vi != undefined && fixVal.Vi == 0) {
                    this.$scope.Visibility.couponsTabInvisible = true;
                    continue;
                }
                if (fixVal.CodeId == manageRCountId && fixVal.Vi != undefined && fixVal.Vi == 1) {
                    this.$scope.manageRCount.val = true;
                    continue;
                }
            }

            if (preorderType == 'OrderTranzit') {
                this.$scope.Visibility.contractsInvisible =
                    this.$scope.Visibility.couponsTabInvisible =
                    this.$scope.Visibility.loyaltyCardInvisible =
                    this.$scope.Visibility.servicesTabInvisible = true;
            }

        };

        closeOrder = () => {
            if (this.$scope.isDirty) {
                var r = Core.Utils.ModalForm.openConfirmation("გსურთ ცვლილებების გაუქმება?");
                r.result.then(() => {
                    this.$uibModalInstance.close();
                    $(document).unbind('keydown', this.closeHandle);
                });
            } else {
                this.$uibModalInstance.close();
                $(document).unbind('keydown', this.closeHandle);
            }
        }

        groductsGridChangeCellEvent(): Components.IEvent {
            var editEventType: Components.EventType = Components.EventType.Edit;
            var editEvent: Components.IEvent =
                {
                    eType: editEventType,
                    eFunction: this.editEventFunctionality
                };
            return editEvent;
        }

        editEventFunctionality = (e: any) => {
            if (e.model != undefined && e.model.packcount == 1) {
                var options: any = { numeric: true, min: 0, allowMinus: false }
                $('#countInputProductsGrid').inputmask('integer', options);
            }
        }
        groductsGridChangeCellEventForModal(): Components.IEvent {
            var editEventType: Components.EventType = Components.EventType.Edit;
            var editEvent: Components.IEvent =
                {
                    eType: editEventType,
                    eFunction: this.editEventFunctionalityForModal
                };
            return editEvent;
        }

        editEventFunctionalityForModal = (e: any) => {

            if (e.model != undefined && e.model.packcount == 1) {
                var options: any = { numeric: true, min: 0, allowMinus: false }
                $('#countInputProductsGridModal').inputmask('integer', options);
            }
        }

        getPartial(): string {
            throw ("Template name is empty! ");
        }
    }
}